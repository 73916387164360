import React from "react";
// import Aux from '../hoc/Aux';
import Header from "../Header/Header";
import Footer from "./Footer";
import Content from "../Layout/Content";

import { Route, Switch } from "react-router-dom";

// const layout = ( props ) => (
//     <Aux>
//         <Switch>
//             <Route path="/" render={props => <Header {...props} />}/>
//             <Route exact path="/byLocation" render={props => <Header {...props} setSearch={"location"}/>}/>
//             <Route exact path="/byClub" render={props => <Header {...props} setSearch={"club"} />}/>
//         </Switch>
//         <Content/>
//         <Footer/>
//     </Aux>
// );
const layout = props => {
  console.log("Layout: ", props);
  return (
    <div>
      <Switch>
        <Route path="/" render={props => <Header {...props} />} />
        <Route
          exact
          path="/byLocation"
          render={props => <Header {...props} setSearch={"location"} />}
        />
        <Route
          exact
          path="/byClub"
          render={props => <Header {...props} setSearch={"club"} />}
        />
        <Route
          exact
          path="/popular"
          render={props => <Header {...props} setSearch={"popular"} />}
        />
        <Route
          exact
          path="/travel"
          render={props => <Header {...props} setSearch={"travel"} />}
        />
        <Route
          exact
          path="/womens"
          render={props => <Header {...props} setSearch={"womens"} />}
        />
        <Route
          exact
          path="/events"
          render={props => <Header {...props} setSearch={"events"} />}
        />
      </Switch>
      {props.children}
      <Footer />
    </div>);
};

export default layout;
