import React, { Component } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete";
import { connect } from "react-redux";
import queryString from "query-string";
import Geocode from "react-geocode";
import { withRouter } from "react-router-dom";

/**
 * https://github.com/hutsi/react-places-autocomplete-extended#readme
 */
class LocationSearch extends Component {
  constructor(props) {
    super(props);
    const parsed = queryString.parse(this.props.history.location.search);
    console.log("Location:: constructor: ", parsed);

    this.state = {
      myLocation: this.props.myLocation,
      address: parsed.location || this.props.address,
      radius: parsed.radius || this.props.radius,
      newRadius: null
    };
    if (parsed.radius) this.props.onChangeRadius(parsed.radius);
    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.handleChangeRadius = this.handleChangeRadius.bind(this);
    this.handleChangeSearchType = this.handleChangeSearchType.bind(this);
    this.handleMobileOptionsClick = this.handleMobileOptionsClick.bind(this);
    this.handleGolfCourse = this.handleGolfCourse.bind(this);
  }
  handleChange = address => {
    console.log(
      "suggestion-items: ",
      document.getElementsByClassName("suggestion-item").length
    );
    this.setState({ address });
  };

  handleSelect = address => {
    console.log("LocationSearch::handleSelect: address:", address);
    this.setState({ address });
    this.props.history.push("/byLocation?location=" + address);
  };

  handleButtonClick() {
    const radius = this.state.newRadius
      ? this.state.newRadius
      : this.state.radius;
    this.setState({ radius: radius });

    console.log(
      "LocationSearch::handleButtonClick: setting radius to: ",
      this.state.address,
      this.state.radius
    );
    this.props.onChangeRadius(radius);
    this.props.onSelectGooglePlace(this.state.address, this.state.radius, 1);
    this.props.history.push(
      `/byLocation?location=${this.state.address}&radius=${radius}`
    );
  }
  useMyGeoLocation = (myLocation, cb) => {
    const { location } = this.props.history;
    const parsed = queryString.parse(location.search);
    Geocode.fromLatLng(myLocation.lat, myLocation.lng).then(response => {
      const address = parsed.location || response.results[0].formatted_address;
      this.setState({
        address: address,
        searchString: address
      });
      if (location.pathname === "/byLocation") {
        setTimeout(() => {
          this.props.onSelectGooglePlace(
            "near " + address,
            parsed.radius || 20,
            2
          );
        }, 50);
      }
    });
  };
  handleChangeRadius(e) {
    const parsed = queryString.parse(this.props.history.location.search);
    let rad = parseInt(e.target.value);
    if (isNaN(rad)) rad = parseInt(parsed.radius) || 20;
    rad = rad > 999 ? 999 : rad;
    this.setState({ newRadius: rad });
    this.setState({ radius: rad });
  }
  handleChangeSearchType(type) {
    this.props.onChangeSearchType(type);
    // this.props.history.push("/byClub");
  }
  handleGolfCourse() {
    this.props.onChangeSearchType('course');
    // this.props.history.push("/byLocation");
  }
  handleMobileOptionsClick() {
    this.props.onShowFilters();
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { location } = this.props.history;
    console.log("componentDidUpdate: this.props: ", this.props);
    if (location.pathname == "/") {
      this.props.unSetLocation();
    }
  }

  componentDidMount() {
    // clear in case there was a previous club search..
    this.props.onClearSelectedClub(this.props.filtersSet);
    console.log(
      "%c LocationSearch::compDidMnt: props.myLocation: ",
      "color: blue; font-weight: bold;",
      this.props
    );
    Geocode.setApiKey("AIzaSyCW7uslsp1hjVGQOXwxbFvc_-3bYwArPMU");
    // if locationSet, we will show results..
    if (this.props.locationSet && !!this.props.searchString) {
      console.log(
        "%c LocationSearch::compDidMnt: props.searchstring: ",
        "color: blue; font-weight: bold;",
        this.props.searchString
      );
      // this.props.onSelectGooglePlace(this.props.searchString, this.props.radius)
      console.log("searchString: ", this.props.searchString);
      Geocode.fromAddress(this.props.searchString).then(
        response => {
          this.props.onChangeSearchString(
            this.props.searchString,
            response.results[0].geometry.location
          );
        },
        error => {
          console.log("searchString: ", this.props.searchString);
          console.log("Error getting geocode from address: ", error);
        }
      );
    }
    const { location } = this.props.history;
    const parsed = queryString.parse(location.search);
    if (parsed.lat && parsed.lng) {
      console.log(
        "%c LocationSearch::compDidMnt: parsed lat lng",
        "color: blue; font-weight: bold;",
        this.props
      );
      Geocode.fromLatLng(parsed.lat, parsed.lng).then(response => {
        const address = response.results[0].formatted_address;
        this.props.onChangeSearchString(address, {
          lng: parsed.lng,
          lat: parsed.lat
        });
        this.setState({
          address: address,
          searchString: address
        });
      });
    } else if (this.props.allowed_location) {
      console.log(
        "%c LocationSearch::compDidMnt: location allowed...",
        "color: blue; font-weight: bold;",
        this.props
      );
      this.useMyGeoLocation(this.props.myLocation);
      // Geocode.fromLatLng(
      //   this.props.myLocation.lat,
      //   this.props.myLocation.lng
      // ).then(response => {
      //   const address =
      //     parsed.location || response.results[0].formatted_address;
      //   this.setState({
      //     address: address,
      //     searchString: address
      //   });
      //   if (location.pathname === "/byLocation") {
      //     setTimeout(() => {
      //       this.props.onSelectGooglePlace(
      //         "near " + address,
      //         parsed.radius || 20,
      //         2
      //       );
      //     }, 50);
      //   }
      // });
    } else {
      const { location } = this.props.history;
      const parsed = queryString.parse(location.search);
      const address = parsed.location || "Los Angeles, CA, USA";
      console.log(
        "%c LocationSearch::compDidMnt: location not allowed...",
        "color: blue; font-weight: bold;",
        address
      );
      this.setState({ address: address });
      //Foobared radius
      if (
        location.pathname &&
        typeof location.pathname == "string" &&
        location.pathname.toLowerCase() === "/bylocation"
      ) {
        setTimeout(() => {
          this.props.onSelectGooglePlace(
            `near ${address}`,
            parsed.radius || 20,
            3
          );
        }, 50);
      }
      console.log(
        "%c LocationSearch::compDidMnt: location NOT allowed...",
        "color: blue; font-weight: bold;",
        location
      );
    }
  }
  componentWillReceiveProps(nextProps) {
    const { location } = this.props.history;
    console.log(
      "LocationSearch::willReceive Props: radius",
      nextProps.radius,
      location
    );
    this.setState({
      // events: nextProps.events,
      radius: nextProps.radius
    });
    //Foobared
    if (location.pathname == "/byLocation") {
      setTimeout(() => {
        this.props.onChangeRadius(nextProps.radius);
      }, 50);
    }
    if (this.props.allowed_location != nextProps.allowed_location) {
      console.log("do stuff here", nextProps.myLocation);
      this.useMyGeoLocation(nextProps.myLocation);
    }
  }

  render() {
    const parsed = queryString.parse(this.props.history.location.search);
    console.log("LocationSearch:: render ", this.props, this.state, parsed);
    const address = this.state.address;

    const pipe_symbol = (
      <span
        className={
          this.props.locationSet
            ? "link-label_pipe_symbol__results"
            : "link-label_pipe_symbol"
        }
      >
        {" "}
        |{" "}
      </span>
    );
    return (
      <div className="search-input-row">
        <div className="link-label">
          <button id="search-location" className="search-label active">
            Find my Community{" "}
          </button>
          <button
            className="search-label"
            onClick={() => { this.handleChangeSearchType("club") }}
          >
            {" "}
            by Club{" "}
          </button>
          <button
            className="search-label"
            onClick={() => { this.handleChangeSearchType("course") }}
          >
            {" "}
            by Course{" "}
          </button>
        </div>
        <div className="input-row">
          <div className="input-with-icon">
            <div className="form-group__icon form-group__icon--location input" />
            <PlacesAutocomplete
              value={address}
              onChange={this.handleChange}
              onSelect={this.handleSelect}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading
              }) => (
                <div
                  style={{ position: "relative" }}
                  className="location-input"
                >
                  <input
                    {...getInputProps({
                      placeholder: "Enter a Location",
                      className: "form-control flat",
                      autoFocus: true
                    })}
                  />
                  <div className="autocomplete-dropdown-container">
                    {loading && <div>Loading...</div>}
                    {suggestions.map(suggestion => {
                      const className = suggestion.active
                        ? "suggestion-item active"
                        : "suggestion-item";
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className
                          })}
                        >
                          <div className="suggestion-group">
                            <div className="form-group__icon form-group__icon--location" />
                            <span className="description">
                              {suggestion.description}
                            </span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
          </div>
          <span
            id="mobile-search-btn"
            className="input-group-btn input-group-btn-mobile"
          >
            <button
              className="btn btn-primary flat"
              onClick={this.handleButtonClick}
            >
              <i className="far fa-search" />
            </button>
          </span>
          <span
            id="mobile-options-btn"
            className="input-group-btn input-group-btn-mobile"
            onClick={this.handleMobileOptionsClick}
          >
            <span className="btn btn-primary flat">
              <i className="far fa-sliders-v" />
            </span>
          </span>
          <div className="radius-wrapper">
            <div className="form-group">
              <label htmlFor="radius" style={{ fontWeight: "100" }}>
                within
              </label>
              <div className="input-group">
                <input
                  id="radius"
                  name="radius"
                  defaultValue={this.state.radius}
                  // value={this.state.radius}
                  className="form-control flat"
                  type="text"
                  onChange={this.handleChangeRadius}
                />
                <div className="miles-word"> miles</div>
                <span className="input-group-btn">
                  <button
                    className="btn btn-primary flat"
                    onClick={this.handleButtonClick}
                  >
                    <i className="far fa-search" />
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function changeLocation(address, latLng, radius) {
  console.log("LocationSearch:changeLocation: latLng: ", latLng);
  return {
    type: "SELECT_ADDRESS",
    payload: { address: address, geoLocation: latLng, radius: radius }
  };
}

function getGeoCodeFromAddress(address, radius) {
  console.log("LocationSearch:getGeoCodeFromAddress: address: ", address);
  return function(dispatch) {
    return geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => dispatch(changeLocation(address, latLng, radius)));
  };
}
const mapStateToProps = state => {
  return {
    address: state.address,
    allowed_location: state.allowed_location,
    defaultSearchString: state.defaultSearchString,
    filtersSet: state.filtersSet,
    location: state.location,
    radius: state.radius,
    searchString: state.searchString,
    searchType: state.searchType,
    setLocation: state.setLocation,
    myLocation: state.myLocation,
    locationSet: state.locationSet
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onChangeRadius: radius =>
      dispatch({ type: "CHANGE_RADIUS", value: radius }),
    onChangeSearchType: (type) =>
      dispatch({ type: "CHANGE_SEARCHTYPE", value: type }),
    onSelectGooglePlace: (address, radius, i) => {
      console.log("onSelectGooglePlace: ", address, radius, i);
      dispatch(getGeoCodeFromAddress(address, radius));
    },
    unSetLocation: () => dispatch({ type: "UNSET_LOCATION", value: "" }),
    onClearSelectedClub: filtersSet =>
      dispatch({ type: "CLEAR_SELECTED_CLUB", value: filtersSet }),
    setLocation: loc => dispatch({ type: "SET_LOCATION", value: loc }),
    onSelectAddress: (address, radius) =>
      dispatch({
        type: "SELECT_ADDRESS",
        payload: { address: address, radius: radius }
      }),
    onShowFilters: () => dispatch({ type: "SHOW_FILTER_OPTIONS" }),
    onChangeSearchString: (address, geoLocation) =>
      dispatch({
        type: "CHANGE_SEARCHSTRING",
        payload: { address: address, geoLocation: geoLocation }
      })
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LocationSearch)
);
