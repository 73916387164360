// import {createBrowserHistory} from 'history'
import React from "react";
import { render } from "react-dom";
import { createStore, applyMiddleware } from "redux";
// import {routerMiddleware} from 'connected-react-router'

import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/css/app.css";
import "./styles/css/reactPlaceholder.css";
import { unregister } from "./registerServiceWorker";
// import createRootReducer from './store/reducer';
import reducer from "./store/reducer";
import Root from "./Root";

import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

const store = createStore(reducer, composeWithDevTools(applyMiddleware(thunk)));
render(<Root store={store} />, document.getElementById("root"));
// registerServiceWorker();
unregister();

//foobared clear cache
caches.keys().then(function(names) {
  for (let name of names) caches.delete(name);
});
