import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";

class MapOverlay extends React.Component {
  constructor(props) {
    super(props);
    this.setFacilityFilter = this.setFacilityFilter.bind(this);
    this.goBack = this.goBack.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      selectedClub: nextProps.selectedClub
    });
  }
  setFacilityFilter(e) {
    e.stopPropagation();
    let filtersSet = { ...this.props.filtersSet };
    const facility = this.props.selectedClub.upcoming[0].facility;
    filtersSet.clubs_that_play_course = [facility.id];
    const location = {
      lat: parseFloat(facility.facility_latitude),
      lng: parseFloat(facility.facility_longitude)
    };
    this.props.onFacilitySelect(filtersSet, location);
    window.scrollTo(0, 150);
  }
  goBack() {
    console.log("goBack", this.props);
    this.props.history.goBack();

    //There is a race condition where going back triggers selectedClub after we clear it
    //Maybe there's a more "proper" way of implementing this
    setTimeout(() => {
      this.props.onChangeSearchType();
      this.props.onClearSelectedClub(this.props.filtersSet);
    }, 100);
  }
  render() {
    console.log("MapOverlay: ", this.props, this.state);
    const club = this.props.selectedClub;
    const days = Object.values(club.wePlay);
    const whens = days.map(when => (
      <div className="badge badge-primary flat when" key={when}>
        {when}
      </div>
    ));
    let nextEvent = "";
    let next;
    if (club.upcoming) {
      next = club.upcoming[0];
    }

    if (next) {
      nextEvent = (
        <div className="next-event">
          <div className="next-event-label">NEXT EVENT</div>
          <div className="next-event-title">{next.name}</div>
          <div className="event-location-row">
            <div className="event-facility">
              <div
                className="facility"
                onClick={this.setFacilityFilter.bind(this)}
              >
                {next.facility.title}
              </div>
            </div>
            <div className="event-date">
              <div className="date">
                {moment(next.event_date).format("MMMM Do, h:mm A")}
              </div>
            </div>
          </div>
        </div>
      );
    }

    const styleStr =
      "linear-gradient(to right, rgba(255, 255, 255, .95) 0%, rgba(255, 255, 255, 0.95) 40%, rgba(255,255,255,.5) 70%, transparent 90%), url(" +
      club.facilityImage +
      ")";
    const backGroundStyle = {
      backgroundImage: styleStr
    };

    return (
      <div
        id={"map-overlay"}
        className="card result-card"
        style={backGroundStyle}
      >
        <div className="full-card">
          <div className="results-card-row">
            <div className="card__left-col">
              <div className="club-info">
                <div className="club-name">
                  <div className="title">{club.title}</div>
                </div>
                <div className="region">{club.address2_city} Area</div>
                <div className="when-row">{whens}</div>
                <div className="distance flex">
                  <div className="marker">
                    <img src="/images/svg/LocationGenericB.svg" alt="marker" />
                  </div>
                  <div className="mileage">
                    {(Math.round(club.miles * 10) / 10).toFixed(1)}
                  </div>
                  <div className="miles-away">miles away</div>
                </div>
              </div>
              <div className="event-info">{nextEvent}</div>
            </div>
            <div className="card__right-col">
              <div
                className="logo"
                style={
                  club.club_site_logo
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <div className="logo-border">
                  <div className="logo-img">
                    <img src={club.club_site_logo} alt="Logo here" />
                  </div>
                </div>
              </div>
              <div className="call-to-action">
                <a
                  href={club.new_scga_joinnowurl}
                  className="join-now-url"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="join-now">
                    <div className="heading">join now</div>
                    <div className="price">
                      ${Math.round(club.new_greenfees)}
                    </div>
                    <div className="per-year">per year</div>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div
            className="recommended"
            style={
              club.recommended ? { display: "block" } : { display: "none" }
            }
          >
            <p>Recommended</p>
          </div>
        </div>

        <button
          className="close-overlay"
          title="Close Info panel"
          onClick={this.props.hideOverlay}
        >
          {/* <Link
            to={{ pathname: "/bylocation?current=1", hash: "#" }}
            onClick={this.props.onChangeSearchType}
            style={{ display: this.props.selected ? "block" : "none" }}
          >
            <i className="fas fa-times fa-lg" style={{ color: "white" }} />
          </Link> */}
          <div
            onClick={this.goBack}
            style={{ display: this.props.selected ? "block" : "none" }}
          >
            <i className="fas fa-times fa-lg" style={{ color: "white" }} />
          </div>
        </button>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    filtersSet: state.filtersSet,
    selected: state.selected
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onFacilitySelect: (filtersSet, location) =>
      dispatch({
        type: "SET_CLUBS_THAT_PLAY",
        payload: { filtersSet: filtersSet, geoLocation: location }
      }),
    onChangeSearchType: () =>
      dispatch({ type: "CHANGE_SEARCHTYPE", value: "location" }),
    onClearSelectedClub: filtersSet =>
      dispatch({ type: "CLEAR_SELECTED_CLUB", value: filtersSet })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MapOverlay));
