import React from 'react';
import moment from 'moment'
const build_date = moment.unix(process.env.REACT_APP_BUILD_TIME).format('dddd, MMMM Do, YYYY h:mm:ss a')
console.log("Footer build_date: ", build_date)
const footer = () => (
    <div className="container-fluid footer">
        <div className="page-footer">
            <div className="row">
                <div className="row page-footer__social-links">
                    <div><a href="https://www.facebook.com/thescga" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f fa-3x"></i></a></div>
                    <div><a href="https://twitter.com/thescga" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter fa-3x"></i></a></div>
                    <div><a href="https://instagram.com/thescga/" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram fa-3x"></i></a></div>
                </div>
                <div className="row page-footer__legal-links">
                    <div className="row">
                        <div><a href="https://scga.org" target="_blank" rel="noopener noreferrer" >SCGA Home</a></div>
                        <div><a href="https://www.scga.org/about/terms-of-use" rel="noopener noreferrer" target="_blank">Terms of Use</a></div>
                        <div><a href="https://www.scga.org/about/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a></div>
                    </div>
                    <span className="copyright" title={'Build date: '+build_date}>&copy;2018 Southern California Golf Association | All Rights Reserved</span>
                </div>
            </div>
        </div>
    </div>
);

export default footer;