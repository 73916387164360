import React, { Component } from "react";
import GoogleMap from "google-map-react";
import mapStyles from "../mapStyles.json";
import MapOverlay from "./MapOverlay";
import MapMarker from "./MapMarker";
import { connect } from "react-redux";

class MapContainer extends Component {
  static defaultProps = {
    center: [34.0372316, -118.2737486],
    zoom: 10,
    maxZoom: 9,
    options: { styles: mapStyles }
  };
  constructor(props) {
    super(props);
    this.state = {
      selected: false,
      center: this.props.geoLocation,
      clubs: this.props.clubs,
      showOverlay: true,
      latlng: {},
      zoom: null
    };
    this.hideOverlay = this.hideOverlay.bind(this);
    this.apiIsLoaded = this.apiIsLoaded.bind(this);
  }
  hideOverlay() {
    // this.setState({showOverlay: false})
    this.props.onSetOverlay(false);
    this.props.onChangeSearchType("location"); // back to results
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      clubs: nextProps.clubs
    });
  }
  shouldComponentUpdate(nextProps, nextState) {
    console.log("MapContainer::shouldComponentUpdate: ", this.props, nextProps);

    return (
      // !!nextProps.clubs && nextProps.clubs.length != this.props.clubs.length
      !!nextProps.clubs
    );
  }
  apiIsLoaded(map, maps) {
    // Get bounds by our clubs

    // if a club is selected, don't fit to bounds, just pass center
    console.log(
      "Mapcontainer: fitting bounds: clubs: ",
      this.props.clubs.length,
      this.props
    );
    const bounds = new maps.LatLngBounds();

    if (!this.props.selected && this.props.clubs.length) {
      this.props.clubs.forEach(club => {
        bounds.extend(
          new maps.LatLng(parseFloat(club.latitude), parseFloat(club.longitude))
        );
      });
      map.fitBounds(bounds);
    } else if (this.props.selectedClub.latitude) {
      bounds.extend(
        new maps.LatLng(
          parseFloat(this.props.selectedClub.latitude),
          parseFloat(this.props.selectedClub.longitude)
        )
      );
    } else {
      bounds.extend(
        new maps.LatLng(
          parseFloat(this.props.latlng.lat),
          parseFloat(this.props.latlng.lng)
        )
      );
    }
    // }
    window.myMap = map;
    window.googleMaps = maps;
    const centerObj = bounds.getCenter();

    const center = { lat: centerObj.lat(), lng: centerObj.lng() };
    console.log("Mapcontainer: fitting bounds: end: ", center);
    this.setMapCenterZoom(center, null);
  }

  setMapCenterZoom(center, zoom) {
    this.props.onSetMapCenterZoom(center, zoom);
  }

  componentDidMount() {
    console.log("MapContainer::componentDidMount: map: ", window.myMap);
  }

  render() {
    const { lat = "", lng = "" } = this.props.latlng || {};
    let zoom = this.props.selected
      ? 13
      : this.props.zoom
      ? this.props.zoom
      : null;
    let center = this.props.center
      ? this.props.center
      : {
          lat: parseFloat(lat),
          lng: parseFloat(lng)
        };
    const clubs = this.props.selected
      ? [this.props.selectedClub]
      : this.props.clubs;
    console.log("MapContainer::render: ", this.props);
    const club = this.props.selectedClub;
    const apiKey = "AIzaSyCW7uslsp1hjVGQOXwxbFvc_-3bYwArPMU";
    // if(window.googleMaps && !this.props.zoom) {
    if (
      window.googleMaps &&
      !this.props.zoom &&
      !this.props.selected &&
      this.props.clubs.length
    ) {
      const bounds = new window.googleMaps.LatLngBounds();
      console.log("MapContainer::render: set bounds...", this.props);
      clubs.map(club => {
        bounds.extend(
          new window.googleMaps.LatLng(
            parseFloat(club.latitude),
            parseFloat(club.longitude)
          )
        );
      });
      window.myMap.fitBounds(bounds);
      zoom = window.myMap.getZoom();
      zoom = zoom < 10 ? 10 : zoom;
      const centerObj = bounds.getCenter();
      center = { lat: centerObj.lat(), lng: centerObj.lng() };
      this.setMapCenterZoom(center, zoom);
      console.log(
        "MapContainer2::render: center: ",
        this.props,
        center,
        zoom,
        centerObj.lat(),
        centerObj.lng()
      );
    }

    // if a club is selected, make a marker with just that club, else map clubs into markers
    const markers = this.props.selected ? (
      <MapMarker
        lat={parseFloat(this.props.selectedClub.latitude)}
        lng={parseFloat(this.props.selectedClub.longitude)}
        club={club}
        text={club.title}
      />
    ) : (
      clubs.map(club => (
        <MapMarker
          key={club.id}
          lat={parseFloat(club.latitude)}
          lng={parseFloat(club.longitude)}
          club={club}
          text={club.title}
        />
      ))
    );

    console.log(
      "MapContainer2::render: zoom, center3: ",
      this.props,
      zoom,
      center
    );
    return (
      <div id="map">
        {center.lat && center.lng ? (
          <GoogleMap
            bootstrapURLKeys={{ key: apiKey }}
            center={center}
            defaultZoom={11}
            zoom={zoom}
            options={this.props.options}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => this.apiIsLoaded(map, maps)}
          >
            {markers}
          </GoogleMap>
        ) : null}

        {this.props.selected && this.props.showOverlay && (
          <MapOverlay selectedClub={club} hideOverlay={this.hideOverlay} />
        )}
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    center: state.center,
    filtersSet: state.filtersSet,
    geoLocation: state.geoLocation,
    myLocation: state.myLocation,
    locationSet: state.locationSet,
    rad: state.radius,
    searchType: state.searchType,
    selectedClub: state.selectedClub,
    selected: state.selected,
    showOverlay: state.showOverlay,
    zoom: state.zoom
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onSetOverlay: show => dispatch({ type: "SET_MAP_OVERLAY", value: show }),
    onChangeSearchType: type =>
      dispatch({ type: "CHANGE_SEARCHTYPE", value: type }),
    onSetMapCenterZoom: (center, zoom) => {
      console.log("onSetMapCenterZoom", center, zoom);
      dispatch({
        type: "SET_MAP_CENTER_ZOOM",
        payload: { center: center, zoom: zoom }
      });
    }
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MapContainer);
