import _ from "lodash";
const defaultFilters = {
    member_types: [],
    welcome_genders: [],
    skill_level: [],
    average_age: [],
    play_days: [],
    whys: [],
    club_types: [],
    game_styles: [],
    languages: []
};
function fetchFilters(clubs = [], filters = defaultFilters) {
    console.log('===fetchFilters::filters', filters, clubs);
    let config = {
        members: {
            question: 'Who do you like to play with?',
            title: 'I PLAY WITH',
            includedClass: filters.member_types.length || filters.welcome_genders.length ? "card member-type checked":"card member-type",
            propName: 'welcome_genders',
            fields: [
                {
                    name: 'Men Only',
                    filterName: 'male'
                },
                {
                    name: 'Women Only',
                    filterName: 'female'
                },
                {
                    name: 'Men and Women',
                    // filterName: ['male', 'female']
                    filterName: 'both'
                },
                {
                    name: 'Seniors',
                    filterName: 'seniors',
                    propOverride: 'member_types',
                    disable: !!!clubs.filter(club => club.member_types.indexOf("seniors") > -1).length
                },
            ]
        },
        skillLevel: {
            question: 'What type of golfer are you?',
            title: "MY SKILL LEVEL",
            propName: 'skill_level',
            includedClass: filters.skill_level && filters.skill_level.length
                ? "card skill-levels checked"
                : "card skill-levels",
            fields: [
                {
                    name: "Beginner",
                    filterName: "beginners",
                    disable: !clubs.filter(club => club.levels.indexOf("beginners") > -1).length,
                },
                {
                    name: "High Handicap (20+)",
                    filterName: "high-handicaps",
                    disable: !clubs.filter(club => club.levels.indexOf("high-handicaps") > -1).length

                },

                {
                    name: "Average Handicap (10-20)",
                    filterName: "average-handicaps",
                    disable: !clubs.filter(club => club.levels.indexOf("average-handicaps") > -1).length,
                },
                {
                    name: "Single Digit Handicap",
                    filterName: "single-digit-handicaps",
                    shouldDisable: !clubs.filter(club => club.levels.indexOf("single-digit-handicaps") > -1).length,
                },
                {
                    name: "Scratch Player",
                    filterName: "scratch-players",
                    shouldDisable: !clubs.filter(club => club.levels.indexOf("scratch-players") > -1).length,
                }
            ]
        },
        averageAge: {
            question: 'What age bracket do you prefer for a group golf outing?',
            title: 'MY AGE',
            propName: 'average_age',
            includedClass: filters.average_age.length ? "card average-age checked" : "card average-age",
            fields: [
                {
                    name: "55+",
                    filterName: "55+",
                    disable: !clubs.filter(club => {
                        return ([...filters.average_age, "55+"].some((age = "") => {
                            console.log('club.age:', club);
                            if (club.title === 'Griffith Park GC') club.age = "55+";
                            return !!club.age && club.age.indexOf(age) > -1
                        }))
                    }).length
                },
                {
                    name: "36-55",
                    filterName: "36-55",
                    disable: !clubs.filter(club => {
                        return ([...filters.average_age, "36-55"].some(age => !!club.age && club.age.indexOf(age) > -1))
                    }).length
                },
                {
                    name: "18-35",
                    filterName: "18-35",
                    disable: !clubs.filter(club => {
                        return ([...filters.average_age, "18-35"].some(age => !!club.age && club.age.indexOf(age) > -1))
                    }).length
                },
                {
                    name: "under 18",
                    filterName: "-18",
                    disable: !clubs.filter(club => {
                        return ([...filters.average_age, "-18"].some(age => !!club.age && club.age.indexOf(age) > -1))
                    }).length
                },
                {
                    name: 'Any Age',
                    filterName: 'any_age'
                },
            ]
        },
        plays: {
            question: 'What day of the week do you like to play?',
            title: 'I PLAY',
            propName: 'play_days',
            includedClass: filters.play_days.length ? "card we-play-days checked" : "card we-play-days",
            fields: [
                {
                    name: 'Weekdays',
                    filterName: 'weekdays',
                    disable: !clubs.filter(club => club.play_days.indexOf('weekdays') > -1).length,
                },
                {
                    name: 'Saturday',
                    filterName: 'saturday',
                    disable: !clubs.filter(club => club.play_days.indexOf('saturday') > -1).length,
                },
                {
                    name: 'Sunday',
                    filterName: 'sunday',
                    disable: !clubs.filter(club => club.play_days.indexOf('sunday') > -1).length,
                },
                {
                    name: 'Any Day',
                    filterName: 'any_day',
                    disable: false
                },
            ]
        },
        whys: {
            question: 'How do you prefer to play?',
            title: 'WHY I PLAY',
            propName: 'whys',
            clubPropName: 'whys',
            includedClass: filters.whys.length ? "card whys checked" : "card whys",
            fields: [
                {
                    name: 'Competition',
                    filterName: 'competition',
                    disable: !clubs.filter(club => club.whys.indexOf('competition') > -1).length
                },
                {
                    name: 'Fun/Social Golf',
                    filterName: 'fun-social-golf',
                    disable: !clubs.filter(club => club.whys.indexOf('fun-social-golf') > -1).length,
                },
                {
                    name: 'Play New Courses',
                    filterName: 'play-new-courses',
                    disable: !clubs.filter(club => club.whys.indexOf('play-new-courses') > -1).length
                },
                {
                    name: 'Business & Networking',
                    filterName: 'business-networking',
                    disable: !clubs.filter(club => club.whys.indexOf('business-networking') > -1).length
                },
                {
                    name: 'Introducing People to the Game',
                    filterName: 'introducing-people-to-the-game',
                    disable: !clubs.filter(club => club.whys.indexOf('introducing-people-to-the-game') > -1).length
                },
                {
                    name: 'Instruction',
                    filterName: 'instruction',
                    disable: !clubs.filter(club => club.whys.indexOf('instruction') > -1).length
                },
                {
                    name: 'Staying Active/Health',
                    filterName: 'staying-active-health',
                    disable: !clubs.filter(club => club.whys.indexOf('staying-active-health') > -1).length
                },
            ]
        },
        radius: {
            question: 'How far are you willing to travel for an event?',
        },
        cost: {
            question: 'What is the max you are willing to pay for a membership to a club?',
        },
        clubType: {
            title: 'I PREFER TO',
            includedClass: filters.club_types.length ? "card club-types checked" : "card club-types",
            propName: 'club_types',
            fields: [
                {
                    name: 'Play a Home Course',
                    filterName: 'home-course'
                },
                {
                    name: 'Travel Around To Play',
                    filterName: 'travel'
                }
            ]
        },
        gameStyle: {
            question: 'How do you prefer to play?',
            title: 'HOW I PLAY',
            includedClass: filters.game_styles.length ? "card game-style checked" : "card game-style",
            propName: 'game_styles',
            fields: [
                {
                    name: 'Competitive (Net)',
                    filterName: 'competitive-net',
                    disable: !clubs.filter(club => club.game_styles.indexOf("competitive-net") > -1).length
                },
                {
                    name: 'Competitive (Gross)',
                    filterName: 'competitive-gross',
                    disable: !clubs.filter(club => club.game_styles.indexOf("competitive-gross") > -1).length
                },
                {
                    name: 'Social (Non-Competitive)',
                    filterName: 'social-non-competitive',
                    disable: !clubs.filter(club => club.game_styles.indexOf("social-non-competitive") > -1).length
                },
                {
                    name: 'Team Events',
                    filterName: 'team-events',
                    disable: !clubs.filter(club => club.game_styles.indexOf("team-events") > -1).length
                },
                {
                    name: 'Skins Games',
                    filterName: 'skins-games',
                    disable: !clubs.filter(club => club.game_styles.indexOf("skins-games") > -1).length
                },
                {
                    name: 'League',
                    filterName: 'league',
                    disable: !clubs.filter(club => club.game_styles.indexOf("league") > -1).length
                },
                {
                    name: 'Couples Golf',
                    filterName: 'couples-golf',
                    disable: !clubs.filter(club => club.game_styles.indexOf("couples-golf") > -1).length
                },
                {
                    name: 'Junior Events',
                    filterName: 'junior-events',
                    disable: !clubs.filter(club => club.game_styles.indexOf("junior-events") > -1).length
                },
                {
                    name: 'Events for Beginners',
                    filterName: 'events-for-beginners',
                    disable: !clubs.filter(club => club.game_styles.indexOf("events-for-beginners") > -1).length
                },
                {
                    name: 'Member/Guest',
                    filterName: 'member-guest',
                    disable: !clubs.filter(club => club.game_styles.indexOf("member-guest") > -1).length
                },
            ]

        },
        
        languages: {
            question: 'What languages do you speak? (Select All that apply)',
            title: 'MY LANGUAGES',
            includedClass: filters.languages.length ? "card languages checked":"card languages",
            propName: 'languages',
            fields: [
                {
                    name: 'English',
                    filterName: 'english',
                    disable: !!!clubs.filter(club => club.langs.indexOf("english") > -1).length
                },
                {
                    name: 'Spanish',
                    filterName: 'spanish',
                    disable: !!!clubs.filter(club => club.langs.indexOf("english") > -1).length
                },
                {
                    name: 'Korean',
                    filterName: 'korean',
                    disable: !!!clubs.filter(club => club.langs.indexOf("korean") > -1).length
                },
                {
                    name: 'Chinese',
                    filterName: 'chinese',
                    disable:!!!clubs.filter(club => club.langs.indexOf("chinese") > -1).length
                },
                {
                    name: 'Vietnamese',
                    filterName: 'vietnamese',
                    disable: !!!clubs.filter(club => club.langs.indexOf("vietnamese") > -1).length
                },
                {
                    name: 'Farsi',
                    filterName: 'farsi',
                    disable: !!!clubs.filter(club => club.langs.indexOf("farsi") > -1).length
                },
                {
                    name: 'Japanese',
                    filterName: 'japanese',
                    disable: !!!clubs.filter(club => club.langs.indexOf("japanese") > -1).length
                },
                {
                    name: 'Russian',
                    filterName: 'russian',
                    disable: !!!clubs.filter(club => club.langs.indexOf("russian") > -1).length
                },
                {
                    name: 'Tagalog',
                    filterName: 'tagalog',
                    disable: !!!clubs.filter(club => club.langs.indexOf("tagalog") > -1).length
                },
                {
                    name: 'French',
                    filterName: 'french',
                    disable: !!!clubs.filter(club => club.langs.indexOf("french") > -1).length
                },
                {
                    name: 'German',
                    filterName: 'german',
                    disable: !!!clubs.filter(club => club.langs.indexOf("german") > -1).length
                },
                {
                    name: 'Italian',
                    filterName: 'italian',
                    disable: !!!clubs.filter(club => club.langs.indexOf("italian") > -1).length
                },
                {
                    name: 'Portuguese',
                    filterName: 'portuguese',
                    disable: !!!clubs.filter(club => club.langs.indexOf("portuguese") > -1).length
                },
                {
                    name: 'Thai',
                    filterName: 'thai',
                    disable: !!!clubs.filter(club => club.langs.indexOf("thai") > -1).length
                },
                {
                    name: 'Punjabi',
                    filterName: 'punjabi',
                    disable: !!!clubs.filter(club => club.langs.indexOf("punjabi") > -1).length
                },
                {
                    name: 'Cambodian',
                    filterName: 'cambodian',
                    disable: !!!clubs.filter(club => club.langs.indexOf("cambodian") > -1).length
                },
            ]
        },
    };
    return config;
}

function filterClubs (clubs, filters) {
    // if (filters.radius) {
    //     console.log("ELSE: ", this.props);
    //     console.log("ELSE: clubs", clubs);
    //     // filter clubs within radius and sort on ASC distance
    //     const foo = clubs.filter(club => club[mileIndex] <= filters.radius);
    //     clubs = clubs
    //       .filter(club => club[mileIndex] <= filters.radius)
    //       .sort(function(a, b) {
    //         return a[mileIndex] - b[mileIndex];
    //       });
    //     console.log("ELSE: END", clubs, foo);
    // }
  
    // get filters from store
    //   const filters = this.props.filtersSet;
      
  
      if (filters.skill_level && filters.skill_level.length) {
        clubs = clubs.filter(club => {
          return filters.skill_level.some(
            level => club.levels.indexOf(level) > -1
          );
        });
      }
      if (filters.play_days && filters.play_days.length) {
        clubs = clubs.filter(club => {
          if(filters.play_days.find(s => s === 'any_day')) {
              return true;
          }
          return filters.play_days.every(
            wePlay => club.play_days.indexOf(wePlay) > -1
          );
        });
      }
      if (filters.average_age && filters.average_age.length) {
        clubs = clubs.filter(club => {
          if(filters.average_age.find(s => s === 'any_age')) {
            return true;
          }
          return filters.average_age.some(age => {
            const clubAge = club.age || [];
            return clubAge.indexOf(age) > -1;
          });
        });
      }
      if (filters.whys && filters.whys.length) {
        clubs = clubs.filter(club => {
          return filters.whys.some(why => club.whys.indexOf(why) > -1);
        });
      }
    //   if (this.props.max_cost < 200) {
    //     clubs = clubs.filter(club => club.new_greenfees <= this.props.max_cost);
    //   }
      if (filters.game_styles && filters.game_styles.length) {
        clubs = clubs.filter(club => {
          return filters.game_styles.some(
            type => club.game_styles.indexOf(type) > -1
          );
        });
      }
      if (filters.club_types && filters.club_types.length) {
        clubs = clubs.filter(club => {
          return filters.club_types.some(
            type => club.club_types.indexOf(type) > -1
          );
        });
      }
      if (filters.game_styles && filters.game_styles.length) {
        clubs = clubs.filter(club => {
          return filters.game_styles.every(
            type => club.game_styles.indexOf(type) > -1
          );
        });
      }
      if (filters.member_types && filters.member_types.length) {
        if (filters.member_types.indexOf("seniors") > -1) {
          clubs = clubs.filter(club => club.is_senior_club);
        }
        if (filters.member_types.indexOf("associate-members") > -1) {
          clubs = clubs.filter(club => club.allow_associate_membership);
        }
      }
      if (filters.welcome_genders && filters.welcome_genders.length) {
        if (filters.welcome_genders.indexOf("both") > -1) {
          console.log("Results: both A: ", clubs.length);
  
          clubs = clubs.filter(club =>
            ["male", "female"].every(
              gender => club.welcome_genders.indexOf(gender) > -1
            )
          );
          console.log("Results: both B: ", clubs.length);
          // debugger;
        }
        if (filters.welcome_genders.indexOf("child") > -1) {
          clubs = clubs.filter(
            club => club.welcome_genders.indexOf("child") > -1
          );
        }
        // female and not male
        if (
          filters.welcome_genders.indexOf("female") > -1 &&
          filters.welcome_genders.indexOf("male") < 0
        ) {
          clubs = clubs.filter(
            club =>
              club.welcome_genders.indexOf("male") < 0 &&
              club.welcome_genders.indexOf("female") > -1
          );
        }
        // male and not female
        if (
          filters.welcome_genders.indexOf("male") > -1 &&
          filters.welcome_genders.indexOf("female") < 0
        ) {
          clubs = clubs.filter(
            club =>
              club.welcome_genders.indexOf("female") < 0 &&
              club.welcome_genders.indexOf("male") > -1
          );
        }
      }
      if (filters.languages && filters.languages.length) {
        console.log('Language filter before', clubs, filters);
        clubs = clubs.filter(club => {
          return filters.languages.every(type => club.langs.indexOf(type) > -1);
        });
        console.log('Language filter after', clubs, filters);
      }
    //   if (filters.clubs_that_play_course.length && this.props.facilities.length) {
    //     const fac_id = parseInt(filters.clubs_that_play_course);
    //     const facility = this.props.facilities.find(
    //       fac => parseInt(fac.id) === fac_id
    //     );
        
    //     clubs = clubs.filter(club => {
    //       return club.ourFacilities.some(
    //         fac => fac.slug === facility.url_title || fac.slug === facility.slug
    //       );
    //     });
    //   }
      return clubs;

}

export default {
    fetchFilters,
    defaultFilters,
    filterClubs
};