import React, { PureComponent, ReactPropTypes } from "react";
import { addUrlProps, UrlQueryPropParamTypes } from "react-url-query";
import LocationSearch from "./LocationSearch";
import ClubSearch from "./ClubSearch";
import CourseSearch from "./CourseSearch";
import TypeLinks from "./TypeLinks";
import ScgaLinkLogo from "./ScgaLinkLogo";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
// import qs from 'qs';
import { connect } from "react-redux";
import axios from "axios";
import _ from "lodash";
const api = "https://clubs.scga.org/api/";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      myLocation: this.props.myLocation,
      radius: this.props,
      // searchType: this.props.searchType,
      searchString: null,
      address: "",
      urlFilters: "",
      location: this.props.location
    };
    this.setLocationSearch = this.setLocationSearch.bind(this);
    this.setClubSearch = this.setClubSearch.bind(this);
    this.getFacilities = this.getFacilities.bind(this);
    this.handleChangeRadius = this.handleChangeRadius.bind(this);
    this.updateFiltersFromQueryString = this.updateFiltersFromQueryString.bind(
      this
    );
  }
  componentDidMount() {
    const { location } = this.props;
    const urlFilters = queryString.parse(location.search);

    this.updateFiltersFromQueryString();
    this.getFacilities();

    // const location = this.state.location;
    console.log("Header:cmpDidMnt: location: ", location.search, this.props);
    // const str = qs.stringify(this.props.filtersSet)
    // console.log("Header: query string from filtersSet: ", str)
    // see if it came in from Route (in Layout) with setSearch
    if (this.props.setSearch) {
      console.log(
        "Header:cmpDidMnt: setting search type to ",
        this.props.setSearch
      );
      this.props.onChangeSearchType(
        this.props.setSearch,
        this.props.filtersSet
      );
    }
    console.log("test", this.props, this.state, urlFilters);

    if ((urlFilters.lat && urlFilters.lng) || urlFilters.location) {
    } else {
      this.props.onSetMyLocation(this.props.myLocation);
    }
    // this.props.onSetMyLocation(this.props.myLocation);

    let params = new URLSearchParams(location.search);
    console.log("Header::componentDidMount: params: ", params);
    // this.props.history.push(queryString.stringify(this.props.fullState))
    // console.log("Header::compDidMnt: urlFilters: ",urlFilters)
    //if there's a query string, sync with filters in state
    /*
        if(urlFilters){
            let changed=false;
            // const new_url_vals = {...this.props.filtersSet, radius: this.props.radius, max_cost: this.props.max_cost}
            // see if any
            if(urlFilters.radius && parseInt(urlFilters.radius) !== parseInt(this.state.radius)) {
                changed = true;
                new_url_vals.radius = parseInt(urlFilters.radius);
            }
            if(urlFilters.max_cost && parseInt(urlFilters.max_cost) !== parseInt(this.state.max_cost)) {
                changed = true;
                new_url_vals.max_cost = parseInt(urlFilters.max_cost);
            }
            if (changed)
                this.props.setFilters(new_url_vals)
        }
*/
  }

  componentWillReceiveProps(nextProps) {
    const { match, location } = this.props;
    const urlFilters = queryString.parse(location.search);
    const nextUrlFilters = queryString.parse(nextProps.location.search);
    if (
      nextProps.clubs &&
      nextProps.clubs.length &&
      !nextProps.selectedClub.id
    ) {
      console.log(
        "Header componentWillReceiveProps",
        nextProps,
        urlFilters,
        nextProps.clubs.length
      );

      if ("club" in nextUrlFilters && nextProps.clubs.length > 0) {
        const club = nextProps.clubs.find(
          club =>
            club.url_title.toLowerCase() === nextUrlFilters.club.toLowerCase()
        );
        console.log("Header::cmpntDidMnt: club: ", club);
        if (club) this.props.onSetSelectedClub(club);
      }
    }
    if (!_.isEqual(urlFilters, nextUrlFilters)) {
      var foo = _.reduce(
        Object.keys(this.props.filtersSet),
        (result, key) => {
          let val = nextUrlFilters[key] || [];
          if (typeof val == "string") {
            val = [val];
          }
          result[key] = val;
          return result;
        },
        {}
      );
      console.log("HEADER: notEqual:", nextUrlFilters, urlFilters, foo);

      this.props.onChangeFilter({
        ...foo
      });
    }
  }

  /*
    componentWillReceiveProps(nextProps) {
        if (nextProps.clubs){
            // this.props.onSetMyLocation(this.props.myLocation)
        }

        console.log("Header::willReceive Props: clubs", nextProps.clubs)
        const urlFilters = queryString.parse(this.state.location.search)

        if ('club' in urlFilters) {
            const club = this.props.clubs.find(club => club.url_title === urlFilters.club)
            console.log("Header::cmpntDidMnt: club: ", club)
            this.props.onSetSelectedClub(club)
        }
        if ('region' in urlFilters) {
            this.props.onSetRegion(urlFilters.region)
        }
        if ('city' in urlFilters) {
            this.props.onSetCity(urlFilters.city)
        }
        if ('clubs_that_play_course' in urlFilters) {
            this.getFacilities();
            let filtersSet = {...this.props.filtersSet};
            const course = urlFilters.clubs_that_play_course
            console.log("Header: clubs_that_play_course: ", course)
            const facility = this.props.facilities.find(fac => fac.slug === course)
            console.log("Header: facility: ", facility.title)
            filtersSet.clubs_that_play_course = [facility];
            const location = {lat: parseFloat(facility.latitude), lng: parseFloat(facility.longitude)};
            this.props.onFacilitySelect(filtersSet, location);
        }

        if ('location' in urlFilters) {
            if (this.props.searchString !== urlFilters.location) {
                console.log("Header::cmpntDidMnt: location, rad: ", urlFilters.location, this.props.rad)
                this.props.onSelectGooglePlace(urlFilters.location, this.props.rad)
                // history.push("byLocation?" + qs.stringify(urlFilters.location))
            }
        }

    }
*/

  /**
   *
   */
  updateFiltersFromQueryString() {
    const { location } = this.props;
    const urlFilters = queryString.parse(location.search);
    var foo = _.reduce(
      Object.keys(this.props.filtersSet),
      (result, key) => {
        let val = urlFilters[key] || [];
        if (typeof val == "string") {
          val = [val];
        }
        result[key] = val;
        return result;
      },
      {}
    );
    console.log("HEADER: componentDidMount:", urlFilters, foo);

    this.props.onChangeFilter({
      ...foo
    });
  }
  getFacilities() {
    axios
      .get(api + "facilities")
      .then(response => response.data)
      .then(data => {
        // console.log(data)
        this.props.onSetFacilities(data);
      })
      .catch(error => console.error(error));
  }

  setLocationSearch(e) {
    e.preventDefault();
    this.setState({ searchType: "location" });
  }
  setClubSearch(e) {
    e.preventDefault();
    this.setState({ searchType: "club" });
    // this.props.setSearchType("club");
  }
  /**
   * handles the case where user updates 'within' radius input
   *
   * */
  handleChangeRadius(e) {
    // console.log("Header::handleChangeRadius")
    const {
      target: { value }
    } = e;
    this.props.onChangeRadius({ radius: parseInt(value) });
  }
  updateUrlFromState() {
    const new_url_vals = {
      ...this.state.filtersSet,
      radius: this.state.radius,
      max_cost: this.state.max_cost
    };
    console.log(new_url_vals);
    const new_url = queryString.stringify(new_url_vals);
    console.log("Results::updateUrlFromState: new_url");
    console.log(new_url);
    this.props.history.push("?" + new_url);
  }

  render() {
    const { myLocation, searchType } = this.props;
    const { location } = this.props.history;

    const query = location.pathname.split("?");
    // const headerWithResults = !!location.search;

    const hasResults = !![
      "/popular",
      "/travel",
      "/womens",
      "/senior",
      "/social",
      "/events",
      "/bylocation",
      "/byclub",
      "/search"
    ].find(path => location.pathname.toLowerCase() == path.toLowerCase());
    const headerWithResults = !!query[1] || hasResults;

    let searchComponent;
    if(searchType === "location") {
      searchComponent = <LocationSearch />;
    } else if( searchType === "course") {
      searchComponent = <CourseSearch />;
    } else {
      searchComponent = <ClubSearch backToResults={this.setLocationSearch} />;
    }

    console.log("Header render: ", searchType);

    let searchBar = null;
    if(searchType === 'location') {
      searchBar = <LocationSearch />;
    } else if (searchType === 'course') {
      searchBar = <CourseSearch />;
      console.log('CourseSearch')
    } else {
      searchBar = <ClubSearch backToResults={this.setLocationSearch} />;
    }

    return (
      <div>
        <div className="container-fluid header">
          <div
            className={
              headerWithResults ? "page-header results" : "page-header"
            }
          >
            <div className="top-row">
              <div className="mobile-logo">
                <ScgaLinkLogo height={70} />
              </div>
              <div className="control-col">
                {searchBar}
                {/* {!!myLocation && <TypeLinks style={{ zIndex: 1 }} />} */}
              </div>

              { hasResults && (<ScgaLinkLogo  height={70} />)}

            </div>
          </div>
        </div>
      </div>
    );
  }
}
function changeSearchType(searchType, value) {
  console.log("changeSearchType: ", searchType);
  if (searchType === "location") {
    return function(dispatch) {
      dispatch({ type: "CLEAR_SELECTED_CLUB", value: value });
      dispatch({ type: "CHANGE_SEARCHTYPE", value: searchType });
    };
  }
  if (searchType === "club") {
    return function(dispatch) {
      dispatch({ type: "SET_LOCATION", value: "" });
      dispatch({ type: "CHANGE_SEARCHTYPE", value: searchType });
      dispatch({ type: "SELECT_BY_CLUB", club: value });
    };
  }
}
function setSelectedClub(club) {
  console.log("setSelectedClub: ", club);
  return function(dispatch) {
    dispatch({ type: "SET_LOCATION", value: "" });
    dispatch({ type: "SELECT_BY_CLUB", club: club });
    // dispatch({type: 'CHANGE_SEARCHTYPE', value: "club"})
  };
}
function setLocation(searchString) {
  return function(dispatch) {
    dispatch({ type: "CHANGE_SEARCHTYPE", value: "location" });
    dispatch({ type: "SET_LOCATION", value: searchString });
    // dispatch({type: 'CLEAR_SELECTED_CLUB', value: this.props.filtersSet})
  };
}
function getLocation() {
  return dispatch => {
    const geolocation = navigator.geolocation;
    geolocation.getCurrentPosition(
      position => {
        dispatch({
          type: "CHANGE_MYLOCATION",
          payload: {
            location: {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            },
            allowed_location: true
          }
        });
      },
      () => {
        // if user did not allow location
        // dispatch({type: 'SET_ADDRESS', value: 'Los Angeles, CA, USA'})

        dispatch({
          type: "CHANGE_MYLOCATION",
          payload: {
            location: { lat: 34.052235, lng: -118.243683 },
            allowed_location: false
          }
        });
      }
    );
  };
}
const mapStateToProps = state => {
  return {
    clubs: state.clubs,
    facilities: state.facilities,
    filtersSet: state.filtersSet,
    locationSet: state.locationSet,
    myLocation: state.myLocation,
    rad: state.radius,
    searchType: state.searchType,
    selectedClub: state.selectedClub
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onChangeRadius: evt =>
      dispatch({ type: "CHANGE_RADIUS", value: evt.value }),
    onChangeFilter: filter =>
      dispatch({ type: "CHANGE_FILTER", value: filter }),
    onFacilitySelect: (filtersSet, location) =>
      dispatch({
        type: "SET_CLUBS_THAT_PLAY",
        payload: { filtersSet: filtersSet, geoLocation: location }
      }),
    onChangeSearchType: (val, filtersSet) =>
      dispatch(changeSearchType(val, filtersSet)),
    onChangeSearchString: (address, geoLocation) =>
      dispatch({
        type: "CHANGE_SEARCHSTRING",
        payload: { address: address, geoLocation: geoLocation }
      }),
    onSetRegion: val => dispatch({ type: "SET_REGION", value: val }),
    onSetCity: val => dispatch({ type: "SET_CITY", value: val }),
    onSetSelectedClub: club => dispatch(setSelectedClub(club)),
    onSetLocation: searchString => dispatch(setLocation(searchString)),
    onSetFacilities: facilities =>
      dispatch({ type: "SET_FACILITIES", value: facilities }),
    onSetMyLocation: () => dispatch(getLocation())
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
