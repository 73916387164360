import React, { Component } from "react";
import Layout from "./Layout/Layout";
import Content from "./Layout/Content";
import Popular from "./Results/Popular";
import Search from "./Results/Search";
import Travel from "./Results/Travel";
import Womens from "./Results/Womens";
import Senior from "./Results/Senior";
import Social from "./Results/Social";
import ByLocation from "./Results/ByLocation";
import ByClub from "./Results/ByClub";
import EventsPage from "./Results/EventsPage";
import { BrowserRouter } from "react-router-dom";
import { connect } from "react-redux";
import queryString from "query-string";
import axios from "axios";
import ReactPlaceholder from "react-placeholder";
import ResultCard from "./Results/ResultCard";
const api = "https://clubs.scga.org/api/";

class App2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // note myLocation does not need to be in store as it should not change
      myLocation: {},
      featuredClub: {}
    };
  }

  componentDidMount() {
    const { match, location } = this.props;
    const qs = queryString.parse(location.search);
    console.log("ResultPage: componentDidMount", qs, match);
    if (!this.props.clubs) {
      this.getClubs();
    }
    if (qs.club) {
      this.props.onChangeSearchType();
    }
  }

  getClubs() {
    axios
      .get(api + "clubs")
      .then(response => response.data)
      .then(data => {
        this.props.onSetClubs(data);
      })
      .catch(error => console.error(error));
  }

  render() {
    const {
      clubs,
      match,
      myLocation,
      match: { path }
    } = this.props;
    console.log(
      "ResultPage::render: myLocation: ",
      this.props,
      path,
      myLocation
    );
    let page = <Content matchtwo={match} />;
    switch (path.toLowerCase()) {
      case "/popular":
        page = <Popular matchtwo={match} locationType={"current"} />;
        break;
      case "/search":
        page = <Search matchtwo={match} locationType={"current"} />;
        break;
      case "/travel":
        page = <Travel matchtwo={match} locationType={"current"} />;
        break;
      case "/womens":
        page = <Womens matchtwo={match} locationType={"current"} />;
        break;
      case "/senior":
        page = <Senior matchtwo={match} locationType={"current"} />;
        break;
      case "/social":
        page = <Social matchtwo={match} locationType={"current"} />;
        break;
      case "/events":
        page = <EventsPage matchtwo={match} locationType={"geo"} />;
        break;
      case "/bylocation":
        page = <ByLocation matchtwo={match} locationType={"geo"} />;
        break;
      case "/byclub":
        page = <ByClub matchtwo={match} locationType={"geo"} />;
        break;
      default:
        page = <Content matchtwo={match} locationType={"geo"} />;
    }

    const ready = this.props.clubs && !!this.props.clubs.length;
    const loadingText = {
      wePlay: {},
      description: "lorem ipsum",
      tags: [],
      upcoming: [],
      benefits: [],
      club_benefits: [],
      ourFacilities: []
    };
    const Loader = () => (
      <div className="spinner">
        <div className="rect1" />
        <div className="rect2" />
        <div className="rect3" />
        <div className="rect4" />
        <div className="rect5" />
      </div>
    );
    return (
      // <BrowserRouter>
      <div className="App">
        <Layout myLocation={myLocation} searchType={this.props.searchType}>
          {/* {page} */}
          <ReactPlaceholder customPlaceholder={<Loader />} ready={ready}>
            {page}
          </ReactPlaceholder>
        </Layout>
      </div>
      // </BrowserRouter>
    );
  }
}

const mapStateToProps = state => {
  return {
    clubs: state.clubs,
    geoLocation: state.geoLocation,
    searchString: state.searchString,
    myLocation: state.myLocation,
    query_string: state.query_string,
    searchType: state.searchType,
    featuredClub: state.featuredClub
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onSelectClub: club => dispatch({ type: "SET_SELECTED_CLUB", value: club }),
    onChangeSearchType: () =>
      dispatch({ type: "CHANGE_SEARCHTYPE", value: "club" }),
    onFilterChange: filtersSet =>
      dispatch({ type: "CHANGE_FILTER", value: filtersSet }),
    onFacilitySelect: filtersSet =>
      dispatch({ type: "CLEAR_SELECTED_CLUB", value: filtersSet }),
    onSetFeaturedClub: featured =>
      dispatch({ type: "SET_FEATURED_CLUB", value: featured }),
    onSetClubs: clubs => dispatch({ type: "SET_CLUBS", value: clubs })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App2);
