import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

const initialState = {
  address: "",
  allowed_location: false,
  city: "",
  clubs: undefined,
  defaultSearchString: "",
  featuredClub: null,
  events: [],
  eventsThisMonthCount: 0,
  eventsFutureCount: 0,
  facilities: [],
  filtersSet: {
    skill_level: [],
    play_days: [],
    average_age: [],
    whys: [],
    club_types: [],
    game_styles: [],
    member_types: [],
    languages: [],
    welcome_genders: [],
    clubs_that_play_course: []
  },
  geoLocation: {},
  hotLink: null,
  locationSet: false,
  max_cost: 200,
  map_compressed: false,
  myLocation: null,
  query_string: "",
  radius: 20,
  region: "",
  resultsTitle: "communities near you",
  searchSimilar: null,
  searchString: "",
  searchType: "location",
  selected: false,
  selectedClub: {},
  showOverlay: true,
  show_filters: false,
  zoom: null,
  center: null
};
console.log(
  "%c reducer: TEST ",
  "color: green; font-weight: bold;",
  initialState
);

const reducer = (state = initialState, action) => {
  if (action.type !== "SET_FEATURED_SIMILAR") {
    state = { ...state, searchSimilar: null };
  }
  if (action.type !== "SET_REGION") {
    state = { ...state, region: null };
  }
  if (action.type !== "SET_CITY") {
    state = { ...state, city: null };
  }
  console.log(
    "%c reducer: ",
    "color: yellow; font-weight: bold;",
    action.type,
    action.value
  );
  switch (action.type) {
    case "SET_CLUBS":
      let clubs3 = action.value.map(el => {
        if (state.geoLocation && state.geoLocation.lat) {
          el.miles =
            Math.sqrt(
              Math.pow(
                parseFloat(state.geoLocation.lat) - parseFloat(el.latitude),
                2
              ) +
                Math.pow(
                  parseFloat(state.geoLocation.lng) - parseFloat(el.longitude),
                  2
                )
            ) * 69.0975851;
        }
        if (state.myLocation && state.myLocation.lat) {
          el.myMiles =
            Math.sqrt(
              Math.pow(
                parseFloat(state.myLocation.lat) - parseFloat(el.latitude),
                2
              ) +
                Math.pow(
                  parseFloat(state.myLocation.lng) - parseFloat(el.longitude),
                  2
                )
            ) * 69.0975851;
        }

        return el;
      });
      console.log("reducer: SET_CLUBS ", state, action, clubs3);

      return {
        ...state,
        clubs: clubs3
      };
    case "SET_FACILITIES":
      return {
        ...state,
        facilities: action.value
      };
    case "SET_EVENTS":
      return {
        ...state,
        events: action.payload.events,
        eventsFutureCount: parseInt(action.payload.eventsFutureCount),
        eventsThisMonthCount: action.payload.eventsThisMonthCount
      };
    case "SET_FEATURED_CLUB":
      return {
        ...state,
        featuredClub: action.value
      };
    case "CHANGE_SEARCHTYPE":
      return {
        ...state,
        searchType: action.value,
        center: null,
        zoom: null
      };
    case "CHANGE_RADIUS":
      return {
        ...state,
        radius: parseInt(action.value)
      };
    case "CHANGE_MAXCOST":
      return {
        ...state,
        max_cost: parseInt(action.value)
      };
    case "UPDATE_CLUBS_WITH_MILES":
      return {
        ...state,
        clubs: action.value
      };
    case "SET_LOCATION":
      return {
        ...state,
        searchString: action.value,
        locationSet: true
      };
    case "UNSET_LOCATION":
      return {
        ...state,
        searchString: "",
        locationSet: false
      };
    case "SET_DEFAULT_SEARCHSTRING":
      return {
        ...state,
        defaultSearchString: action.value
      };
    case "CHANGE_SEARCHSTRING":
      console.log(
        "%c reducer: CHANGE_SEARCHSTRING ",
        "color: green; font-weight: bold;",
        state,
        action
      );
      return {
        ...state,
        searchString: action.payload.address,
        geoLocation: action.payload.geoLocation,
        locationSet: true,
        clubs:
          state.clubs &&
          state.clubs.map(el => {
            el.miles =
              Math.sqrt(
                Math.pow(action.payload.geoLocation.lat - el.latitude, 2) +
                  Math.pow(action.payload.geoLocation.lng - el.longitude, 2)
              ) * 69.0975851;
            return el;
          }),
        address: action.payload.address,
        center: null,
        zoom: null
      };
    case "CHANGE_MYLOCATION":
      console.log("reducer: myLocation: ", action.payload);
      let clubs2 = !!state.clubs ? state.clubs : [];
      clubs2 = clubs2.map(el => {
        el.myMiles =
          Math.sqrt(
            Math.pow(action.payload.location.lat - parseFloat(el.latitude), 2) +
              Math.pow(
                action.payload.location.lng - parseFloat(el.longitude),
                2
              )
          ) * 69.0975851;

        return el;
      });

      return {
        ...state,
        clubs: clubs2,
        allowed_location: action.payload.allowed_location,
        myLocation: action.payload.location,
        center: null,
        zoom: null
      };
    case "SET_SELECTED_CLUB":
      console.log(
        "%c reducer: SET_SELECTED_CLUB ",
        "color: green; font-weight: bold;",
        state,
        action
      );
      return {
        ...state,
        searchType: "club",
        selectedClub: action.value,
        selected: true,
        geoLocation: {
          lat: parseFloat(action.value.latitude),
          lng: parseFloat(action.value.longitude)
        },
        query_string: "club=" + action.value.url_title,
        center: null,
        zoom: null,
        showOverlay: true,
        map_compressed: false
      };
    case "SELECT_ADDRESS":
      const clubs = !!state.clubs ? state.clubs : [];
      console.log(
        "%c reducer: SELECT_ADDRESS ",
        "color: green; font-weight: bold;",
        state,
        action
      );

      return {
        ...state,
        address: action.payload.address,
        searchString: action.payload.address,
        geoLocation: action.payload.geoLocation,
        // radius: action.payload.radius,
        locationSet: true,
        selectedClub: {},
        selected: false,
        // update clubs miles from new location
        clubs: clubs.map(el => {
          el.miles =
            Math.sqrt(
              Math.pow(action.payload.geoLocation.lat - el.latitude, 2) +
                Math.pow(action.payload.geoLocation.lng - el.longitude, 2)
            ) * 69.0975851;
          return el;
        }),
        center: null,
        zoom: null
      };
    case "CHANGE_FILTER":
      return {
        ...state,
        filtersSet: action.value
      };
    case "CLEAR_SELECTED_CLUB":
      // const new_zoom = state.zoom ? state.zoom : null;
      const center = state.center ? state.center : null;
      return {
        ...state,
        selectedClub: {},
        selected: false,
        filtersSet: action.value,
        // zoom: null,     // set to null so map will setbounds
        center: center
      };
    case "SET_FEATURED_SIMILAR":
      console.log(
        "%c reducer: SET_FEATURED_SIMILAR ",
        "color: green; font-weight: bold;",
        state,
        action
      );

      return {
        ...state,
        searchSimilar: action.payload.club_id,
        selectedClub: {},
        selected: false,
        filtersSet: action.payload.filtersSet,
        locationSet: true,
        geoLocation: action.payload.geoLocation,
        clubs: state.clubs.map(el => {
          el.miles =
            Math.sqrt(
              Math.pow(action.payload.geoLocation.lat - el.latitude, 2) +
                Math.pow(action.payload.geoLocation.lng - el.longitude, 2)
            ) * 69.0975851;
          return el;
        }),
        center: null,
        zoom: null
      };
    case "SET_CLUBS_THAT_PLAY":
      console.log(
        "%c reducer: SET_CLUBS_THAT_PLAY ",
        "color: green; font-weight: bold;",
        state,
        action
      );

      return {
        ...state,
        selectedClub: {},
        selected: false,
        filtersSet: action.payload.filtersSet,
        locationSet: true,
        geoLocation: action.payload.geoLocation,
        clubs:
          state.clubs &&
          state.clubs.map(el => {
            el.miles =
              Math.sqrt(
                Math.pow(action.payload.geoLocation.lat - el.latitude, 2) +
                  Math.pow(action.payload.geoLocation.lng - el.longitude, 2)
              ) * 69.0975851;
            return el;
          }),
        center: null,
        zoom: null
      };
    case "SELECT_BY_CLUB":
      console.log(
        "%c reducer: SELECT_BY_CLUB ",
        "color: green; font-weight: bold;",
        state,
        action
      );

      return {
        ...state,
        selectedClub: action.club,
        selected: true,
        searchType: "club",
        geoLocation: {
          lat: parseFloat(action.club.latitude),
          lng: parseFloat(action.club.longitude)
        },
        locationSet: true,
        clubs:
          state.clubs &&
          state.clubs.map(el => {
            el.miles =
              Math.sqrt(
                Math.pow(action.club.latitude - el.latitude, 2) +
                  Math.pow(action.club.longitude - el.longitude, 2)
              ) * 69.0975851;
            return el;
          }),
        center: null,
        zoom: null
      };
    case "SET_HOTLINK":
      console.log(
        "%c reducer: SET_HOTLINK ",
        "color: green; font-weight: bold;",
        state,
        action
      );

      // set filters for certain hotlinks
      let filtersSet = { ...state.filtersSet };
      if (action.value === "womens") {
        filtersSet["welcome_genders"] = ["female"];
        console.log("reducer SET_HOTLINK: filtersSet: ", filtersSet);
        state.filtersSet = filtersSet;
      }
      if (action.value === "social") {
        filtersSet["whys"] = ["fun-social-golf"];
        filtersSet["game_styles"] = ["social-non-competitive"];
        console.log("reducer SET_HOTLINK: filtersSet: ", action.value, filtersSet);
        state.filtersSet = filtersSet;
      }
      return {
        ...state,
        // filtersSet: filtersSet,
        hotLink: action.value,
        locationSet: true,
        // geoLocation: state.myLocation,
        clubs: state.clubs.map(el => {
          if (state.myLocation) {
            el.myMiles =
              Math.sqrt(
                Math.pow(state.myLocation.lat - el.latitude, 2) +
                  Math.pow(state.myLocation.lng - el.longitude, 2)
              ) * 69.0975851;
          }
          if (state.geoLocation) {
            el.miles =
              Math.sqrt(
                Math.pow(state.geoLocation.lat - el.latitude, 2) +
                  Math.pow(state.geoLocation.lng - el.longitude, 2)
              ) * 69.0975851;
          }

          return el;
        }),
        center: null,
        zoom: null
      };
    case "SET_REGION":
      console.log(
        "%c reducer: SET_REGION ",
        "color: green; font-weight: bold;",
        state,
        action
      );
      return {
        ...state,
        region: action.value,
        locationSet: true,
        clubs: state.clubs.map(el => {
          if (state.myLocation && state.myLocation.lat) {
            el.myMiles =
              Math.sqrt(
                Math.pow(state.myLocation.lat - el.latitude, 2) +
                  Math.pow(state.myLocation.lng - el.longitude, 2)
              ) * 69.0975851;
          }
          if (state.geoLocation && state.geoLocation.lat) {
            el.miles =
              Math.sqrt(
                Math.pow(state.geoLocation.lat - el.latitude, 2) +
                  Math.pow(state.geoLocation.lng - el.longitude, 2)
              ) * 69.0975851;
          }

          return el;
        }),
        center: null,
        zoom: null
      };
    case "SET_CITY":
      console.log("reducer: SET_CITY ", state, action);
      return {
        ...state,
        city: action.value,
        locationSet: true,
        clubs: state.clubs.map(el => {
          if (state.myLocation && state.myLocation.lat) {
            el.myMiles =
              Math.sqrt(
                Math.pow(state.myLocation.lat - el.latitude, 2) +
                  Math.pow(state.myLocation.lng - el.longitude, 2)
              ) * 69.0975851;
          }
          if (state.geoLocation && state.geoLocation.lat) {
            el.miles =
              Math.sqrt(
                Math.pow(state.geoLocation.lat - el.latitude, 2) +
                  Math.pow(state.geoLocation.lng - el.longitude, 2)
              ) * 69.0975851;
          }

          return el;
        }),
        center: null,
        zoom: null
      };
    case "SET_QUERYSTRING":
      return {
        ...state,
        query_string: action.value
      };
    case "SET_MAP_CENTER_ZOOM":
      return {
        ...state,
        zoom: action.payload.zoom,
        center: action.payload.center
      };
    case "UNSET_MAP_CENTER_ZOOM":
      return {
        ...state,
        zoom: null,
        center: null
      };
    case "SHOW_FILTER_OPTIONS":
      return {
        ...state,
        show_filters: true
      };
    case "HIDE_FILTER_OPTIONS":
      return {
        ...state,
        show_filters: false
      };
    case "HIDE_MAP_OVERLAY":
      return {
        ...state,
        showOverlay: false
      };
    case "SHOW_MAP_OVERLAY":
      return {
        ...state,
        showOverlay: true
      };
    case "SET_MAP_OVERLAY":
      return {
        ...state,
        showOverlay: action.value
      };
    case "SET_MAP_COMPRESSED":
      const showOverlay = action.value
        ? false
        : state.selected
        ? true
        : state.showOverlay;
      const zoom = state.zoom ? (state.zoom < 10 ? 10 : state.zoom) : 14;
      console.log("reducer: zoom: ", state.zoom, zoom);
      return {
        ...state,
        map_compressed: action.value,
        showOverlay: showOverlay,
        zoom: zoom
      };
    default:
      return { ...state };
  }
};

export default reducer;
