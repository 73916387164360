import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class MapMarker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      club: this.props.club
    };
    this.handleMarkerClick = this.handleMarkerClick.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      selected: nextProps.selected,
      selectedClub: nextProps.selectedClub,
      center: {
        lat: parseFloat(nextProps.lat),
        lng: parseFloat(nextProps.lng)
      },
      club: nextProps.club
    });
  }
  handleMarkerClick() {
    const { club } = this.state;
    this.props.history.push(
      `/byClub?club=${club.url_title}&lat=${club.latitude}&lng=${
        club.longitude
      }`
    );
    this.props.onSelectClub(club);
  }

  render() {
    return (
      <div
        className="marker"
        title={this.state.club.title}
        onClick={this.handleMarkerClick.bind(this)}
        style={{ cursor: "pointer" }}
      >
        <img src="/images/highball.png" alt="sup" />
      </div>
    );
  }
}
function setSelectedClub(club) {
  return function(dispatch) {
    dispatch({ type: "SET_SELECTED_CLUB", value: club });
    dispatch({ type: "CHANGE_SEARCHTYPE", value: "club" });
  };
}

const mapDispatchToProps = dispatch => {
  return {
    onSelectClub: club => dispatch(setSelectedClub(club))
  };
};
export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(MapMarker)
);
