import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import queryString from "query-string";
import _ from "lodash";

class CheckboxFilter extends Component {
  static defaultProps = {
    fields: [],
    clubs: [],
    filtersSet: {},
    updateParam: true,
    hideTitle: false,
    onFilterUpdate: _.noop
  };
  onFieldChange = e => {
    // destruct the event to local consts
    const {
      target: { name, value, checked }
    } = e;
    let parsed = queryString.parse(this.props.location.search);
    // get local copy of state filtersSet
    console.log("CheckboxFilters::onFieldChange: name, value: ", name, value);
    let filtersSet = { ...this.props.filtersSet };
    console.log(
      "Filters::onFieldChange: filtersSet: before",
      filtersSet,
      parsed
    );
    // get the specific filter passed
    let thisArray = filtersSet[name];
    // add the passed value
    if (checked) {
      thisArray.push(value);
    } else {
      thisArray = thisArray.filter(field => field !== value);
    }

    // welcome_genders is a radio, so filter on only this value
    if (name === "welcome_genders") {
      thisArray = thisArray.filter(field => field === value);
    }
    // merge back into filters
    filtersSet[name] = thisArray;
    parsed[name] = thisArray;
    // update store with state
    console.log(
      "Filters::onFieldChange: filtersSet: after",
      filtersSet,
      parsed
    );
    const path = `${this.props.location.pathname}?${queryString.stringify(parsed)}`;
    this.props.onFilterUpdate(filtersSet, queryString.stringify(filtersSet));

    this.props.onFilterChange(filtersSet);
    if(this.props.updateParam) {
      this.props.history.push(
        path
      );
    }

    // this.setState({filtersSet: filtersSet});
    // this.updateUrlFromState()
    // this.props.history.push(queryString.stringify(filtersSet));
  };
  render() {
    let {
      config,
      name,
      clubs,
      fields,
      filtersSet,
      includedClass,
      shouldDisable,
      invertColor,
      hideTitle
    } = this.props;

    return (
      <div style={{padding: `${invertColor ? '0px 5%' : '0'}`}}>
        <div className={config.includedClass}>
          <div className={`${invertColor ? 'card-block-modal': 'card-block'}`}>
            {hideTitle ? null : <h5 className="card-title">{config.title}</h5>}
            {_.map(config.fields, field => {
              const propName = field.propOverride || config.propName;
              return (
                <div key={field.filterName.toString()} style={{ fontWeight: invertColor ? '600' : 'normal' }} className="form-check">
                  <label
                    className={
                      !!field.disable
                        ? "form-check-label disabled"
                        : "form-check-label"
                    }
                  >
                    {field.name}
                    <input
                      name={propName}
                      className="form-check-input"
                      type="checkbox"
                      value={field.filterName}
                      onChange={this.onFieldChange}
                      checked={
                        filtersSet[propName].indexOf(field.filterName) > -1
                          ? "checked"
                          : ""
                      }
                      disabled={!!field.disable}
                    />
                    <span style={{ border: `1px solid #888` }} className="form-check__checkbox" />
                  </label>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

CheckboxFilter.propTypes = {
  clubs: PropTypes.arrayOf(PropTypes.object),
  fields: PropTypes.arrayOf(PropTypes.object),
  config: PropTypes.object
};

const mapStateToProps = state => {
  return {
    filtersSet: state.filtersSet,
    geoLocation: state.geoLocation,
    max_cost: state.max_cost,
    radius: state.radius,
    selected: state.selected,
    selectedClub: state.selectedClub,
    show_filters: state.show_filters
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onChangeSomething: (type, value) => dispatch({ type, value }),
    onChangeRadius: val => dispatch({ type: "CHANGE_RADIUS", value: val }),
    onChangeMaxCost: val => dispatch({ type: "CHANGE_MAXCOST", value: val }),
    onChangeSearchType: () => dispatch({ type: "CHANGE_SEARCHTYPE" }),
    onFilterChange: filtersSet =>
      dispatch({ type: "CHANGE_FILTER", value: filtersSet }),
    onSelectClub: club => dispatch({ type: "SET_SELECTED_CLUB", value: club }),
    onClearSelectedClub: filtersSet =>
      dispatch({ type: "CLEAR_SELECTED_CLUB", value: filtersSet })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CheckboxFilter));
