import React, { Component } from "react";
import Slider from 'rc-slider';
import queryString from "query-string";
import Layout from "./Layout/Layout";
import Content from "./Layout/Content";
import { BrowserRouter } from "react-router-dom";
import { connect } from "react-redux";
import QueryString from "query-string";
import axios from "axios";
import Modal from 'react-modal';
import _ from 'lodash';
import filterConfig from './config/filterConfig';
import CheckboxFilters from "./Filters/CheckboxFilter";
import cookie from './lib/cookie';
import { isObjectLiteralElementLike } from "typescript";
const api = "https://clubs.scga.org/api/";

Modal.setAppElement('#root');
const customStyles = {
  content : {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    width: '60%',
    height: '600px',
    transform: 'translate(-50%, -50%)',
    padding: '0px',
    
  }
};

function filterClubsByRadius(currentLocation = {}, clubs, radius) {
  return clubs.filter(el => {
    const miles =
      Math.sqrt(
        Math.pow(currentLocation.lat - el.latitude, 2) +
          Math.pow(currentLocation.lng - el.longitude, 2)
      ) * 69.0975851;
    return radius > miles;
  })
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // note myLocation does not need to be in store as it should not change
      myLocation: {},
      featuredClub: {},
      modalIsOpen: true,
      currentFilter: null,
      currentFilterIndex: 0,
      filterPath: '',
      radius: 1000,
      max_cost: 200
    };
  }

  makeArray = (data = {}) => {
    let newData = {};
    Object.keys(data).map((key) => {
      let val = data[key];
      newData[key] = typeof val == 'string' ? [val] : val;
    });
    return newData;
  }

  componentDidMount() {
    const { match, location } = this.props;
    const { modalIsOpen } = this.state;
    const parsed = QueryString.parse(location.search);

    console.log('componentDidMount::parsed: ', this.makeArray(parsed), location.search);
    const showedModal = cookie.getCookie('showedModal');
    this.setState({
      ...this.state,
      currentFilter: this.makeArray(parsed),
      // filterPath: location.search + `&radius=70&max_cost=200`
      filterPath: location.search
    });
    if (showedModal) {
      this.setState({ modalIsOpen: false });
    }
    if (!this.props.clubs) {
      this.getClubs();
    }
  }

  getClubs() {
    axios
      .get(api + "clubs")
      .then(response => response.data)
      .then(data => {
        this.props.onSetClubs(data);
      })
      .catch(error => console.error(error));
  }
  afterOpenModal = () => {

  }
  openModal = () => {
    console.log('openModal');
    this.setState({
      modalIsOpen: true
    });    
  }
  closeModal = () => {
    this.setState({
      modalIsOpen: false
    });
    cookie.setCookie('showedModal', 1, 365);
  }
  nextFilter = () => {
    this.setState({
      ...this.state,
      currentFilterIndex: this.state.currentFilterIndex + 1
    });
  }
  previousFilter = () => {
    this.setState({
      ...this.state,
      currentFilterIndex: this.state.currentFilterIndex - 1
    });
  }
  removeTag = (tag) => {
    let currentFilter = _.cloneDeep(this.state.currentFilter);
    _.map(Object.keys(this.state.currentFilter), key => {
      const arr = this.state.currentFilter[key];
      const removed = _.remove(arr, (name) => {
        return tag !== name;
      });
      currentFilter[key] = removed;
    });
    console.log('test:', currentFilter);
    const path = queryString.stringify(currentFilter);
    console.log('removeTag:', tag, this.state, currentFilter);
    this.setState({
      ...this.state,
      currentFilter,
      filterPath: path
    });
    this.props.history.push(`/?${path}`);

  }
  onFilterUpdate = (filter, path) => {
    console.log('onFilterUpdate: filter', filter, path);
    this.setState({
      ...this.state,
      currentFilter: filter,
      filterPath: path
    });
    this.props.history.push(`/?${path}`);
  }
  handleDistanceSliderChange = (val) => {
    this.setState({ radius: val });
  }
  updateStoreRadius = (val) => {
    let parsed = queryString.parse(this.props.location.search);
    parsed.radius = val;
    this.props.history.push(
        `${this.props.location.pathname}?${queryString.stringify(parsed)}`
      );
    console.log('updateStoreRadius:', this.props);
    this.props.onChangeRadius(val);
  }
  handleCostSliderChange(val) {
    this.setState({ max_cost: val });
  }
  updateStoreCost(val) {
      let parsed = queryString.parse(this.props.location.search);
      parsed.max_cost = val;
      this.props.history.push(
          `${this.props.location.pathname}?${queryString.stringify(parsed)}`
        );
      console.log('updateStoreCost:', this.props);
      this.props.onChangeMaxCost(val);
  }

  gotoResults = () => {
    const { filterPath, radius, max_cost } = this.state;
    console.log('gotoResults: ', filterPath);
    cookie.setCookie('showedModal', 1, 365);
    this.props.history.push(`/search?${filterPath}&radius=${radius}&max_cost=${max_cost}`);
  }

  render() {
    const { locationType = "geo", clubs = [], myLocation, location } = this.props;
    const { modalIsOpen, currentFilter, currentFilterIndex, radius, max_cost } = this.state;
    const filters = currentFilter || filterConfig.defaultFilters;
    const parsed = QueryString.parse(location.search);
    
    const config = filterConfig.fetchFilters(this.props.clubs);

    //Radius filter
    let filteredClubs = myLocation && myLocation.lat ? filterClubsByRadius(myLocation, clubs, radius) : clubs;
    console.log("Setup::", this.props, this.state);
    console.log("Filter by Radius::", filteredClubs, radius, myLocation);

    //Cost filter
    const min_cost = filteredClubs.length ? parseInt(filteredClubs.sort((a, b) => parseFloat(a.new_greenfees) - parseFloat(b.new_greenfees))[0].new_greenfees) : 25;
    if (max_cost < 200) {
      filteredClubs = filteredClubs.filter(club => club.new_greenfees <= max_cost);
    }
    const cost = max_cost >= 200 ? 'ANY' : '$' + max_cost;

    const showList = [
      { name: 'skillLevel', type: 'checkbox', key: 'skill_level' }, 
      { name: 'plays', type: 'checkbox', key: 'play_days' }, 
      { name: 'members', type: 'checkbox', key: 'welcome_genders' },
      { name: 'averageAge', type: 'checkbox', key: 'average_age' },
      { name: 'gameStyle', type: 'checkbox', key: 'game_styles' },
      { name: 'languages', type: 'checkbox', key: 'languages' },
      { name: 'radius', type: 'slider', key: 'radius' },
      { name: 'cost', type: 'slider', key: 'max_cost' },
    ];
    //Other filters
    filteredClubs = filterConfig.filterClubs(filteredClubs, filters) || [];

    const tags2 = _.map(showList, ({ name, type, key}) => {
      if(parsed[key]) {
        if( key == 'radius' ) {
          return `${parsed[key]} miles`;
        } else if( key == 'max_cost' ) {
          return `$${parsed[key]}`;
        } else {
          return parsed[key];
        }
      }

    });
    const tags = _.map(Object.keys(parsed), (key) => {
      if(key=='radius') {
        return `${parsed[key]} miles`;
      } else if(key=='max_cost') {
        return `$${parsed[key]}`;
      } else {
        return parsed[key];
      }
      
    });

    console.log('App::render: (filters)', filters, config, filteredClubs.length);
    console.log('Test::render tags', parsed, _.flatten(tags), tags2);
    
    // const showList = ['skillLevel', 'plays', 'members', 'averageAge', 'gameStyle', 'radius', 'cost', 'languages'];
    // const showList = ['skillLevel', 'plays', 'members', 'averageAge', 'gameStyle', 'languages'];

    
    return (
      <div className="App">
        <div className="modal-filter">
          <img src="/images/matchme.png" onClick={this.openModal} style={{ zIndex: '5', height: '42px', cursor: 'pointer' }} />
        </div>
        <div className="sub-icon">
          <div className="finder-icon" style={{alignItems: 'center'}}>
            <img src="/images/scga-logo-simple.png" style={{height: '26px', marginRight: '3px'}} />
            <span style={{color: 'white', fontSize: '32px'}}><b>GROUP FINDER</b></span>
          </div>
        </div>
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          className={'modal'}
          overlayClassName={'modal-overlay'}
          contentLabel="Example Modal"
        >
          <button className={'modal-close'} style={{ border: 'none', fontWeight: 'bold', fontSize: '1.6em', position: 'absolute' }} onClick={this.closeModal}>
            <i className="far fa-times" />
          </button>
          <div className={'modal-container'}>
            <div className={'modal-details'}>
              <div style={{ height: '12%', backgroundColor: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <div className={'modal-header'}>
                  Let us find your golf group by answering the folowing questions:
                </div>
                {/* <ul className="stepper">
                  <li>1</li>
                  <li>2</li>
                  <li className="active">3</li>
                  <li>4</li>
                  <li>5</li>
                </ul> */}
              </div>
              <div style={{ height: '73%', minHeight: '35vh', borderTop: '1px #eee solid'}}>
                <div style={{padding: '3% 5%', fontSize: '1.3em', color: '#0281be', fontWeight: 'bold'}}>
                  {/* What type of golfer are you? */}
                  <div style={{ minHeight: '32px' }}>
                    {_.map(_.compact(_.flatten(tags2)), (tag) => {
                      return (
                        <span style={{margin: '0px 1px'}} className="badge badge-primary">{tag}<span onClick={() => this.removeTag(tag)} className="modal-tag-close">x</span></span>
                      )
                    })}
                  </div>
                  {config[showList[currentFilterIndex].name].question}
                </div>
                {_.map(showList, ({name, type}, index) => {
                  const { currentFilterIndex } = this.state;
                  let filter = null
                  if (currentFilterIndex == index) {
                    if (type === 'checkbox') {
                      filter = (
                        <CheckboxFilters 
                          config={config[name]}
                          hideTitle
                          updateParam={false}
                          name={name}
                          clubs={this.props.clubs}
                          invertColor
                          filtersSet={filters}
                          onFilterUpdate={this.onFilterUpdate}
                          />
                      );
                    } else if (name == 'radius') {
                      filter = (
                        <div style={{padding: '20px'}} className={this.props.radius !== 20 ? "card max-distance slider checked" : "card max-distance slider"}>
                          <div className="card-block">
                              <h5 className="card-title">DISTANCE <span className="max-distance float-right">{radius} miles</span> </h5>
                              <div id="distance-slider" className="slider">
                                  <Slider
                                      min={5}
                                      max={200}
                                      step={5}
                                      // defaultValue={20}
                                      value={this.state.radius}
                                      onChange={this.handleDistanceSliderChange.bind(this)}
                                      onAfterChange={this.updateStoreRadius.bind(this)}
                                  />
                              </div>
                              <input className="form-check-input" type="hidden" id="max-distance" name="max_distance" value={this.props.radius} />
                          </div>
                        </div>
                      );
                    } else if (name == 'cost') {
                      filter = (
                        <div style={{padding: '20px'}} className={this.state.max_cost < 200 ? "card max-cost slider checked" : "card max-cost slider"}>
                          <div className="card-block">
                              <h5 className="card-title">MAX PRICE <span className="max-cost float-right">{cost}</span> </h5>
                              <div id="cost-slider" className="slider">
                                  <Slider
                                      min={min_cost <= 50 ? 50 : min_cost}
                                      max={200}
                                      step={1}
                                      defaultValue={200}
                                      onChange={this.handleCostSliderChange.bind(this)}
                                      onAfterChange={this.updateStoreCost.bind(this)}
                                  />
                              </div>
                              <input className="form-check-input" type="hidden" id="max-cost" name="max_cost" value="200" />
                          </div>
                        </div>
                      );
                    }
                  }
                  return filter;
                })}
              </div>

              <div style={{ height: '15%', padding: '2% 5%', borderTop: '1px #eee solid'}}>
              {/* <button class={'modal-next-mobile'} onClick={this.gotoResults}>Show Results</button> */}
                {
                  (currentFilterIndex == showList.length -1) ? 
                    null : 
                    (<button style={{float: 'right', padding: '4px 10px', background: '#0281be', color: 'white'}} onClick={this.nextFilter}>Next Question</button>)
                }
                
                {
                  (currentFilterIndex == 0) ? 
                    null : 
                    (<button style={{float: 'right', marginRight: '5px', padding: '4px 10px', background: '#666666', color: 'white'}} onClick={this.previousFilter}>Previous Question</button>)
                }  
              </div>
            </div>
            <div className={'modal-graphic'}>
              <div style={{ fontSize: '36px', color: 'white', width: '80%' }}>Matching Golf Groups</div>
              <div className={'modal-count'}>{filteredClubs.length}</div>
              <button class={'modal-next'} onClick={this.gotoResults}>Show Results</button>
            </div>
          </div>
          
        </Modal>
        <Layout myLocation={myLocation} searchType={this.props.searchType}>
          <Content />
        </Layout>
      </div>
    );
    // return (
    //   <BrowserRouter>
    //     <div className="App">
    //       <Layout myLocation={myLocation} searchType={this.props.searchType}>
    //         <Content />
    //       </Layout>
    //     </div>
    //   </BrowserRouter>
    // );
  }
}

const mapStateToProps = state => {
  return {
    clubs: state.clubs,
    geoLocation: state.geoLocation,
    searchString: state.searchString,
    myLocation: state.myLocation,
    query_string: state.query_string,
    searchType: state.searchType,
    featuredClub: state.featuredClub
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onSelectClub: club => dispatch({ type: "SET_SELECTED_CLUB", value: club }),
    onChangeRadius: (val) => dispatch({ type: 'CHANGE_RADIUS', value: val }),
    onChangeMaxCost: (val) => dispatch({ type: 'CHANGE_MAXCOST', value: val }),
    onFilterChange: filtersSet =>
      dispatch({ type: "CHANGE_FILTER", value: filtersSet }),
    onFacilitySelect: filtersSet =>
      dispatch({ type: "CLEAR_SELECTED_CLUB", value: filtersSet }),
    onSetFeaturedClub: featured =>
      dispatch({ type: "SET_FEATURED_CLUB", value: featured }),
    onSetClubs: clubs => dispatch({ type: "SET_CLUBS", value: clubs })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
