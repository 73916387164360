import React from "react";
import { get } from "lodash";
import Results from "../Results/Results";
import Events from "../Events/ClubEvents";
import Featured from "../Featured/FeaturedClub";
import YouTube from "react-youtube";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from 'react-router-dom';
import axios from "axios";
import Modal from 'react-modal';
import featuredClubs from '../config/featuredClubs';
import _ from 'lodash';
const api = "https://clubs.scga.org/api/";


const video_opts = {
  width: "100%",
  height: "500",
  playerVars: {
    autoplay: 0
  }
};
const customStyles = {
  content : {
    top: '50%',
    left: '50%',
    width: '70%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};
class Content extends React.Component {
  constructor(props) {
    super(props);
    console.log("Content constructor: ", this, props);
  }
  state = { showPlayer: [], openModal: false, currentClub: {} };

  componentDidMount() {
    console.log("Content props: ", this.props);
    // get Featured club from api
    if (!this.props.featuredClub) {
      this.getFeaturedClub();
    }
    // get next 10 events, plus thismonthcount and total events count from api
    if (this.props.events.length === 0) {
      this.getNextEvents(0);
    }
    this.props.unSetLocation();
  }
  onReady = (e) => {
    // when player is ready, assign it to state so we can access it
    this.setState({ player: e.target });
  }
  onShowPlayer = (i) => {
    const club = this.props.featuredClub[i];
    let sp = this.state.showPlayer;
    sp.map(i => {
      return false;
    });
    sp[i] = true;
    this.setState({ showPlayer: sp, openModal: true, currentClub: club });
  }
  onClosePlayer = () => {
    this.setState({ ...this.state, openModal: false });
  }

  getFeaturedClub() {
    axios
      .get(api + "featured_clubs")
      .then(response => response.data)
      .then(data => {
        this.props.onSetFeaturedClub(data);
      })
      .catch(error => console.error(error));
  }
  /**
   * get next 10 upcoming events, this month count, and total future count
   * @param offset
   */
  getNextEvents(offset) {
    axios
      .get(api + "events/" + offset)
      .then(response => response.data)
      .then(data => {
        this.props.onSetEvents(
          data.events,
          data.futureCount,
          data.thisMonthCount
        );
      })
      .catch(error => console.error(error));
  }

  render() {
    const { location } = this.props.history;
    const showResults = !!location.search;
    console.log("Content2 render: ", this.props, showResults);
    let content = null;
    if (this.props.locationSet) {
      content = showResults && <Results />;
    } else {
      if (showResults) {
        console.log("Whoops A");
      } else {
        content = (
          <div>
            {this.props.featuredClub ? (
              <Featured featured={this.props.featuredClub} />
            ) : (
              <div>Loading Featured Club....</div>
            )}
            {!!this.props.events.length ? (
              <Events />
            ) : (
              <div>Loading Upcoming Events....</div>
            )}
          </div>
        );
      }
    }
    return (
      <div className="container-fluid content" style={{ background: '#f1f1f1', padding: '0px' }}>
        
        <div clasName="row col-md-12" style={{background: '#f1f1f1', paddingBottom: '25px', boxShadow: '3px 3px 3px #a1a1a1' }}>
          <div className="preview-list-title">Find a New Group of Golf Friends</div>
          <div style={{display: 'flex', justifyContent: 'center'}}>

            <div className="preview-list-container">
              
                <div className="preview-container">
                  <Link to={{ pathname: "/popular", hash: '#' }}>
                  <img className="preview-image" src="/images/popular_clubs.png" alt="popular" />
                  </Link>
                  <div className="preview-title">Popular Clubs</div>
                </div>
              
              
              <div className="preview-container">
                <Link to={{ pathname: "/travel", hash: '#' }}>
                  {/* <div className="preview-image"></div> */}
                  <img className="preview-image" src="/images/travel_clubs.png" alt="travel" />
                </Link>
                <div className="preview-title">Travel Clubs</div>
              </div>
              <div className="preview-container">
                <Link to={{ pathname: "/womens", hash: '#' }}>
                <img className="preview-image" src="/images/women_only.png" alt="women" />
                </Link>
                <div className="preview-title">Women Only</div>
              </div>
              <div className="preview-container">
                <Link to={{ pathname: "/social", hash: '#' }}>
                <img className="preview-image" src="/images/under40.png" alt="under40" />
                </Link>
                <div className="preview-title">Social Clubs</div>
              </div>
              <div className="preview-container">
                <Link to={{ pathname: "/senior", hash: '#' }}>
                <img className="preview-image" src="/images/senior.png" alt="senior" />
                </Link>
                <div className="preview-title">Senior Clubs</div>
              </div>
              <div className="preview-container">
                <a href="/byLocation?skill_level=high-handicaps">
                <img className="preview-image" src="/images/high_handicap.png" alt="high_handicap" />
                </a>
                <div className="preview-title">High Handicap</div>
              </div>
              <div className="preview-container">
              <a href="/byLocation?languages=korean">
              <img className="preview-image" src="/images/international.png" alt="international" />
              </a>
                <div className="preview-title">International Clubs</div>

              </div>
              <div className="preview-container">
              <a href="/byLocation?play_days=saturday&play_days=sunday">
              <img className="preview-image" src="/images/weekend_play.png" alt="weekend" />
              </a>
                <div className="preview-title">Weekend Events</div>
              </div>
            </div>
          </div>
        </div>

        <div clasName="row col-md-12" style={{background: '#f1f1f1', borderTop: '2px solid #fff', boxShadow: '3px 3px 3px #a1a1a1', padding: '0px 0px 30px 0px' }}>
          <div className="preview-list-title">Featured Groups</div>
          

          <div style={{display: 'flex', justifyContent: 'center', flexWrap: 'wrap'}}>
          <div className="preview-list-container">
          {_.map(this.props.featuredClub, (club, i) => {
            console.log('Content::loop...', club);
            return (
              
                <div className="preview-container" onClick={() => { this.onShowPlayer(i) }}>
                  <div className="preview-image">
                    <img src={get(club, 'featured_image_url')} style={{ width: '225px', maxWidth: '100%' }} />
                  </div>
                  <div className="preview-title">
                    <div>{get(club, 'title')}</div>
                  </div>
                </div>
              
            )
          })}
          </div>
          <Modal className="modal-video" isOpen={this.state.openModal} onRequestClose={this.onClosePlayer} center>
                  <YouTube
                        videoId={get(this.state.currentClub, 'video_id')}
                        opts={video_opts}
                        onReady={this.onReady}
                      />
                </Modal>

            
          </div>
        </div>
        {/*only show Results if location set*/}
        {/* {!!this.props.locationSet && showResults && <Results />} */}
        {/*only show Featured and Events if location NOT set (Results component renders these internally) */}
        {/* {!!!this.props.locationSet && !showResults && ( */}
        <div style={{background: 'rgb(241, 241, 241)',display: 'flex', justifyContent: 'center', borderTop: '2px solid #fff'}}>
          {/* {this.props.featuredClub ? (
            <Featured featured={this.props.featuredClub} />
          ) : (
            <div>Loading Featured Club....</div>
          )} */}
          {!!this.props.events.length ? (
            <Events />
          ) : (
            <div>Loading Upcoming Events....</div>
          )}
        </div>
        {/* )} */}
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    clubs: state.clubs,
    events: state.events,
    featuredClub: state.featuredClub,
    filtersSet: state.filtersSet,
    locationSet: state.locationSet,
    selectedClub: state.selectedClub,
    selected: state.selected
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onSetFeaturedClub: featured =>
      // dispatch({ type: "SET_FEATURED_CLUB", value: featured }),
      dispatch({ type: "SET_FEATURED_CLUB", value: featuredClubs.featuredClubs }),
    unSetLocation: () => dispatch({ type: "UNSET_LOCATION", value: "" }),
    onSetEvents: (events, eventsFutureCount, eventsThisMonthCount) =>
      dispatch({
        type: "SET_EVENTS",
        payload: {
          events: events,
          eventsFutureCount: eventsFutureCount,
          eventsThisMonthCount: eventsThisMonthCount
        }
      })
  };
};
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Content)
);

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(Content);
