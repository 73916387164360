import React from "react";
import { connect } from "react-redux";
import YouTube from "react-youtube";
import queryString from "query-string";
import { withRouter } from "react-router-dom";

class FeaturedClub extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
      featuredClub: null,
      locationSet: this.props.locationSet,
      showPlayer: false
    };
    this.handleHover = this.handleHover.bind(this);
    this.onPlayVideo = this.onPlayVideo.bind(this);
    this.onReady = this.onReady.bind(this);
    this.onStopVideo = this.onStopVideo.bind(this);
    this.handleClickSimilar = this.handleClickSimilar.bind(this);
  }

  // toggle hover state on hover
  handleHover() {
    this.setState({
      hover: !this.state.hover
    });
  }
  onReady(e) {
    // when player is ready, assign it to state so we can access it
    this.setState({ player: e.target });
  }
  onPlayVideo() {
    this.setState({ showPlayer: true });
    this.state.player.playVideo();
  }
  onStopVideo() {
    this.setState({ showPlayer: false });
    this.state.player.stopVideo();
  }
  componentDidMount() {
    // const featuredClub = this.props.clubs.find(club => club.featured === true)
    const featuredClub = this.props.featuredClub;
    this.setState({ featuredClub });
  }
  /**
   * When user clicks 'similar' link on featured, set filtersSet to all the values in featured club
   */
  handleClickSimilar(e) {
    e.preventDefault();
    const filtersSet = { ...this.props.filtersSet };
    // console.log("FeaturedClub::handleClickSimilar: filtersSet: ",filtersSet)
    const featured = this.props.featuredClub;
    featured.whys.map(val => filtersSet.whys.push(val));
    featured.play_days.map(val => filtersSet.play_days.push(val));
    featured.club_types.map(val => filtersSet.club_types.push(val));
    featured.game_styles.map(val => filtersSet.game_styles.push(val));
    featured.levels.map(val => filtersSet.skill_level.push(val));
    featured.welcome_genders.map(val => filtersSet.welcome_genders.push(val));
    // featured.langs.map(val => filtersSet.languages.push(val))
    filtersSet.average_age = [featured.age];
    console.log(
      "FeaturedClub::handleClickSimilar: filtersSet: ",
      this.props,
      filtersSet,
      queryString.stringify(filtersSet)
    );
    this.props.onSetSimilar(filtersSet, this.props.myLocation, featured.id);
    this.props.history.push(
      `/bylocation?lat=${this.props.myLocation.lat}&lng=${
        this.props.myLocation.lng
      }&${queryString.stringify(filtersSet)}&radius=100`
    );
  }

  render() {
    const video_opts = {
      width: "60%",
      height: "100%",
      playerVars: {
        autoplay: 1
      }
    };
    const featured = this.props.featured || {}; // passed in props from Content.js
    // const featured = this.state.featuredClub;

    // console.log("featured wePlay: ",featured.wePlay);
    // console.log("featured video_id: ",featured.video_id);
    // console.log("featured image: ",featured.featured_image_url);
    const video_id = featured.video_id + "?rel=0";

    const days = Object.values(featured.wePlay);
    const wePlay = days.map(when => (
      <div key={when} className="badge badge-primary flat when">
        {when}
      </div>
    ));
    return (
      <div>
        <div
          className={
            this.props.locationSet ? "featured-club results" : "featured-club"
          }
          onMouseEnter={this.handleHover}
          onMouseLeave={this.handleHover}
        >
          <div className="row heading-row">
            <div className="col col-sm-12">
              <p className="headings">FEATURED CLUB</p>
              <span>{featured.title}</span>
            </div>
          </div>
          <div className="featured-card">
            <div
              className="youtube embed-responsive embed-responsive-16by9"
              style={
                this.state.showPlayer
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              <YouTube
                videoId={video_id}
                opts={video_opts}
                onReady={this.onReady}
              />
              <div
                id="close"
                className="close-video"
                style={
                  this.state.showPlayer
                    ? { display: "block" }
                    : { display: "none" }
                }
                onClick={this.onStopVideo}
                title="Close Video"
              >
                <i className="far fa-times" />
              </div>
            </div>
            <img
              src={featured.featured_image_url}
              alt=""
              className="img-fluid"
            />
            <div
              className={
                this.state.hover ? "over-visible" : "over-visible invisible"
              }
            >
              <div className="featured-card__image">
                <div
                  className="logo"
                  style={
                    featured.club_site_logo
                      ? { visibility: "visible" }
                      : { visibility: "hidden" }
                  }
                >
                  <div className="logo-border">
                    <div className="logo-img">
                      <img src={featured.club_site_logo} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="play"
                className="featured-card__video"
                onClick={this.onPlayVideo}
              >
                <i className="fab fa-youtube fa-4x" />
              </div>
              <div className="featured-card__learn-more">
                <div className="link">
                  <a href="#" onClick={e => this.handleClickSimilar(e)}>
                    <i className="far fa-search" /> similar clubs
                  </a>
                </div>
              </div>

              <div className="featured-card__info">
                <div className="col-club-info">
                  <div className="area">{featured.address2_city} Area</div>
                  <div className="when-row">{wePlay}</div>
                  <div className="next-event">
                    <div className="label">next event</div>
                    <div className="title">
                      {featured.nextEvent ? featured.nextEvent.name : "None"}
                    </div>
                    <div className="date-row">
                      <div className="date">
                        {featured.nextEvent ? featured.nextEvent.date : "None"}
                      </div>
                      <div className="facility">
                        {featured.nextEvent
                          ? featured.nextEvent.facility.title
                          : "None"}
                      </div>
                    </div>
                  </div>
                  <div className="call-to-action featured">
                    <a
                      href={featured.new_scga_joinnowurl}
                      className="join-now-url"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="join-now">
                        <div className="heading">join now</div>
                        <div className="price">
                          ${parseInt(featured.new_greenfees)}
                        </div>
                        <div className="per-year">per year</div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    filtersSet: state.filtersSet,
    locationSet: state.locationSet,
    selectedClub: state.selectedClub,
    selected: state.selected,
    myLocation: state.myLocation,
    clubs: state.clubs,
    featuredClub: state.featuredClub
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onSetSimilar: (filtersSet, location, club_id) =>
      dispatch({
        type: "SET_FEATURED_SIMILAR",
        payload: {
          filtersSet: filtersSet,
          geoLocation: location,
          club_id: club_id
        }
      })
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FeaturedClub));
