import React from "react";
import moment from "moment";
import _ from "lodash";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import axios from "axios";
const api = "https://clubs.scga.org/api/";


class ResultCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      club: props.club,
      showChat: false,
      selected: false,
      expanded: this.props.expanded,
      reviews: {}
    };

    this.handleTileClick = this.handleTileClick.bind(this);
    this.setFacilityFilter = this.setFacilityFilter.bind(this);
    this.handleUnExpandClick = this.handleUnExpandClick.bind(this);
    this.handleSelectClub = this.handleSelectClub.bind(this);
    this.handleMilesClick = this.handleMilesClick.bind(this);
    this.getClubReview = this.getClubReview.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    console.log('ResulCard::componentWillReceiveProps:1',nextProps.expanded, this.state, nextProps);
    let expanded = false;
    if(nextProps.expanded) {
      console.log('ResulCard::componentWillReceiveProps:2');
      expanded = true;
      this.getClubReview(this.state.club.id);
    }
    this.setState({
      club: nextProps.club,
      selected: _.get(nextProps, 'selectedClub.id') === _.get(nextProps, 'club.id'),
      selectedClub: nextProps.selectedClub,
      expanded,
      ...this.state.reviews
    });
  }
  componentWillMount() {
    console.log('componentWillMount', this.props);
    if(_.get(this.props, 'expanded')) {
      console.log('ResulCard::componentWillMount:1', this.props);
      this.getClubReview(this.props.club.id);
    }
  }
  componentWillUnmount() {
    
  }


  getClubReview(id) {
    axios
      .get(api + "club_reviews?club_id=" + id)
      .then(response => response.data)
      .then(data => {
        // data = [
        //   {
        //     review: "This is a great club!",
        //     title: "",
        //     fullname: "John Smith",
        //     stars: 5,
        //     created_at: "",
        //     updated_at: "",
        //     approved_at: "",
        //     deleted_at: "",
        //     join_date: "Febuary 2020",
        //     created_by: "Test",
        //     club_id: "",
        //     member_id: 123,
        //   },
        //   {
        //     review: "This is a great club!",
        //     title: "",
        //     fullname: "John Smith2",
        //     stars: 5,
        //     created_at: "",
        //     updated_at: "",
        //     approved_at: "",
        //     deleted_at: "",
        //     join_date: "Febuary 2020",
        //     created_by: "Test",
        //     club_id: "",
        //     member_id: 123,
        //   }
        // ];
        this.setState({
          ...this.state,
          reviews: {
            ...this.state.reviews,
            [id]: data
          }
        });
      })
      .catch(error => console.error(error));
  }

  handleTileClick() {
    // const {club} = this.state;
    // this.props.onSelectClub(club);  // save it to store
    console.log("ResultCard: handleTileClick: setting expanded to true");
    this.getClubReview(this.props.club.id);
    this.setState({ expanded: true });
  }
  setFacilityFilter(facility) {
    let filtersSet = { ...this.props.filtersSet };
    filtersSet.clubs_that_play_course = [facility.id];
    const location = {
      lat: parseFloat(facility.lat),
      lng: parseFloat(facility.lng)
    };
    console.log(
      "ResultCard::setFacilityFilter: facility: ",
      this.props,
      facility,
      location
    );
    this.props.onFacilitySelect(filtersSet, location);
    // FIND-219
    if (facility && facility.title)
      this.props.history.push(
        `/byClub?lat=${facility.lat}&lng=${facility.lng}&clubs_that_play_course=${facility.id}`
      );
    window.scrollTo(0, 150);
  }
  handleUnExpandClick(e) {
    console.log("ResultCard: unexpandedClick: resetting expanded");
    this.setState({ expanded: false });
    e.stopPropagation();
  }

  handleSelectClub(e) {
    const { club } = this.state;
    console.log('handleSelectClub');
    this.props.history.push(
      `/byClub?club=${club.url_title}&lng=${club.longitude}&lat=${club.latitude}`
    );
    this.props.onSelectClub(this.state.club);
    e.stopPropagation();
    window.scrollTo(0, 150);
  }
  handleMilesClick(e) {
    e.preventDefault();
    this.props.onSetMapCenter(
      {
        lat: parseFloat(this.state.club.latitude),
        lng: parseFloat(this.state.club.longitude)
      },
      14
    );
    e.stopPropagation();
  }
  openLiveChat = (e) => {
    e.preventDefault();
    console.log('openLiveChat: ', this.props);
    this.props.onShowChat();
    this.setState({
      showChat: true
    });
  }
  getOptimizedImage(imageUrl, size) {
    let all = imageUrl.split(".");
    const ext = all[all.length - 1];
    all.pop();

    var ratio = window.devicePixelRatio || 1;
    var w = window.screen.width * 1;
    var h = window.screen.height * 1;
    let optimizedUrl = "";
    if (w > 768 && size != "small") {
      optimizedUrl = all.join(".") + "__1300" + "." + ext;
    } else {
      optimizedUrl = all.join(".") + "__400" + "." + ext;
    }

    // console.log("RESULT CARD WIDTH: ", w);
    return optimizedUrl;
  }
  render() {
    // console.log('ResultCard render: ', this.props, this.state);

    const expanded = this.state.expanded;
    const clickDiv = expanded ? (
      <div
        className="expandomatic"
        onClick={this.handleUnExpandClick.bind(this)}
      >
        show less
      </div>
    ) : (
      <div className="expandomatic" onClick={() => this.handleTileClick(club)}>
        show more
      </div>
    );

    const titleDiv = () => (
      <div className="club-name">
        <div
          className="title"
          onClick={this.handleSelectClub.bind(this)}
          title={"Select this Club"}
        >
          {club.title}
        </div>
        {clickDiv}
      </div>
    );

    const club = this.state.club;

    const reviews = this.state.reviews[club.id] || [];

    // const reviews = [
    //   {
    //     review: "This is a great club!",
    //     title: "",
    //     fullname: "John Smith",
    //     stars: 5,
    //     created_at: "",
    //     updated_at: "",
    //     approved_at: "",
    //     deleted_at: "",
    //     join_date: "Febuary 2020",
    //     created_by: "Test",
    //     club_id: "",
    //     member_id: 123,
    //   },
    // ];

    const no_star = (
      <img
      src="/images/no_star_rating.png"
      height="25"
      width="25"
      alt="star"
      style={{ background: '#fff', margin: '0px 7px 0px 0px' }}
    />
    );

    const star = (
      <img
      src="/images/star_rating.png"
      height="25"
      width="25"
      alt="star"
      style={{ background: '#fff', margin: '0px 7px 0px 0px' }}
    />
    );

    const milesAway = (Math.round(( typeof club.miles == 'number' ? club.miles : club.myMiles) * 10) / 10).toFixed(1);
    console.log('==current club: ', this.state, club.miles,  milesAway);
    // const expanded = (this.props.selected && this.props.selectedClub && this.props.selectedClub.id === club.id);
    const days = Object.values(club.wePlay);

    const description = club.description.length ? (
      <div className={expanded ? "description expanded" : "description"}>
        <div className="heading list">
          About Us
          {/* About Us | 
          <span onClick={this.openLiveChat} style={{cursor: 'pointer', color: '#0281be'}}> Ask This Club a Question</span> */}
          </div>
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: club.description }}
        />
      </div>
    ) : (
      ""
    );

    const whens = days.map(when => (
      <div className="badge badge-primary flat when" key={when}>
        {when}
      </div>
    ));
    const tags = club.tags.map(tag => (
      <div className="search-tag" title={tag.description} key={tag.name}>
        {tag.name}
      </div>
    ));
    const selectedStuff = (
      <div
        className={
          this.state.expanded ? "selected-stuff expanded" : "selected-stuff"
        }
      >
        <div className="big-tags">
          <div className="count">
            <div className="members-count">
              {club.member_count}
              <br />
              <span className="members">members</span>
            </div>
          </div>
          <div className="count">
            <div className="members-count">
              {club.age}
              <br />
              <span className="members">average age</span>
            </div>
          </div>
        </div>
        <div className="selected-info">
          <div className="search-tags">{tags}</div>
        </div>
      </div>
    );
    let eventsDiv = "";
    if (club.upcoming.length) {
      if (expanded) {
        const upcoming = club.upcoming.map(event => (
          <div className="next-event list" key={event.id}>
            <div className="next-event-title">{event.name}</div>
            <div className="event-location-row">
              <div className="event-facility">
                <div className="facility">{event.facility.title}</div>
              </div>
              <div className="event-date">
                <div className="date">
                  {moment(event.event_date).format("MMMM Do, h:mm A")}
                </div>
              </div>
            </div>
          </div>
        ));
        eventsDiv = (
          <div className="event-info upcoming">
            <div className="heading list">Upcoming Events</div>
            {upcoming}
            <div style={{ margin: '10px 0px', lineHeight: '1.1em', fontSize: '.9em' }}>
            *Due to the COVID-19 pandemic and policies on tournament play, all upcoming events listed are subject to change or cancellation. <span onClick={this.openLiveChat} style={{cursor: 'pointer', color: '#0281be'}}>Please contact the club</span> directly for updates to their upcoming schedule.
            </div>
          </div>
        );
      } else {
        const event = club.upcoming[0];
        eventsDiv = (
          <div className="event-info next">
            <div className="next-event list">
              <div className="heading list">NEXT EVENT</div>
              <div className="next-event-title">{event.name}</div>
              <div className="event-location-row">
                <div className="event-facility">
                  <div className="facility">{event.facility.title}</div>
                </div>
                <div className="event-date">
                  <div className="date">
                    {moment(event.event_date).format("MMMM Do, h:mm A")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
    }

    const memberBenefits = club.club_benefits.length
      ? club.club_benefits.map(benefit => (
          <div className="benefit-item" key={benefit.id}>
            {benefit.name}
          </div>
        ))
      : "";
    const clubBenefitsDiv = club.club_benefits.length ? (
      <div className="benefit-list">{memberBenefits}</div>
    ) : (
      ""
    );
    const benefitsDiv = (
      <div className={expanded ? "benefits expanded" : "benefits"}>
        <div className="member-benefits">
          <div className="heading">Membership benefits</div>
          {clubBenefitsDiv}
        </div>
        <div className="includes-benefits">
          <div className="heading">includes</div>
          <div className="benefit-list">
            <div className="benefit-item">All SCGA Member Benefits</div>
            <div className="benefit-item">Handicap Index®</div>
          </div>
        </div>
      </div>
    );

    const facilities = club.ourFacilities.map(course => (
      <div
        className="facility-item selected"
        key={course.id}
        onClick={() => this.setFacilityFilter(course)}
      >
        <img
          src={this.getOptimizedImage(course.image, "small")}
          className="img-fluid"
          alt={course.title}
        ></img>
        <div className="course-name" title={"Show Clubs who play here"}>
          {course.title}
        </div>
      </div>
    ));

    let facilities_class = expanded ? "facilities expanded" : "facilities";
    if (expanded && club.ourFacilities.length > 16)
      facilities_class = "facilities expanded facilities--18";

    const facilitiesDiv = (
      <div className={facilities_class}>
        <div className="heading list facilities" style={{ display: "block" }}>
          Courses We Play
        </div>
        {expanded ? (
          <div className="facilities-grid selected">{facilities}</div>
        ) : null}
      </div>
    );
    //
    // const styleStr = " -webkit-linear-gradient(to right, rgba(255, 255, 255, .95) 0%, rgba(255, 255, 255, 0.95) 40%, rgba(255,255,255,.5) 70%, transparent 80%), url("+club.facilityImage+")";background-image: linear-gradient(to right, rgba(255, 255, 255, .95) 0%, rgba(255, 255, 255, 0.95) 40%, rgba(255,255,255,.5) 70%, transparent 80%), url("+club.facilityImage+")";
    // const styleStr = "linear-gradient(to right, rgba(255, 255, 255, .95) 0%, rgba(255, 255, 255, 0.95) 40%, rgba(255,255,255,.5) 70%, transparent 80%), url("+club.facilityImage+")";
    var ratio = window.devicePixelRatio || 1;
    var w = window.screen.width * 1;
    var h = window.screen.height * 1;

    const optimizedImage = this.getOptimizedImage(club.facilityImage);
    const backGroundStyle = {
      // backgroundImage: "url(" + optimizedImage + ")",
      backgroundImage: `url(${optimizedImage})`,
      minHeight: '135px'
    };

    console.log(`${club.title} expandedd: `,expanded, this.state.selected, backGroundStyle);

    return (
      <div
        className={expanded ? "ttt card result-card selected" : "card result-card"}
        style={backGroundStyle}
      >
        <div className="full-card">
          <div className="results-card-row" style={{ flexDirection: "column" }}>
            <div
              className={`result-main ${expanded ? 'result-expanded' : ''}`}
              style={{ display: "flex", alignItems: "flex-start" }}
            >
              <div className="card__left-col">
                <div className="club-info">
                  {titleDiv()}
                  <div className="region">{club.address2_city} Area</div>
                  <div className="when-row">
                  <span style={{ display: 'inline-block', width: '40px' }} className="miles-away">we play</span>
                    {whens}
                  </div>
                  <div
                    className="distance flex"
                    onClick={this.handleMilesClick.bind(this)}
                    title={"Zoom to this Club"}
                  >
                    <div className="marker">
                      <img
                        src="/images/svg/LocationGenericB.svg"
                        alt="marker"
                      />
                    </div>
                    <div className="mileage">
                      {milesAway}
                    </div>
                    <div className="miles-away">miles away</div>
                    
                  </div>
                  <div style={{ display: `${_.get(club, 'review_count') || 0 > 0 ? 'flex' : 'none'}`, alignItems: 'center', marginTop: '15px', color: '#EA6800'}}>
                    {star} <span style={{ fontWeight: '600', fontSize: '18px', paddingTop: '2px'}}>{_.get(club, 'review_count', '')} reviews</span>
                    </div>
                </div>
                {description}
                {eventsDiv}

                <div
                  className="logo-wrap"
                  style={
                    club.club_site_logo
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <div
                    className="logo logo--show-for-mobile"
                    onClick={this.handleSelectClub.bind(this)}
                  >
                    <div className="logo-border">
                      <div className="logo-img">
                        <img src={club.club_site_logo} alt="Logo here" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="card__right-col"
                style={{ justifyContent: "space-between", marginTop: "0", marginLeft: '10px' }}
              >
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    paddingRight: "10px",
                    justifyContent: "space-between",
                    flexDirection: "row-reverse"
                  }}
                >
                  <div
                    className="logo"
                    style={{
                      display: `${club.club_site_logo ? "block" : "none"}`
                    }}
                    onClick={this.handleSelectClub.bind(this)}
                    title={"Select this Club"}
                  >
                    <div className="logo-border">
                      <div className="logo-img">
                        <img src={club.club_site_logo} alt="Club logo" />
                      </div>
                    </div>
                    
                  </div>
                  
                  {/* <div className="logo-help2" onClick={this.openLiveChat} style={{ position: 'absolute', margin: '50px 0px 0px -60px'}}>
                    <img
                      src="/images/svg/logo-help.svg"
                      alt="star"
                      onClick={this.openLiveChat}
                      style={{ width: "30px", height: '30px' }}
                    />
                    <div style={{fontSize: '26px', color: 'white', fontWeight: 'bold', margin: '-22px 8px'}}>?</div>
                  </div> */}
              
                  <div
                    className="free-trial"
                    style={{
                      display: `${club.offers_free_trial ? "flex" : "none"}`,
                      marginTop: "-12px"
                    }}
                  >
                    <div
                      style={{
                        color: "white",
                        fontSize: "16px",
                        fontWeight: "bold",
                        textAlign: "center",
                        lineHeight: "18px",
                        marginTop: "12px"
                      }}
                    >
                      FREE TRIAL
                    </div>
                    <div
                      style={{
                        background: "url('/images/svg/Star.svg'",
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        marginTop: "7px",
                        width: "20px",
                        height: "20px"
                      }}
                    ></div>
                  </div>
                </div>

                {selectedStuff}
                {benefitsDiv}

                <div className="call-to-action">
                  <a
                    href={club.new_scga_joinnowurl}
                    className="join-now-url"
                    target="_blank"
                    rel="noopener noreferrer"
                    title={"Join this Club!"}
                  >
                    <div
                      className="join-now"
                      style={{
                        alignItems: "flex-end",
                        display: "flex",
                        flexDirection: "column"
                      }}
                    >
                      <div className="heading">join now</div>
                      {!!club.offers_free_trial ? (
                        <div
                          className="price price-mobile"
                          style={{ fontSize: "21px", fontWeight: "600" }}
                        >
                          Activate Free Trial
                        </div>
                      ) : (
                        <div className="price">
                          ${Math.round(club.new_greenfees)}
                        </div>
                      )}

                      <div className="per-year">per year</div>
                      
                      <div className="logo-help2" style={{ minHeight: '32px', display: `${ expanded ? 'block': 'none'}` }} onClick={this.openLiveChat}>
                        <img
                          src="/images/svg/logo-help.svg"
                          alt="star"
                          onClick={this.openLiveChat}
                          style={{ width: "30px", height: '30px' }}
                        />
                        <div style={{fontSize: '26px', color: 'white', fontWeight: 'bold', margin: '-22px 8px'}}>?</div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div
              style={{
                display: `${club.offers_free_trial ? "flex" : "none"}`,
                justifyContent: "flex-end"
              }}
            >
              <div className="free-trial-banner">
                <img
                  src="/images/svg/Star.svg"
                  alt="star"
                  style={{ padding: "0px 4px" }}
                />
                <div>Activate Free Trial</div>
              </div>
            </div>
          </div>
          {facilitiesDiv}
        </div>
        <div
          className="recommended"
          style={club.recommended ? { display: "block" } : { display: "none" }}
        >
          <p>Recommended</p>
          
        </div>
        
        {/* {(expanded || this.state.selected) && reviewsDiv} */}
        {(expanded || this.state.selected) && reviews.length && (
          <div style={{display: `${expanded ? 'block' : 'none'}`, width: '100%', minHeight: '200px', background: 'none', backgroundColor: 'white', zIndex: '2', padding: '0% 2%'}}>
          <br />
          <div style={{fontSize: '16px', marginBottom: '5px'}}>{_.toUpper(club.title)} REVIEWS</div>
          {reviews.map(({ review, title, full_name, join_date, stars }) => {
            return (
                <div style={{ marginTop: '15px' }}>
                  <div style={{ fontSize: '1.2em', color: '#444' }}><span style={{ fontWeight: 'bolder' }}>{full_name || 'Member'}</span> - Verified Club Member - Joined in {moment(join_date).year()}</div>
                  <div style={{color: '#888', paddingTop: '15px'}}>
                    <span style={{display: 'inline-block'}}></span>
                    {_.range(Math.round(stars)).map(s => star)}{_.range(Math.round(5 - (stars || 5))).map(s => no_star)} <br />

                  </div>
                  <span style={{color: '#888', lineHeight: '1.5em', display: 'inline-block', marginTop: '12px'}}>{review}</span>
                  <div style={{borderBottom: '1px #ddd solid', width: '100%', padding: '2% 5%'}}></div>
                </div>
              
            )
          })}
          <br />
          </div>
        )}
        
        <div className="result-card__gradient"></div>
      </div>
    );
  }
}
const mapStateToProps = (state, props) => {
  console.log('mapStateToProps:', props, state);
  return {
    filtersSet: state.filtersSet,
    geoLocation: state.geoLocation,
    selected: state.selected,
    selectedClub: state.selectedClub,
    expanded: props.expanded,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onSelectClub: club => dispatch({ type: "SET_SELECTED_CLUB", value: club }),
    onChangeSearchType: () => dispatch({ type: "CHANGE_SEARCHTYPE" }),
    onFilterChange: filtersSet =>
      dispatch({ type: "CHANGE_FILTER", value: filtersSet }),
    onFacilitySelect: (filtersSet, location) =>
      dispatch({
        type: "SET_CLUBS_THAT_PLAY",
        payload: { filtersSet: filtersSet, geoLocation: location }
      }),
    onSetMapCenter: (center, zoom) =>
      dispatch({
        type: "SET_MAP_CENTER_ZOOM",
        payload: { center: center, zoom: zoom }
      })
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ResultCard)
);
