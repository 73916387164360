import React from 'react';
import Slider from 'rc-slider';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import CheckboxFilters from "../Filters/CheckboxFilter";
import filterConfig from "../config/filterConfig";

class Filters extends React.Component {
    static defaultProps = {
        cost: 200,
    }
    constructor(props) {
        super(props);

        this.state = {
            radius: this.props.radius,
            max_cost: this.props.max_cost,
            filtersSet: this.props.filtersSet,
            clubs: this.props.clubs,
        };

        this.handleDistanceSliderChange = this.handleDistanceSliderChange.bind(this);
        this.handleCostSliderChange = this.handleCostSliderChange.bind(this);
        this.onFieldChange = this.onFieldChange.bind(this);
        this.updateStoreRadius = this.updateStoreRadius.bind(this);
        this.clearClubsThatPlay = this.clearClubsThatPlay.bind(this);
    }

    /**
     * Note there are a pair of handlers each for the cost sliders for performance
     * the onChange method uses local state; the onAfterChange dispatches to store
     * @param val
     */
    handleDistanceSliderChange(val) {
        this.setState({ radius: val });
    }
    updateStoreRadius(val) {
        let parsed = queryString.parse(this.props.location.search);
        parsed.radius = val;
        this.props.history.push(
            `${this.props.location.pathname}?${queryString.stringify(parsed)}`
          );
        console.log('updateStoreRadius:', this.props);
        this.props.onChangeRadius(val);
    }
    handleCostSliderChange(val) {
        this.setState({ max_cost: val });
    }
    updateStoreCost(val) {
        let parsed = queryString.parse(this.props.location.search);
        parsed.max_cost = val;
        this.props.history.push(
            `${this.props.location.pathname}?${queryString.stringify(parsed)}`
          );
        console.log('updateStoreCost:', this.props);
        this.props.onChangeMaxCost(val);
    }

    onFieldChange(e) {
        // destruct the event to local consts
        const { target: { name, value, checked } } = e;
        // get local copy of state filtersSet
        console.log("Filters::onFieldChange: name, value: ", name, value)
        const filtersSet = { ...this.props.filtersSet };
        console.log("Filters::onFieldChange: filtersSet: ", filtersSet)
        // get the specific filter passed
        let thisArray = filtersSet[name];
        // add the passed value
        thisArray.push(value)
        // if it was 'unchecked', remove value from filter
        if (!checked) {
            thisArray = thisArray.filter(field => field !== value);
        }
        // welcome_genders is a radio, so filter on only this value
        if (name === 'welcome_genders') {
            thisArray = thisArray.filter(field => field === value);
        }
        // merge back into filters
        filtersSet[name] = thisArray;
        // update store with state
        console.log("Filters::onFieldChange: filtersSet: ", filtersSet)
        this.props.onFilterChange(filtersSet);
        // this.setState({filtersSet: filtersSet});
        // this.updateUrlFromState()
        // this.props.history.push(queryString.stringify(filtersSet));
    }

    clearClubsThatPlay() {
        let filtersSet = { ...this.props.filtersSet };
        let parsed = queryString.parse(this.props.location.search);
        delete parsed.clubs_that_play_course;
        filtersSet.clubs_that_play_course = [];
        this.props.onClearSelectedClub(filtersSet);  // clear the facility filter
        this.props.history.push(
            `${this.props.location.pathname}?${queryString.stringify(parsed)}`
          );
    }

    render() {
        const cost = this.props.max_cost >= 200 ? 'ANY' : '$' + this.props.max_cost;
        const rad = this.state.radius;
        console.log("Filters::render: props.radius: ", rad)
        const geoLocation = this.props.geoLocation;
        console.log("Filters::render: geoLocatoin: ", geoLocation)
        // get the lowest membership in current clubs list, to set min in cost slider
        const min_cost = this.props.clubs.length ? parseInt(this.props.clubs.sort((a, b) => parseFloat(a.new_greenfees) - parseFloat(b.new_greenfees))[0].new_greenfees) : 25;
        const filters = this.props.filtersSet;
        //if the clubs_that_play filter is set, show an option so user can unset it
        const fac_id = parseInt(this.props.filtersSet.clubs_that_play_course);
        const facility = this.props.facilities.find(
            fac => parseInt(fac.id) === fac_id
          );
        const clubsThatPlay = this.props.filtersSet.clubs_that_play_course.length ?
            <div className="card clubs-that-play checked">
                <div className="card-block">
                    <h5 className="card-title">CLUBS THAT PLAY AT</h5>
                    <div className="form-check">
                        <label className="form-check-label">
                            {facility.title}
                            <input
                                name="clubs_that_play"
                                className="form-check-input"
                                type="checkbox"
                                onChange={this.clearClubsThatPlay}
                                checked={"checked"}
                            />
                            <span className="form-check__checkbox"></span>
                        </label>
                    </div>
                </div>
            </div>
            : '';
        console.log('Filters: renderr:', this.props);
        let config = filterConfig.fetchFilters(this.props.clubs, filters);
        /*
        let config2 = {
            members: {
                title: 'I PLAY WITH',
                includedClass: filters.member_types.length || filters.welcome_genders.length ? "card member-type checked":"card member-type",
                propName: 'welcome_genders',
                fields: [
                    {
                        name: 'Men Only',
                        filterName: 'male'
                    },
                    {
                        name: 'Women Only',
                        filterName: 'female'
                    },
                    {
                        name: 'Men & Women',
                        // filterName: ['male', 'female']
                        filterName: 'both'
                    },
                    {
                        name: 'Seniors',
                        filterName: 'seniors',
                        propOverride: 'member_types',
                        disable: !!!this.props.clubs.filter(club => club.member_types.indexOf("seniors") > -1).length
                    },
                    // {
                    //     name: 'Accepts Juniors',
                    //     filterName: 'child',
                    //     disable: !!!this.props.clubs.filter(club => club.welcome_genders.indexOf("child") > -1).length
                    // },
                    // {
                    //     name: 'Accepts Associates',
                    //     filterName: 'associate-members',
                    //     propOverride: 'member_types',
                    //     disable: !!!this.props.clubs.filter(club => club.member_types.indexOf("associate-members") > -1).length
                    // },
                ]
            },
            skillLevel: {
                title: "MY SKILL LEVEL",
                propName: 'skill_level',
                includedClass: filters.skill_level.length
                    ? "card skill-levels checked"
                    : "card skill-levels",
                fields: [
                    {
                        name: "Beginners",
                        filterName: "beginners",
                        disable: !this.props.clubs.filter(club => club.levels.indexOf("beginners") > -1).length,
                    },
                    {
                        name: "High Handicaps (20+)",
                        filterName: "high-handicaps",
                        disable: !this.props.clubs.filter(club => club.levels.indexOf("high-handicaps") > -1).length

                    },

                    {
                        name: "Average Handicaps (10-20)",
                        filterName: "average-handicaps",
                        disable: !this.props.clubs.filter(club => club.levels.indexOf("average-handicaps") > -1).length,
                    },
                    {
                        name: "Single Digit Handicaps",
                        filterName: "single-digit-handicaps",
                        shouldDisable: !this.props.clubs.filter(club => club.levels.indexOf("single-digit-handicaps") > -1).length,
                    },
                    {
                        name: "Scratch Players",
                        filterName: "scratch-players",
                        shouldDisable: !this.props.clubs.filter(club => club.levels.indexOf("scratch-players") > -1).length,
                    }
                ]
            },
            averageAge: {
                title: 'MY AGE',
                propName: 'average_age',
                includedClass: filters.average_age.length ? "card average-age checked" : "card average-age",
                fields: [
                    {
                        name: "55+",
                        filterName: "55+",
                        disable: !this.props.clubs.filter(club => {
                            return ([...(this.props.filtersSet.average_age || []), "55+"].some(age => club.age.indexOf(age) > -1))
                        }).length
                    },
                    {
                        name: "36-55",
                        filterName: "36-55",
                        disable: !this.props.clubs.filter(club => {
                            return ([...(this.props.filtersSet.average_age || []), "36-55"].some(age => club.age.indexOf(age) > -1))
                        }).length
                    },
                    {
                        name: "18-35",
                        filterName: "18-35",
                        disable: !this.props.clubs.filter(club => {
                            return ([...(this.props.filtersSet.average_age || []), "18-35"].some(age => club.age.indexOf(age) > -1))
                        }).length
                    },
                    {
                        name: "under 18",
                        filterName: "-18",
                        disable: !this.props.clubs.filter(club => {
                            return ([...(this.props.filtersSet.average_age || []), "-18"].some(age => club.age.indexOf(age) > -1))
                        }).length
                    },
                ]
            },
            plays: {
                title: 'I PLAY',
                propName: 'play_days',
                includedClass: filters.play_days.length ? "card we-play-days checked" : "card we-play-days",
                fields: [
                    {
                        name: 'Weekdays',
                        filterName: 'weekdays',
                        disable: !this.props.clubs.filter(club => club.play_days.indexOf('weekdays') > -1).length,
                    },
                    {
                        name: 'Saturday',
                        filterName: 'saturday',
                        disable: !this.props.clubs.filter(club => club.play_days.indexOf('saturday') > -1).length,
                    },
                    {
                        name: 'Sunday',
                        filterName: 'sunday',
                        disable: !this.props.clubs.filter(club => club.play_days.indexOf('sunday') > -1).length,
                    },
                    {
                        name: 'Any Day',
                        filterName: 'any_day',
                        disable: !this.props.clubs.filter(club => club.play_days.indexOf('any_day') > -1).length,
                    },
                ]
            },
            whys: {
                title: 'WHY I PLAY',
                propName: 'whys',
                clubPropName: 'whys',
                includedClass: filters.whys.length ? "card whys checked" : "card whys",
                fields: [
                    {
                        name: 'Competition',
                        filterName: 'competition',
                        disable: !this.props.clubs.filter(club => club.whys.indexOf('competition') > -1).length
                    },
                    {
                        name: 'Fun/Social Golf',
                        filterName: 'fun-social-golf',
                        disable: !this.props.clubs.filter(club => club.whys.indexOf('fun-social-golf') > -1).length,
                    },
                    {
                        name: 'Play New Courses',
                        filterName: 'play-new-courses',
                        disable: !this.props.clubs.filter(club => club.whys.indexOf('play-new-courses') > -1).length
                    },
                    {
                        name: 'Business & Networking',
                        filterName: 'business-networking',
                        disable: !this.props.clubs.filter(club => club.whys.indexOf('business-networking') > -1).length
                    },
                    {
                        name: 'Introducing People to the Game',
                        filterName: 'introducing-people-to-the-game',
                        disable: !this.props.clubs.filter(club => club.whys.indexOf('introducing-people-to-the-game') > -1).length
                    },
                    {
                        name: 'Instruction',
                        filterName: 'instruction',
                        disable: !this.props.clubs.filter(club => club.whys.indexOf('instruction') > -1).length
                    },
                    {
                        name: 'Staying Active/Health',
                        filterName: 'staying-active-health',
                        disable: !this.props.clubs.filter(club => club.whys.indexOf('staying-active-health') > -1).length
                    },
                ]
            },
            clubType: {
                title: 'I PREFER TO',
                includedClass: this.props.filtersSet.club_types.length ? "card club-types checked" : "card club-types",
                propName: 'club_types',
                fields: [
                    {
                        name: 'Play a Home Course',
                        filterName: 'home-course'
                    },
                    {
                        name: 'Travel Around To Play',
                        filterName: 'travel'
                    }
                ]
            },
            gameStyle: {
                title: 'HOW I PLAY',
                includedClass: this.props.filtersSet.game_styles.length ? "card game-style checked" : "card game-style",
                propName: 'game_styles',
                fields: [
                    {
                        name: 'Competitive (Net)',
                        filterName: 'competitive-net',
                        disable: !this.props.clubs.filter(club => club.game_styles.indexOf("competitive-net") > -1).length
                    },
                    {
                        name: 'Competitive (Gross)',
                        filterName: 'competitive-gross',
                        disable: !this.props.clubs.filter(club => club.game_styles.indexOf("competitive-gross") > -1).length
                    },
                    {
                        name: 'Social (Non-Competitive)',
                        filterName: 'social-non-competitive',
                        disable: !this.props.clubs.filter(club => club.game_styles.indexOf("social-non-competitive") > -1).length
                    },
                    {
                        name: 'Team Events',
                        filterName: 'team-events',
                        disable: !this.props.clubs.filter(club => club.game_styles.indexOf("team-events") > -1).length
                    },
                    {
                        name: 'Skins Games',
                        filterName: 'skins-games',
                        disable: !this.props.clubs.filter(club => club.game_styles.indexOf("skins-games") > -1).length
                    },
                    {
                        name: 'League',
                        filterName: 'league',
                        disable: !this.props.clubs.filter(club => club.game_styles.indexOf("league") > -1).length
                    },
                    {
                        name: 'Couples Golf',
                        filterName: 'couples-golf',
                        disable: !this.props.clubs.filter(club => club.game_styles.indexOf("couples-golf") > -1).length
                    },
                    {
                        name: 'Junior Events',
                        filterName: 'junior-events',
                        disable: !this.props.clubs.filter(club => club.game_styles.indexOf("junior-events") > -1).length
                    },
                    {
                        name: 'Events for Beginners',
                        filterName: 'events-for-beginners',
                        disable: !this.props.clubs.filter(club => club.game_styles.indexOf("events-for-beginners") > -1).length
                    },
                    {
                        name: 'Member/Guest',
                        filterName: 'member-guest',
                        disable: !this.props.clubs.filter(club => club.game_styles.indexOf("member-guest") > -1).length
                    },
                ]

            },
            
            languages: {
                title: 'MY LANGUAGES',
                includedClass: this.props.filtersSet.languages.length ? "card languages checked":"card languages",
                propName: 'languages',
                fields: [
                    {
                        name: 'English',
                        filterName: 'english',
                        disable: !!!this.props.clubs.filter(club => club.langs.indexOf("english") > -1).length
                    },
                    {
                        name: 'Spanish',
                        filterName: 'spanish',
                        disable: !!!this.props.clubs.filter(club => club.langs.indexOf("english") > -1).length
                    },
                    {
                        name: 'Korean',
                        filterName: 'korean',
                        disable: !!!this.props.clubs.filter(club => club.langs.indexOf("korean") > -1).length
                    },
                    {
                        name: 'Chinese',
                        filterName: 'chinese',
                        disable:!!!this.props.clubs.filter(club => club.langs.indexOf("chinese") > -1).length
                    },
                    {
                        name: 'Vietnamese',
                        filterName: 'vietnamese',
                        disable: !!!this.props.clubs.filter(club => club.langs.indexOf("vietnamese") > -1).length
                    },
                    {
                        name: 'Farsi',
                        filterName: 'farsi',
                        disable: !!!this.props.clubs.filter(club => club.langs.indexOf("farsi") > -1).length
                    },
                    {
                        name: 'Japanese',
                        filterName: 'japanese',
                        disable: !!!this.props.clubs.filter(club => club.langs.indexOf("japanese") > -1).length
                    },
                    {
                        name: 'Russian',
                        filterName: 'russian',
                        disable: !!!this.props.clubs.filter(club => club.langs.indexOf("russian") > -1).length
                    },
                    {
                        name: 'Tagalog',
                        filterName: 'tagalog',
                        disable: !!!this.props.clubs.filter(club => club.langs.indexOf("tagalog") > -1).length
                    },
                    {
                        name: 'French',
                        filterName: 'french',
                        disable: !!!this.props.clubs.filter(club => club.langs.indexOf("french") > -1).length
                    },
                    {
                        name: 'German',
                        filterName: 'german',
                        disable: !!!this.props.clubs.filter(club => club.langs.indexOf("german") > -1).length
                    },
                    {
                        name: 'Italian',
                        filterName: 'italian',
                        disable: !!!this.props.clubs.filter(club => club.langs.indexOf("italian") > -1).length
                    },
                    {
                        name: 'Portuguese',
                        filterName: 'portuguese',
                        disable: !!!this.props.clubs.filter(club => club.langs.indexOf("portuguese") > -1).length
                    },
                    {
                        name: 'Thai',
                        filterName: 'thai',
                        disable: !!!this.props.clubs.filter(club => club.langs.indexOf("thai") > -1).length
                    },
                    {
                        name: 'Punjabi',
                        filterName: 'punjabi',
                        disable: !!!this.props.clubs.filter(club => club.langs.indexOf("punjabi") > -1).length
                    },
                    {
                        name: 'Cambodian',
                        filterName: 'cambodian',
                        disable: !!!this.props.clubs.filter(club => club.langs.indexOf("cambodian") > -1).length
                    },
                ]
            },
        };
        */

        return (
            <div className="options" style={this.props.show_filters ? { bottom: "0px" } : { bottom: "200%" }}>
                <form action="" id="options-form">
                    {clubsThatPlay}
                    <div className={this.props.radius !== 20 ? "card max-distance slider checked" : "card max-distance slider"}>
                        <div className="card-block">
                            <h5 className="card-title">DISTANCE <span className="max-distance float-right">{this.props.radius} miles</span> </h5>
                            <div id="distance-slider" className="slider">
                                <Slider
                                    min={5}
                                    max={200}
                                    step={5}
                                    // defaultValue={20}
                                    value={this.state.radius}
                                    onChange={this.handleDistanceSliderChange.bind(this)}
                                    onAfterChange={this.updateStoreRadius.bind(this)}
                                />
                            </div>
                            <input className="form-check-input" type="hidden" id="max-distance" name="max_distance" value={this.props.radius} />
                        </div>
                    </div>
                    <div className={this.state.max_cost < 200 ? "card max-cost slider checked" : "card max-cost slider"}>
                        <div className="card-block">
                            <h5 className="card-title">MAX PRICE <span className="max-cost float-right">{cost}</span> </h5>
                            <div id="cost-slider" className="slider">
                                <Slider
                                    min={min_cost <= 50 ? 50 : min_cost}
                                    max={200}
                                    step={1}
                                    defaultValue={200}
                                    onChange={this.handleCostSliderChange.bind(this)}
                                    onAfterChange={this.updateStoreCost.bind(this)}
                                />
                            </div>
                            <input className="form-check-input" type="hidden" id="max-cost" name="max_cost" value="200" />
                        </div>
                    </div>
                    
                    <CheckboxFilters config={config.plays} clubs={this.props.clubs} filtersSet={this.props.filtersSet} />
                    <CheckboxFilters config={config.skillLevel} clubs={this.props.clubs} filtersSet={this.props.filtersSet} />
                    <CheckboxFilters config={config.averageAge} clubs={this.props.clubs} filtersSet={this.props.filtersSet} />
                    <CheckboxFilters config={config.members} clubs={this.props.clubs} filtersSet={this.props.filtersSet} />
                    <CheckboxFilters config={config.whys} clubs={this.props.clubs} filtersSet={this.props.filtersSet} />
                    <CheckboxFilters config={config.languages} clubs={this.props.clubs} filtersSet={this.props.filtersSet} />
                    <CheckboxFilters config={config.clubType} clubs={this.props.clubs} filtersSet={this.props.filtersSet} />
                    <CheckboxFilters config={config.gameStyle} clubs={this.props.clubs} filtersSet={this.props.filtersSet} />
                </form>
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        filtersSet: state.filtersSet,
        facilities: state.facilities,
        geoLocation: state.geoLocation,
        max_cost: state.max_cost,
        radius: state.radius,
        selected: state.selected,
        selectedClub: state.selectedClub,
        show_filters: state.show_filters,
    };
}
const mapDispatchToProps = dispatch => {
    return {
        onChangeRadius: (val) => dispatch({ type: 'CHANGE_RADIUS', value: val }),
        onChangeMaxCost: (val) => dispatch({ type: 'CHANGE_MAXCOST', value: val }),
        onChangeSearchType: () => dispatch({ type: 'CHANGE_SEARCHTYPE' }),
        onFilterChange: (filtersSet) => dispatch({ type: 'CHANGE_FILTER', value: filtersSet }),
        onSelectClub: (club) => dispatch({ type: 'SET_SELECTED_CLUB', value: club }),
        onClearSelectedClub: (filtersSet) => dispatch({ type: 'CLEAR_SELECTED_CLUB', value: filtersSet }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Filters));