import React from "react";
import App from "./App.js";
import ResultPage from "./ResultPage";
const routes = [
  { path: "/byLocation", component: ResultPage, exact: true },
  { path: "/byClub", component: ResultPage, exact: true },
  { path: "/popular", component: ResultPage, exact: true },
  { path: "/search", component: ResultPage, exact: true },
  { path: "/travel", component: ResultPage, exact: true },
  { path: "/womens", component: ResultPage, exact: true },
  { path: "/senior", component: ResultPage, exact: true },
  { path: "/social", component: ResultPage, exact: true },
  { path: "/events", component: ResultPage, exact: true },
  { path: ":filter?", component: App }
];

export default routes;
