import React from "react";
import moment from "moment/moment";
import { connect } from "react-redux";
import { withRouter } from "react-router";

class EventItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false
      // setFacilityFilter: this.props.setFacilityFilter,
    };
    this.handleHover = this.handleHover.bind(this);
    // this.selectFacility = this.selectFacility.bind(this);
    this.setFacilityFilter = this.setFacilityFilter.bind(this);
    this.goToClub = this.goToClub.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      event: nextProps.event,
      setFacilityFilter: nextProps.setFacilityFilter
    });
  }
  // toggle hover state on hover
  handleHover() {
    this.setState({
      hover: !this.state.hover
    });
  }
  goToClub(facility) {
    console.log("goToClub: ", facility);
    this.props.history.push(
      `/byClub?club=${facility.url_title}&lat=${facility.facility_latitude}&lng=${facility.facility_longitude}`
    );
    window.scrollTo(0, 150);
  }
  // selectFacility(){
  //     console.log("EventItem::selectFacility: passing "+this.props.event.facility.slug)
  //     this.props.onFacilitySelect(this.props.event.facility.slug)
  // }
  setFacilityFilter() {
    let filtersSet = { ...this.props.filtersSet };
    this.props.onClearFilters();
    filtersSet.clubs_that_play_course = [this.props.event.facility.id];
    const location = {
      lat: parseFloat(this.props.event.facility.facility_latitude),
      lng: parseFloat(this.props.event.facility.facility_longitude)
    };
    console.log(
      "EventItem::setFacilityFilter: location: ",
      filtersSet,
      location
    );

    this.props.onFacilitySelect(filtersSet, location);
    this.props.history.push(
      `/events?eventId=${this.props.event.id}&lat=${location.lat}&lng=${location.lng}&clubs_that_play_course=${this.props.event.facility.id}`
    );

    window.scrollTo(0, 150);
  }

  render() {
    const event = this.props.event;
    // console.log("EventItem::render: event facility image: ",event.facility_image)
    let gridClass = "event-grid-item ";
    gridClass += !this.props.locationSet
      ? this.props.gridOrientation
      : "results";
    gridClass += this.state.hover ? " hover" : "";
    let facility_image = "/images/default_facility.jpg";
    if (event.facility_image) {
      let image_name = event.facility_image.substring(
        event.facility_image.lastIndexOf("/") + 1
      );
      const image_path = event.facility_image.substring(
        0,
        event.facility_image.lastIndexOf("/") + 1
      );
      const parts = image_name.split(".");
      let image_base = image_name.replace(/\.[^/.]+$/, "");
      image_base += "__1300.";
      image_name = image_base + parts[1];
      facility_image = image_path + image_name;
      // console.log("EventItem::render: facility image with 1300: ", facility_image)
    }
    // console.log("EventItem::render: event.facility_image: ", event.facility_image)
    // console.log("EventItem::render: image_path: ", image_path)
    // console.log("EventItem::render: image_name: ", image_name)
    // console.log("EventItem::render: facility_image: ", facility_image)
    return (
      <div
        className={gridClass}
        key={event.id}
        onMouseEnter={this.handleHover}
        onMouseLeave={this.handleHover}
      >
        <img
          src={facility_image}
          alt="golf course"
          className={this.state.hover ? "img-fluid grayed" : "img-fluid"}
        />
        <p className="course-name">{event.facility.title}</p>
        <div className="hover-text event-name">{event.name}</div>
        <div
          className="hover-text facility-name"
          style={{ cursor: "pointer" }}
          onClick={() => this.goToClub(event.facility)}
        >
          {event.facility.title}
        </div>
        <div className="hover-text event-date">
          {moment(event.event_date).format("MMMM Do, h:mm A")}
        </div>
        <div
          className="hover-text similar"
          title={"See other clubs who play at " + event.facility.title}
        >
          <div className={"similar-btn"} onClick={this.setFacilityFilter}>
            <i className="far fa-search"></i> similar clubs
          </div>
        </div>
        <div className="hover-text club-name-div">
          <p>hosted by</p>
          <div className="hover-text club-name">{event.club.title}</div>
        </div>
      </div>
    );
  }
}
const defaultFiltersSet = {
  skill_level: [],
  play_days: [],
  average_age: [],
  whys: [],
  club_types: [],
  game_styles: [],
  member_types: [],
  languages: [],
  welcome_genders: [],
  clubs_that_play_course: []
};
const mapStateToProps = state => {
  return {
    filtersSet: state.filtersSet,
    selectedClub: state.selectedClub,
    selected: state.selected
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onFacilitySelect: (filtersSet, location) =>
      dispatch({
        type: "SET_CLUBS_THAT_PLAY",
        payload: { filtersSet: filtersSet, geoLocation: location }
      }),
    onClearFilters: () =>
      dispatch({
        type: "CHANGE_FILTER",
        payload: { filtersSet: defaultFiltersSet }
      })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EventItem));
