import React from "react";
import Results from "./Results";
import Events from "../Events/ClubEvents";
import Featured from "../Featured/FeaturedClub";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import axios from "axios";
const api = "https://clubs.scga.org/api/";

class Womens extends React.Component {
  constructor(props) {
    super(props);
    console.log("Travel constructor: ", this, props);
    this.state = { test: 1 };
  }

  componentDidMount() {
    console.log("Popular props: ", this.props);
    // get Featured club from api
    if (!this.props.featuredClub) {
      this.getFeaturedClub();
    }
    // get next 10 events, plus thismonthcount and total events count from api
    if (this.props.events.length === 0) {
      this.getNextEvents(0);
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { clubs } = props;
    let hasClubs = false;
    console.log("Women getDerivedStateFromProps: ", this, props);
    if (clubs && clubs.length && !!!state.hasClubs) {
      hasClubs = true;
      console.log("Popular getDerivedStateFromProps: set true", props, state);
      props.onPopularReady();
    } else if (state && state.hasClubs) {
      hasClubs = true;
    }
    return {
      ...state,
      hasClubs
    };
  }

  getFeaturedClub() {
    axios
      .get(api + "featured_club")
      .then(response => response.data)
      .then(data => {
        this.props.onSetFeaturedClub(data);
      })
      .catch(error => console.error(error));
  }
  /**
   * get next 10 upcoming events, this month count, and total future count
   * @param offset
   */
  getNextEvents(offset) {
    axios
      .get(api + "events/" + offset)
      .then(response => response.data)
      .then(data => {
        this.props.onSetEvents(
          data.events,
          data.futureCount,
          data.thisMonthCount
        );
      })
      .catch(error => console.error(error));
  }

  render() {
    const { location } = this.props.history;
    const { hasClubs } = this.state;
    console.log(
      "Womens render: ",
      this.props,
      this.state,
      !!this.props.locationSet,
      hasClubs
    );
    return (
      <div className="container-fluid content">
        {/*only show Results if location set*/}
        {!!this.props.locationSet && hasClubs && (
          <Results locationType={this.props.locationType} />
        )}
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    clubs: state.clubs,
    events: state.events,
    featuredClub: state.featuredClub,
    filtersSet: state.filtersSet,
    locationSet: state.locationSet,
    selectedClub: state.selectedClub,
    selected: state.selected
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onSetFeaturedClub: featured =>
      dispatch({ type: "SET_FEATURED_CLUB", value: featured }),
    onPopularReady: () => dispatch({ type: "SET_HOTLINK", value: "womens" }),
    onSetEvents: (events, eventsFutureCount, eventsThisMonthCount) =>
      dispatch({
        type: "SET_EVENTS",
        payload: {
          events: events,
          eventsFutureCount: eventsFutureCount,
          eventsThisMonthCount: eventsThisMonthCount
        }
      })
  };
};
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Womens)
);
