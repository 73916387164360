import React from "react";
import Results from "./Results";
import Events from "../Events/ClubEvents";
import Featured from "../Featured/FeaturedClub";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import axios from "axios";
import queryString from "query-string";
import _ from "lodash";
const api = "https://clubs.scga.org/api/";

class EventsPage extends React.Component {
  constructor(props) {
    super(props);
    console.log("EventsPage constructor: ", this, props);
    this.state = { test: 1 };
  }

  componentDidMount() {
    console.log("EventsPage props: ", this.props);
    // get Featured club from api
    if (!this.props.featuredClub) {
      this.getFeaturedClub();
    }
    // get next 10 events, plus thismonthcount and total events count from api
    if (this.props.events.length === 0) {
      this.getNextEvents(0);
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { events } = props;
    let hasEvent = false;
    console.log("EventsPage getDerivedStateFromProps: ", this, props);
    if (events && events.length && !!!state.hasEvent) {
      const qs = queryString.parse(props.location.search);
      const event = _.find(props.events, { id: parseInt(qs.eventId) });
      if (event) {
        let filtersSet = { ...props.filtersSet };
        filtersSet.clubs_that_play_course = [event.facility.id];
        const location = {
          lat: parseFloat(event.facility.facility_latitude),
          lng: parseFloat(event.facility.facility_longitude)
        };
        console.log(
          "EventsPage::setFacilityFilter: location: ",
          filtersSet,
          location
        );
        props.onFacilitySelect(filtersSet, location);
        props.setMapCenter(location, null);
        hasEvent = true;
      }
    } else if (state && state.hasEvent) {
      hasEvent = true;
    }
    return {
      ...state,
      hasEvent
    };
  }

  getFeaturedClub() {
    axios
      .get(api + "featured_club")
      .then(response => response.data)
      .then(data => {
        this.props.onSetFeaturedClub(data);
      })
      .catch(error => console.error(error));
  }
  /**
   * get next 10 upcoming events, this month count, and total future count
   * @param offset
   */
  getNextEvents(offset) {
    axios
      .get(api + "events/" + offset)
      .then(response => response.data)
      .then(data => {
        this.props.onSetEvents(
          data.events,
          data.futureCount,
          data.thisMonthCount
        );
      })
      .catch(error => console.error(error));
  }

  render() {
    const { location } = this.props.history;
    const { hasEvent } = this.state;
    console.log(
      "EventsPage render: ",
      this.props,
      this.state,
      !!this.props.locationSet,
      hasEvent
    );
    return (
      <div className="container-fluid content">
        {/*only show Results if location set*/}
        {!!this.props.locationSet && hasEvent && (
          <Results locationType={this.props.locationType} />
        )}
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    clubs: state.clubs,
    events: state.events,
    featuredClub: state.featuredClub,
    filtersSet: state.filtersSet,
    locationSet: state.locationSet,
    selectedClub: state.selectedClub,
    selected: state.selected
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onSetFeaturedClub: featured =>
      dispatch({ type: "SET_FEATURED_CLUB", value: featured }),
    onPopularReady: () => dispatch({ type: "SET_HOTLINK", value: "womens" }),
    onFacilitySelect: (filtersSet, location) =>
      dispatch({
        type: "SET_CLUBS_THAT_PLAY",
        payload: { filtersSet: filtersSet, geoLocation: location }
      }),
    setMapCenter: (center, zoom) =>
      dispatch({
        type: "SET_MAP_CENTER_ZOOM",
        payload: { center: center, zoom: null }
      }),
    onSetEvents: (events, eventsFutureCount, eventsThisMonthCount) =>
      dispatch({
        type: "SET_EVENTS",
        payload: {
          events: events,
          eventsFutureCount: eventsFutureCount,
          eventsThisMonthCount: eventsThisMonthCount
        }
      })
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EventsPage));
