import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux'

class TypeLinks extends React.Component {
    constructor(props) {
        super(props);
        this.handleClickEvent = this.handleClickEvent.bind(this);
        this.goToPopular = this.goToPopular.bind(this);
    }

    handleClickEvent(e) {
        const { target: { id } } = e;
        console.log("TypeLink: click event: ", id)
        this.props.onChangeHotlink(id)
    }
    goToPopular() {
        console.log('goToPopular');
        this.props.history.push('/popular');
    }
    render() {
        if (this.props.locationSet) return '';
        return <div className="club-type-links">
            <div className="links-group">
                {/* <Link to={{ pathname: "/popular", hash: '#' }} className="type-link" id="popular" onClick={this.handleClickEvent}>Popular Clubs</Link> */}
                <Link to={{ pathname: "/popular", hash: '#' }} className="type-link" id="popular" onClick={this.goToPopular}>Popular Clubs</Link>
                <Link to={{ pathname: "/travel", hash: '#' }} className="type-link" id="travel" onClick={this.handleClickEvent}>Travel Clubs</Link>
                <Link to="/womens" className="type-link" id="womens" onClick={this.handleClickEvent}>Women's Clubs</Link>
                <Link to="/senior" className="type-link" id="senior" onClick={this.handleClickEvent}>Senior Clubs</Link>
                <Link to={{ pathname: "/social", hash: '#' }} className="type-link" id="social" onClick={this.handleClickEvent}>Social Clubs</Link>
            </div>
        </div>;
    }
}
const mapStateToProps = state => {
    return {
        locationSet: state.locationSet,
        hotLink: state.hotLink,
    };
}
const mapDispatchToProps = dispatch => {
    return {
        onChangeHotlink: (hotlink) => dispatch({ type: 'SET_HOTLINK', value: hotlink }),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TypeLinks));