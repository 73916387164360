import React from "react";
import MapContainer from "../Results/MapContainer";
import Filters from "./Filters";
import ResultCard from "./ResultCard";
import FeaturedClub from "../Featured/FeaturedClubSmall";
import Events from "../Events/ClubEvents";
import { Link, withRouter } from "react-router-dom";
import Select, { components } from "react-select";
import queryString from "query-string";
import qs from "qs";
import _ from "lodash";
import { connect } from "react-redux";

class Results extends React.Component {
  constructor(props) {
    super(props);
    this.livechat = null;
    this.state = {
      map_compressed: false,
      show_filters: true,
      radius: null,
      sortName: 'recommended'
    };
    // this.onFieldChange = this.onFieldChange.bind(this);
    this.handleCostChange = this.handleCostChange.bind(this);
    this.setFiltersFromUrl = this.setFiltersFromUrl.bind(this);
    this.updateUrlFromState = this.updateUrlFromState.bind(this);
    this.handleChangeRadius = this.handleChangeRadius.bind(this);
    this.onHandleMapCaretClick = this.onHandleMapCaretClick.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    
  }

  componentWillUpdate(nextProps) {
    // console.log("Results::componentWillUpdate: updating url...")
  }
  componentDidMount() {
    // if(this.props.hotLink)
    //     this.setState({resultsTitle: this.props.hotLink +" clubs near you"});
    const { match, location, history } = this.props;
    // console.log("Results: location.search: "+location.search)
    const scrollTop = document.querySelector("div.row.count-row").scrollTop;
    console.log("Results::componentDidMount: scrollTop: ", scrollTop);
    /*if (!this.props.show_filters) //window.scrollTo(0, scrollTop)
            document.querySelector('div.row.count-row').scrollIntoView()*/
    // if (!this.props.show_filters) window.scrollTo(0, 150)
    // this.props.history.push(this.props.query_string)
    // set mapcompressed default in mobile
    if (515 > window.innerWidth) {
      this.props.onSetMapCompressed(true);
    }
    //if scrolling, compress map
    if (!this.props.map_compressed) {
      window.addEventListener("scroll", this.handleScroll);
    } else {
      window &&
        window.removeEventListener &&
        window.removeEventListener("scroll", this.handleScroll);
    }
  }


  handleScroll() {
    if (!this.props.map_compressed) this.props.onSetMapCompressed(true);
  }

  handleSelect(e) {
    const val = e.target.value;
    console.log('handleSelect: ', val);
    this.setState({
      ...this.state,
      sortName: val
    });
  }
  componentWillUnmount() {
    window.LC_API.hide_chat_window();
  }

  showChat(title) {
    if (window.LC_API){
      var custom_variables = [
        { name: "title", value: title },
        { name: "application", value: "Club Finder"}
      ];
      window.LC_API.update_custom_variables(custom_variables);
      window.LC_API.open_chat_window();
    } 
  }

  /**
   * compress or expand the map
   */
  onHandleMapCaretClick() {
    //get the current zoom level of map, save in store
    const zoom = window.myMap.getZoom();
    console.log(
      "onHandleMapCaretClick",
      window.myMap.center,
      window.myMap.zoom
    );
    this.props.onSetZoom(window.myMap.center, window.myMap.zoom);
    //toggle map state
    this.props.onSetMapCompressed(!this.props.map_compressed);
  }
  setFiltersFromUrl(params) {
    // let filtersSet = {...this.props.filtersSet}
    // console.log("setFiltersFromUrl:: radius: "+params.radius)
    // params.radius && this.props.handleChangeRadius(params.radius)
  }
  removeTag = (name, value) => {
    // let currentFilter = _.cloneDeep(this.state.currentFilter);
    // _.map(Object.keys(this.state.currentFilter), key => {
    //   const arr = this.state.currentFilter[key];
    //   const removed = _.remove(arr, (name) => {
    //     return tag !== name;
    //   });
    //   currentFilter[key] = removed;
    // });
    // console.log('test:', currentFilter);
    // const path = queryString.stringify(currentFilter);
    // console.log('removeTag:', tag, this.state, currentFilter);
    // this.setState({
    //   ...this.state,
    //   currentFilter,
    //   filterPath: path
    // });
    // this.props.history.push(`/?${path}`);


    let parsed = queryString.parse(this.props.location.search);
    // get local copy of state filtersSet
    
    let filtersSet = { ...this.props.filtersSet };
    console.log(
      "Filters::onFieldChange: filtersSet: before",
      filtersSet,
      parsed
    );
    // get the specific filter passed
    let thisArray = filtersSet[name];
    // add the passed value

    thisArray = thisArray.filter(field => field !== value);


    // welcome_genders is a radio, so filter on only this value
    if (name === "welcome_genders") {
      thisArray = thisArray.filter(field => field === value);
    }
    // merge back into filters
    filtersSet[name] = thisArray;
    parsed[name] = thisArray;
    // update store with state
    console.log(
      "Filters::onFieldChange: filtersSet: after",
      filtersSet,
      parsed
    );
    const path = `${this.props.location.pathname}?${queryString.stringify(parsed)}`;

    this.props.onFilterChange(filtersSet);
    
      this.props.history.push(
        path
      );
    

  }

  onFieldChange = e => {
    // destruct the event to local consts
    const {
      target: { name, value, checked }
    } = e;
    let parsed = queryString.parse(this.props.location.search);
    // get local copy of state filtersSet
    console.log("CheckboxFilters::onFieldChange: name, value: ", name, value);
    let filtersSet = { ...this.props.filtersSet };
    console.log(
      "Filters::onFieldChange: filtersSet: before",
      filtersSet,
      parsed
    );
    // get the specific filter passed
    let thisArray = filtersSet[name];
    // add the passed value
    if (checked) {
      thisArray.push(value);
    } else {
      thisArray = thisArray.filter(field => field !== value);
    }

    // welcome_genders is a radio, so filter on only this value
    if (name === "welcome_genders") {
      thisArray = thisArray.filter(field => field === value);
    }
    // merge back into filters
    filtersSet[name] = thisArray;
    parsed[name] = thisArray;
    // update store with state
    console.log(
      "Filters::onFieldChange: filtersSet: after",
      filtersSet,
      parsed
    );
    const path = `${this.props.location.pathname}?${queryString.stringify(parsed)}`;

    this.props.onFilterChange(filtersSet);
    if(this.props.updateParam) {
      this.props.history.push(
        path
      );
    }

  };
  updateUrlFromState() {
    const new_url_vals = {
      ...this.props.filtersSet,
      radius: this.props.radius,
      max_cost: this.props.max_cost
    };
    console.log(new_url_vals);
    const new_url = queryString.stringify(new_url_vals);
    console.log("Results::updateUrlFromState: new_url");
    console.log(new_url);
    this.props.history.push("?" + new_url);
  }

  handleCostChange(val) {
    this.setState({ max_cost: val });
    this.updateUrlFromState();
  }
  handleChangeRadius(val) {
    console.log("Results::handleChangeRadius: " + val);
    this.setState({ radius: val });
    this.updateUrlFromState();
    this.props.handleChangeRadius(val);
  }
  handleBack() {
    // this.props.onChangeSearchType();
    // this.props.history.push("/byLocation?current=1");
    window.location.href = "/byLocation?current=1";
  }

  render() {
    let {
      match,
      location,
      history,
      clubs = [],
      locationType = "geo",
      geoLocation,
      facilities,
      myLocation
    } = this.props;
    //let clubs;

    console.log('Result::render::initial props', this.props);

    const parsed = queryString.parse(location.search);
    delete parsed.radius;
    delete parsed.max_cost;
    const tags = _.map(Object.keys(parsed), (key) => {
      if(key=='radius') {
        return `${parsed[key]} miles`;
      } else if(key=='max_cost') {
        return `$${parsed[key]}`;
      } else {
        return parsed[key];
      }
    });
    const mileIndex = locationType == "geo" ? "miles" : "myMiles";
    const loc = locationType == "geo" ? geoLocation : myLocation;

    if (this.props.hotLink) {
      console.log("HOTLINK: FILTER START", clubs, this.props.hotLink);
      switch (this.props.hotLink) {
        case "travel":
          clubs = clubs.filter(club => club.club_types.indexOf("travel") > -1);
          break;
        case "popular":
          clubs = clubs.filter(club => parseInt(club.member_count) > 125);
          console.log("HOTLINK: FILTER POPULAR", clubs);
          break;
        case "social":
          clubs = clubs.filter(
            club =>
              club.game_styles.indexOf("social-non-competitive") > -1 &&
              club.whys.indexOf("fun-social-golf") > -1
          );
          break;
        case "womens":
          clubs = clubs.filter(
            club =>
              club.welcome_genders.indexOf("female") > -1 &&
              club.welcome_genders.indexOf("male") === -1
          );
          break;
        case "senior":
          clubs = clubs.filter(club => parseInt(club.is_senior_club) === 1);
          break;
        default:
          break;
      }
      if(this.props.searchType != 'course') {
        console.log("WAH??", clubs, mileIndex);
        clubs = clubs
        .filter(club => club[mileIndex] <= this.props.radius)
        .sort(function(a, b) {
          return a[mileIndex] - b[mileIndex];
        });
        console.log("OMG??", clubs);
      }

      console.log("HOTLINK: FILTER END", clubs);
    } else if (this.props.region) {
      const region = this.props.region.toString();
      clubs = clubs
        .filter(club => club.region === region)
        .sort(function(a, b) {
          return a[mileIndex] - b[mileIndex];
        });
      console.log("Region: FILTER END", clubs);
    } else if (this.props.city) {
      console.log("City: ", this.props);
      const city = this.props.city.toLowerCase();
      clubs = clubs
        .filter(club => club.address2_city.toLowerCase() === city)
        .sort(function(a, b) {
          return a[mileIndex] - b[mileIndex];
        });
      console.log("City: FILTER End", clubs);
    } else if (this.props.searchSimilar) {
      clubs = clubs;
    } else {
      console.log("ELSE: ", this.props);
      console.log("ELSE: clubs", clubs);
      // filter clubs within radius and sort on ASC distance
      const foo = clubs.filter(club => club[mileIndex] <= this.props.radius);
      clubs = clubs
        .filter(club => club[mileIndex] <= this.props.radius)
        .sort(function(a, b) {
          return a[mileIndex] - b[mileIndex];
        });
      console.log("ELSE: END", clubs, foo);
    }
    console.log('Result::render::initial filters', clubs);

    // get filters from store
    const filters = this.props.filtersSet;

    if (filters.skill_level.length) {
      clubs = clubs.filter(club => {
        return filters.skill_level.some(
          level => club.levels.indexOf(level) > -1
        );
      });
    }
    if (filters.play_days.length) {
      clubs = clubs.filter(club => {
        if(filters.play_days.find(s => s === 'any_day')) {
          return true;
        }
        return filters.play_days.every(
          wePlay => club.play_days.indexOf(wePlay) > -1
        );
      });
    }
    if (filters.average_age.length) {
      clubs = clubs.filter(club => {
        if(filters.average_age.find(s => s === 'any_age')) {
          return true;
        }
        return filters.average_age.some(age => club.age.indexOf(age) > -1);
      });
    }
    if (filters.whys.length) {
      clubs = clubs.filter(club => {
        return filters.whys.some(why => club.whys.indexOf(why) > -1);
      });
    }
    if (this.props.max_cost < 200) {
      clubs = clubs.filter(club => club.new_greenfees <= this.props.max_cost);
    }
    if (filters.game_styles.length) {
      clubs = clubs.filter(club => {
        return filters.game_styles.some(
          type => club.game_styles.indexOf(type) > -1
        );
      });
    }
    if (filters.club_types.length) {
      clubs = clubs.filter(club => {
        return filters.club_types.some(
          type => club.club_types.indexOf(type) > -1
        );
      });
    }
    if (filters.game_styles.length) {
      clubs = clubs.filter(club => {
        return filters.game_styles.every(
          type => club.game_styles.indexOf(type) > -1
        );
      });
    }
    if (filters.member_types.length) {
      if (filters.member_types.indexOf("seniors") > -1) {
        clubs = clubs.filter(club => club.is_senior_club);
      }
      if (filters.member_types.indexOf("associate-members") > -1) {
        clubs = clubs.filter(club => club.allow_associate_membership);
      }
    }
    if (filters.welcome_genders.length) {
      if (filters.welcome_genders.indexOf("both") > -1) {
        console.log("Results: both A: ", clubs.length);

        clubs = clubs.filter(club =>
          ["male", "female"].every(
            gender => club.welcome_genders.indexOf(gender) > -1
          )
        );
        console.log("Results: both B: ", clubs.length);
        // debugger;
      }
      if (filters.welcome_genders.indexOf("child") > -1) {
        clubs = clubs.filter(
          club => club.welcome_genders.indexOf("child") > -1
        );
      }
      // female and not male
      if (
        filters.welcome_genders.indexOf("female") > -1 &&
        filters.welcome_genders.indexOf("male") < 0
      ) {
        clubs = clubs.filter(
          club =>
            club.welcome_genders.indexOf("male") < 0 &&
            club.welcome_genders.indexOf("female") > -1
        );
      }
      // male and not female
      if (
        filters.welcome_genders.indexOf("male") > -1 &&
        filters.welcome_genders.indexOf("female") < 0
      ) {
        clubs = clubs.filter(
          club =>
            club.welcome_genders.indexOf("female") < 0 &&
            club.welcome_genders.indexOf("male") > -1
        );
      }
    }
    if (filters.languages.length) {
      clubs = clubs.filter(club => {
        return filters.languages.every(type => club.langs.indexOf(type) > -1);
      });
    }
    if (filters.clubs_that_play_course.length && this.props.facilities.length) {
      const fac_id = parseInt(filters.clubs_that_play_course);
      const facility = this.props.facilities.find(
        fac => parseInt(fac.id) === fac_id
      );
      // const facility = filters.clubs_that_play_course[0];
      clubs = clubs.filter(club => {
        return club.ourFacilities.some(
          fac => fac.slug === facility.url_title || fac.slug === facility.slug
        );
      });
      console.log(
        "filters.clubs_that_play_course: ",
        clubs,
        this.props.facilities
      );
    }

    // create the result cards for each club (except selected)
    let filteredClubs = _.filter(
      clubs,
      club => club.id !== this.props.selectedClub.id
    );
    switch (this.state.sortName) {
      case 'recommended':
        filteredClubs = _.orderBy(filteredClubs, ['recommended', 'offers_free_trial'], ['desc', 'desc'])
        break;
      case 'distance':
        break;
      case 'popularity':
        filteredClubs = _.orderBy(filteredClubs, ['member_count'], ['desc'])
        break;
      case 'freeTrials':
        filteredClubs = _.orderBy(filteredClubs, [ 'offers_free_trial'], ['desc'])
        break;
      default:
        break;
    }
    // const clubsList = [];
    const clubsList = _.map(filteredClubs, (club, index) => {
      return (
        <div key={club.id}>
          <ResultCard club={club} onShowChat={() => {this.showChat(club.title)}} />
        </div>
      );
    });


    const course_selected = filters.clubs_that_play_course.length
      ? filters.clubs_that_play_course[0]
      : "";

    const clubs_that_play_str =
      clubs.length === 1 ? "club that plays at " : "clubs that play at ";
    const courseObj = _.find(facilities, { id: parseInt(course_selected) });

    console.log(
      "Results::render: course_selected: ",
      course_selected,
      courseObj,
      this.props.hotLink
    );

    let resultsTitle = courseObj
      ? clubs_that_play_str + courseObj.title
      : this.props.hotLink && this.props.hotLink != "byclub"
      ? this.props.hotLink + " clubs near you"
      : clubs.length === 1
      ? "Recommended Club"
      : "communities near you";
    if (this.props.region) {
      const regionName = this.props.region.replace(/-/, " ");
      resultsTitle = "communities in " + regionName;
    }
    if (this.props.city) {
      const city = this.props.city;
      resultsTitle = "communities in " + city;
    }
    if (this.props.searchSimilar) {
      console.log("Results::render: searchSimilar: ", this.props.searchSimilar);
      const club = clubs.find(
        club => club.id === parseInt(this.props.searchSimilar)
      );
      resultsTitle = "clubs similar to " + club.title;
    }

    const options = [
      { label: 'Recommended', value: 'recommended' },
      { label: 'Distance', value: 'distance' },
      { label: 'Popularity', value: 'popularity' },
      { label: 'Free Trials', value: 'freeTrials' },
    ]

    if(window.LC_API.on_before_load) {
      window.LC_API.on_before_load = function() {
        console.log('LC_API: before', window.LC_API);
        window.LC_API.hide_chat_window();
        
      }
    }
    if(window.LC_API.on_after_load) {
      window.LC_API.on_after_load = function() {
        console.log('LC_API: ready', window.LC_API);
        window.LC_API.hide_chat_window();
        // window.LC_API.open_chat_window();
      };
    }

    



    console.log(
      "Results::render: clubs after filter: ",
      filteredClubs, clubs, tags, parsed
    );
    if(this.props.selected) {
      console.log('dsfadf::selected', this.props.selectedClub);
    }
    
    return (
      <div className="container-fluid content">
        <Link
          className="back-to-results"
          onClick={this.handleBack}
          style={{ display: this.props.selected ? "block" : "none" }}
        >
          <i className="far fa-arrow-left" /> back to Search Results
        </Link>
        {/* <div style={{ width: '300px', margin: '10px 0px' }}>
          <select className="minimal" onChange={this.handleSelect} >
            {_.map(options, (option) => {
              return (
                <option value={option.value}>{option.label}</option>
              );
            })}
          </select>
        </div> */}
        {/* <div
          className={
            this.props.map_compressed
              ? "row count-row count-row--active"
              : "row count-row"
          }
        >
          <div className="col-12">
            <i
              id="mobile-map-trigger"
              className={
                this.props.map_compressed
                  ? "fas fa-caret-left fa-2x"
                  : "fas fa-caret-down fa-2x"
              }
              onClick={this.onHandleMapCaretClick.bind(this)}
            />
            <p className="headings">
              <span className="count">
                {this.props.selected ? "" : clubs.length}{" "}
              </span>{" "}
              {this.props.selected
                ? this.props.selectedClub.title
                : resultsTitle}
            </p>
            <MapContainer clubs={clubs} latlng={loc} />
          </div>
        </div> */}

        <div className="row results-row" style={{marginTop: '25px'}} id="app">
          <div
            className="col col-md-4 col-sm-12 options opt-panel-wrap"
            id="opt-panel-results"
            style={
              this.props.show_filters ? { bottom: "0px" } : { bottom: "200%" }
            }
          >
            <p className="headings">
              <span>OPTIONS</span>
            </p>
            <div className="opt-panel__header">
              <h3>OPTIONS</h3>
              <div className="options-controls">
                <i
                  className="far fa-times fa-2x"
                  onClick={this.props.onHideFilters}
                />
                <i
                  className="far fa-check fa-2x opt-panel-close--active"
                  onClick={this.props.onHideFilters}
                />
              </div>
            </div>
            <div className="opt-panel__results-str">
              <span>
                <span id="results-count" /> matching results
              </span>
            </div>
            {clubs.length && <Filters clubs={clubs} />}
          </div>
          <div
            className="col"
            id="this-was-new"
            style={{
              order: 2,
              flex: "1 1 80%",
              marginTop: "-5px",
              padding: "0px 0px 0px 8px"
            }}
          >
            <div
              className={
                this.props.map_compressed
                  ? "row count-row count-row--active"
                  : "row count-row"
              }
            >
              <div className="col-12">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p className="headings">
                    <span className="count">
                      {this.props.selected ? "" : clubs.length}{" "}
                    </span>{" "}
                    {this.props.selected
                      ? this.props.selectedClub.title
                      : resultsTitle}
                  </p>
                  <i
                    id="mobile-map-trigger"
                    className={
                      this.props.map_compressed
                        ? "fas fa-caret-left fa-2x"
                        : "fas fa-caret-down fa-2x"
                    }
                    onClick={this.onHandleMapCaretClick.bind(this)}
                  />
                </div>

                <MapContainer clubs={clubs} latlng={loc} />
              </div>
            </div>
            <div className="row" style={{ marginTop: "35px", padding: "6px" }}>
              {!this.props.show_filters && (
                <div id="results_featured-club">
                  <FeaturedClub featured={this.props.featuredClub} />
                  <Events />
                </div>
              )}
              
              {!this.props.show_filters && (
                <div className="col results" style={{marginTop: '-32px'}}>
                  <div style={{ float: 'left' }}>
                    {_.map(parsed, (tag, key) => {
                      return (
                      <span style={{margin: '0px 1px'}} className="badge badge-primary">{tag}<span onClick={() => this.removeTag(key, tag)} className="modal-tag-close">x</span></span>
                      )
                    })}
                  </div>
                  <div style={{ width: '240px', margin: '0px', float: 'right', color: '#005077' }}>
                    SORT BY: <select className="minimal" onChange={this.handleSelect} >
                      {_.map(options, (option) => {
                        return (
                          <option value={option.value}>{option.label}</option>
                        );
                      })}
                    </select>
                  </div>
                  {this.props.selected && (
                    <div>
                      <ResultCard
                        club={this.props.selectedClub}
                        mileIndex={mileIndex}
                        expanded={true}
                        onShowChat={this.showChat}
                      />
                      <p className={"headings"}>Nearby clubs</p>
                    </div>
                  )}
                  {clubsList}
                </div>
              )}
            </div>
          </div>
          {/*don't show featured or events if mobile and show_filters mode */}
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    center: state.center,
    city: state.city,
    clubs: state.clubs,
    events: state.events,
    featuredClub: state.featuredClub,
    facilities: state.facilities,
    filtersSet: state.filtersSet,
    geoLocation: state.geoLocation,
    myLocation: state.myLocation,
    hotLink: state.hotLink,
    locationSet: state.locationSet,
    map_compressed: state.map_compressed,
    max_cost: state.max_cost,
    query_string: state.query_string,
    radius: state.radius,
    region: state.region,
    searchSimilar: state.searchSimilar,
    searchType: state.searchType,
    selected: state.selected,
    selectedClub: state.selectedClub,
    show_filters: state.show_filters,
    showOverlay: state.showOverlay,
    zoom: state.zoom
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onChangeRadius: evt =>
      dispatch({ type: "CHANGE_RADIUS", value: evt.value }),
    onHideFilters: () => dispatch({ type: "HIDE_FILTER_OPTIONS" }),
    onSetZoom: (center, zoom) =>
      dispatch({
        type: "SET_MAP_CENTER_ZOOM",
        payload: { center: center, zoom: zoom }
      }),
    onFilterChange: filtersSet =>
      dispatch({ type: "CHANGE_FILTER", value: filtersSet }),
    onSetOverlay: show => dispatch({ type: "SET_MAP_OVERLAY", value: show }),
    onSetMapCompressed: compressed =>
      dispatch({ type: "SET_MAP_COMPRESSED", value: compressed }),
    onChangeSearchType: () =>
      dispatch({ type: "CHANGE_SEARCHTYPE", value: "location" })
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Results)
);
