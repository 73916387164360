import React from "react";
import Select, { components } from "react-select";
import { withRouter } from "react-router-dom";
import ByClubIcon from "./ByClubIcon";
import Geocode from "react-geocode";
import { connect } from "react-redux";
import queryString from "query-string";

class ClubSearch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: this.props.selected,
      currentSelectedValue: {
        value: this.props.selectedClub.url_title,
        label: this.props.selectedClub.title
      }
    };

    this.handleSelect = this.handleSelect.bind(this);
    this.handleChangeSearchType = this.handleChangeSearchType.bind(this);
    this.handleButtonClick = this.handleButtonClick.bind(this);
  }

  componentDidMount() {
    const { location } = this.props.history;
    const parsed = queryString.parse(location.search);

    Geocode.setApiKey("AIzaSyCW7uslsp1hjVGQOXwxbFvc_-3bYwArPMU");
    console.log(
      "%c Clubsearch componentDidMount...",
      "color: blue; font-weight: bold;",
      this.props
    );
    // window.scrollTo(0, 150)

    /*     if (parsed.club) {
      this.setState({ currentSelectedValue: parsed.club });
      this.props.onSelectClub(parsed.club);
    } */

    //copied from LocationSearch
    if (this.props.locationSet && !!this.props.searchString) {
      console.log(
        "%c ClubSearch::compDidMnt:Geocode props.searchstring: ",
        "color: blue; font-weight: bold;",
        this.props.searchString
      );
      // this.props.onSelectGooglePlace(this.props.searchString, this.props.radius)
      Geocode.fromAddress(this.props.searchString).then(
        response => {
          if (parsed.club) {
            this.setState({ currentSelectedValue: parsed.club });
            const selectedClub = this.props.clubs.find(
              club => club.url_title === parsed.club
            );
            //this.props.onSelectClub(parsed.club);
          }

          this.props.onChangeSearchString(
            this.props.searchString,
            response.results[0].geometry.location
          );
        },
        error => {
          console.log("Error getting geocode from address: ", error);
        }
      );
    }

    if (parsed.lat && parsed.lng) {
      console.log(
        "%c ClubSearch::compDidMnt: parsed lat lng",
        "color: blue; font-weight: bold;",
        this.props,
        parsed
      );
      Geocode.fromLatLng(parsed.lat, parsed.lng).then(response => {
        const address = response.results[0].formatted_address;
        this.props.onChangeSearchString(
          address,
          response.results[0].geometry.location
        );
        this.setState({
          address: address,
          searchString: address
        });
      });
    } else {
      const { location } = this.props.history;
      const parsed = queryString.parse(location.search);
      const address = parsed.location || "Los Angeles, CA, USA";
      console.log(
        "%c LocationSearch::compDidMnt: location not allowed...",
        "color: red; font-weight: bold;",
        address
      );
      this.setState({ address: address });
    }
    //end copy
  }

  handleSelect(selected_club) {
    console.log("Clubsearch::handleSelect: selectedclub: ", selected_club);
    const selectedClub = this.props.clubs.find(
      club => club.url_title === selected_club.value
    );
    // const selected = this.props.clubs.filter(club => {return club.id === selected.value} )[0];
    console.log("ClubSearch::selected: ", selectedClub);
    this.setState({ currentSelectedValue: selected_club });
    this.props.onSelectClub(selectedClub);
    this.props.history.push(
      `/byClub?club=${selectedClub.url_title}&lat=${selectedClub.latitude}&lng=${selectedClub.longitude}`
    );
    window.scrollTo(0, 150);
  }
  handleChangeSearchType(type) {
    this.props.onChangeSearchType(type);
    // this.props.history.push("/byLocation");
  }

  handleButtonClick() {
    console.log("selectedClub: ", this.props.selectedClub);
    this.props.onSelectClub(this.props.selectedClub);
  }

  render() {
    const { location } = this.props.history;
    const { clubs = [] } = this.props;
    const parsed = queryString.parse(location.search);
    const value = this.props.selected
      ? {
          value: this.props.selectedClub.url_title,
          label: this.props.selectedClub.title
        }
      : parsed.club || "";
    // const value = this.state.currentSelectedValue;
    console.log("ClubSearch::render: value: ", value);
    const { Option } = components;
    const IconOption = props => (
      <Option {...props}>
        <ByClubIcon />
        {props.data.label}
      </Option>
    );
    // create options from clubs with slug and title
    const options = clubs.map(club => {
      return { value: club.url_title, label: club.title };
    });
    // console.log(options)
    const selectStyles = {
      control: (base, state) => ({
        ...base,
        marginTop: 0,
        borderRadius: "none",
        overflow: "none"
        // marginLeft: "20px",
      })
    };
    const pipe_symbol = (
      <span
        className={
          this.props.locationSet
            ? "link-label_pipe_symbol__results"
            : "link-label_pipe_symbol"
        }
      >
        {" "}
        |{" "}
      </span>
    );

    return (
      <div className="search-input-row">
        <div className="link-label">
          <button
            className="search-label"
            onClick={() => { this.handleChangeSearchType("location") }}
          >
            {" "}
            Find my Community{" "}
          </button>
          <button id="search-location" className="search-label active">
            by Club
          </button>
          <button
            className="search-label"
            onClick={() => { this.handleChangeSearchType("course") }}
          >
            {" "}
            by Course{" "}
          </button>
        </div>
        <div className="input-row">
          <div className="input-with-icon">
            <div className="form-group__icon form-group__icon--golf-course" />
            <Select
              id={"find-club"}
              value={value}
              classNamePrefix={"react-select"}
              options={options}
              styles={selectStyles}
              placeholder={
                <div className={"byClubPlaceholder"}>Enter a Club Name</div>
              }
              onChange={this.handleSelect}
              components={{ Option: IconOption }}
              autoFocus
            />
            <span
              id="mobile-search-btn"
              className="input-group-btn input-group-btn-mobile"
            >
              <button type="submit" className="btn btn-primary flat">
                <i className="far fa-search" />
              </button>
            </span>
            <span
              id="mobile-options-btn"
              className="input-group-btn input-group-btn-mobile"
            >
              <span className="btn btn-primary flat">
                <i className="far fa-sliders-v" />
              </span>
            </span>
          </div>
          <span className="input-group-btn">
            <button
              className="btn btn-primary flat"
              onClick={this.handleButtonClick}
            >
              <i className="far fa-search" />
            </button>
          </span>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    clubs: state.clubs,
    geoLocation: state.geoLocation,
    searchType: state.searchType,
    searchString: state.searchString,
    selectedClub: state.selectedClub,
    selected: state.selected,
    myLocation: state.myLocation,
    locationSet: state.locationSet,
    allowed_location: state.allowed_location
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onChangeSearchType: (type) =>
      dispatch({ type: "CHANGE_SEARCHTYPE", value: type }),
    onSelectClub: club => dispatch({ type: "SELECT_BY_CLUB", club: club }),
    onChangeSearchString: (address, geoLocation) =>
      dispatch({
        type: "CHANGE_SEARCHSTRING",
        payload: { address: address, geoLocation: geoLocation }
      })
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ClubSearch)
);
