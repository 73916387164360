const featuredClubs = [{
	"id": 3125,
	"title": "Latina\/o Golfers Golf Club",
	"url_title": "the-lga-divas",
	"entry_date": "2010-12-02 14:28:29",
	"entry_id": 3939,
	"region_id": 7,
	"eligible": 1,
	"club_status_id": 2,
	"latitude": "33.83489280",
	"longitude": "-117.91556220",
	"feature_video_url": "https:\/\/www.youtube.com\/embed\/aIhprbJUCMc?rel=0",
	"address2_line1": "174 W. Lincoln Ave. #255",
	"address2_line2": "",
	"address2_city": "Anaheim",
	"address2_stateorprovince": "CA",
	"address2_postalcode": "92805",
	"address2_line3": "",
	"telephone1": "(213) 804-8091",
	"emailaddress1": "",
	"new_greenfees": "50.00",
	"new_clasificationtype": "8",
	"facility_featured": "no",
	"new_scga_displayname": "Latina\/o Golfers Golf Club",
	"new_yearestablished": "",
	"new_scgahidecontactemail": 0,
	"new_scgaopenformembership": 1,
	"websiteurl": "http:\/\/latinagolfers.com",
	"facility_latitude": "33.83489280",
	"facility_longitude": "-117.91556220",
	"facility_formatted_address": "174 W Lincoln Ave #255, Anaheim, CA 92805, USA",
	"course_facility": 0,
	"club_facility": 0,
	"description": "<div class=\"embed-responsive embed-responsive-16by9\">\n  <iframe class=\"embed-responsive-item\" src=\"https:\/\/www.youtube.com\/embed\/aIhprbJUCMc\" allowfullscreen><\/iframe>\n<\/div>\n<br>\nThe LGA eliminates the intimidation factor surrounding the game of golf. Our goal is to make golf accessible to women by conducting affordable golf clinics and lessons for newbies and beginner\/intermediate players. We empower women by teaching them how to utilize golf as a business tool in order to enhance their careers and personal lives.",
	"new_scgapromotionaltext": "The Latina Golfers Association empowers women by teaching them how to utilize golf as a business tool. We organize and conduct golf clinics, golf lessons, and golf tournaments for women. We welcome golfers of all levels including newbies.",
	"address1_line1": "174 W. Lincoln Ave. #255",
	"address1_line2": "",
	"address1_line3": "",
	"address1_city": "Anaheim",
	"address1_stateorprovince": "CA",
	"address1_postalcode": "92805",
	"online_app": 0,
	"clublink": "",
	"new_scga_allowonlineregistration": 0,
	"new_scga_joinnowurl": "https:\/\/membership.scga.org\/start\/join\/?cid=1199",
	"new_scga_renewnowurl": "",
	"new_scga_allowsonlinesignup": 1,
	"club_image": "",
	"new_remotehandicappingsystemkey": "007331250001",
	"club_facility_relationship": 0,
	"club_site_logo": "https:\/\/scga.s3.us-west-2.amazonaws.com\/logos\/the-lga-divas\/jiOvfnCTIqSwAqtjPwF9evMvcqp3RuW04m3BiZuP.jpeg",
	"club_header_image": null,
	"last_member_import": "2020-02-25 15:04:18",
	"local_description": null,
	"local_scgapromotionaltext": "<div class=\"embed-responsive embed-responsive-16by9\">\n  <iframe class=\"embed-responsive-item\" src=\"https:\/\/www.youtube.com\/embed\/aIhprbJUCMc\" allowfullscreen><\/iframe>\n<\/div>\n<br>\nThe LGA eliminates the intimidation factor surrounding the game of golf. Our goal is to make golf accessible to women by conducting affordable golf clinics and lessons for newbies and beginner\/intermediate players. We empower women by teaching them how to utilize golf as a business tool in order to enhance their careers and personal lives.",
	"address1_county": "ORANGE",
	"featured_on_home": "no",
	"geolocation_update_address2": "yes",
	"member_count": 15,
	"contact_name": "Natalie Cabrera",
	"contact_email": "ncabrera2737@yahoo.com",
	"allow_associate_membership": 0,
	"is_senior_club": 0,
	"age_id": 6,
	"updated_at": "2020-10-16 00:00:13",
	"created_at": "2017-01-13 04:38:09",
	"last_import_action": "updated",
	"last_import_date": "2020-10-16 00:00:13",
	"classification_id": 8,
	"offers_free_trial": 0,
	"video_id": "aIhprbJUCMc",
	"featured_image_url": "https:\/\/scga.s3.us-west-2.amazonaws.com\/featured_image\/nBrTklCTFxFeYFhV9Ypv4bo8qQ6XZweuuYLP9iRv.png",
	"wePlay": {
		"saturday": "SAT",
		"sunday": "SUN"
	},
	"play_days": ["saturday", "sunday"],
	"nextEvent": null,
	"learnMoreUrl": "\/findByClubName?active=club&club_id=3125",
	"levels": ["beginners", "high-handicaps"],
	"tags": [{
		"name": "Travel",
		"description": "hey"
	}, {
		"name": "Fun\/Social Golf",
		"description": ""
	}, {
		"name": "Introducing People to the Game",
		"description": ""
	}, {
		"name": "Beginners",
		"description": ""
	}, {
		"name": "High Handicaps (20+)",
		"description": ""
	}, {
		"name": "Men",
		"description": ""
	}, {
		"name": "Women",
		"description": ""
	}, {
		"name": "English",
		"description": ""
	}, {
		"name": "Spanish",
		"description": ""
	}],
	"age": "36-55",
	"recommended": true,
	"whys": ["fun-social-golf", "introducing-people-to-the-game"],
	"club_types": ["travel"],
	"game_styles": ["social-non-competitive", "events-for-beginners"],
	"member_types": ["beginners"],
	"welcome_genders": ["male", "female"],
	"langs": ["english", "spanish"],
	"skill_levels": [{
		"id": 5,
		"name": "Beginners",
		"slug": "beginners",
		"created_at": "2017-10-09 09:15:08",
		"updated_at": "2017-10-09 09:15:08",
		"pivot": {
			"club_id": 3125,
			"skill_level_id": 5
		}
	}, {
		"id": 7,
		"name": "High Handicaps (20+)",
		"slug": "high-handicaps",
		"created_at": "2017-10-09 09:17:15",
		"updated_at": "2017-10-09 15:08:29",
		"pivot": {
			"club_id": 3125,
			"skill_level_id": 7
		}
	}],
	"club_events": [{
		"id": 838,
		"name": "Clinic & 9 Hole Shotgun",
		"description": "",
		"event_date": "2018-04-07 12:30:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 3125,
		"is_tournament": 0,
		"cost": "40.00",
		"registration_url": "https:\/\/www.eventbrite.com\/e\/9-hole-shotgun-start-golf-outing-all-levels-welcome-tickets-43071259296",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 828,
		"created_at": "2018-03-09 13:21:37",
		"updated_at": "2018-03-09 13:21:37"
	}, {
		"id": 839,
		"name": "Networking Tournament",
		"description": "",
		"event_date": "2018-09-29 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 3125,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 859,
		"created_at": "2018-03-09 13:23:34",
		"updated_at": "2018-03-09 13:23:34"
	}, {
		"id": 840,
		"name": "Mother\/Daughter Golf Clinic",
		"description": "",
		"event_date": "2018-03-17 09:30:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 3125,
		"is_tournament": 1,
		"cost": "20.00",
		"registration_url": "https:\/\/www.eventbrite.com\/e\/motherdaughter-golf-clinic-auntsnieces-grandmothersgranddaughters-tickets-42447941937",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 800,
		"created_at": "2018-03-09 13:24:49",
		"updated_at": "2018-03-09 13:24:49"
	}, {
		"id": 4872,
		"name": "Mimosa Brunch & Golf",
		"description": "",
		"event_date": "2020-04-04 11:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 3125,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 751,
		"created_at": "2020-02-25 15:05:40",
		"updated_at": "2020-02-25 15:05:40"
	}, {
		"id": 4873,
		"name": "Golf Fiesta",
		"description": "",
		"event_date": "2020-05-30 18:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 3125,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 751,
		"created_at": "2020-02-25 15:06:46",
		"updated_at": "2020-02-25 15:07:50"
	}, {
		"id": 4874,
		"name": "Monthly Outing",
		"description": "",
		"event_date": "2020-03-14 13:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 3125,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 733,
		"created_at": "2020-02-25 15:10:32",
		"updated_at": "2020-02-25 15:10:32"
	}, {
		"id": 4875,
		"name": "Brunch & Golf Bootcamp",
		"description": "",
		"event_date": "2020-03-21 11:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 3125,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1057,
		"created_at": "2020-02-25 15:11:25",
		"updated_at": "2020-02-25 15:11:25"
	}, {
		"id": 4876,
		"name": "Golf Clinic for Beginners",
		"description": "",
		"event_date": "2020-03-22 13:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 3125,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 835,
		"created_at": "2020-02-25 15:11:59",
		"updated_at": "2020-02-25 15:11:59"
	}, {
		"id": 4877,
		"name": "Learn How to Play in a Charity Tournament",
		"description": "",
		"event_date": "2020-04-19 13:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 3125,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 775,
		"created_at": "2020-02-25 15:13:07",
		"updated_at": "2020-02-25 15:13:07"
	}],
	"club_benefits": [],
	"average_age": {
		"id": 6,
		"name": "36-55",
		"slug": "36-55",
		"created_at": "2017-10-09 11:32:35",
		"updated_at": "2017-10-18 09:02:36"
	},
	"region": {
		"id": 7,
		"name": "Orange County",
		"slug": "orange-county",
		"display_order": 3,
		"created_at": "2017-02-28 20:14:42",
		"updated_at": "2017-03-04 07:06:56",
		"created_by": 205
	},
	"wheres": [{
		"id": 3,
		"name": "Travel",
		"slug": "travel",
		"created_at": "2017-09-27 08:18:09",
		"updated_at": "2017-09-27 08:18:09",
		"pivot": {
			"club_id": 3125,
			"where_id": 3
		}
	}],
	"why": [{
		"id": 8,
		"name": "Fun\/Social Golf",
		"slug": "fun-social-golf",
		"created_at": "2017-10-05 14:54:09",
		"updated_at": "2017-11-20 11:02:08",
		"pivot": {
			"club_id": 3125,
			"why_id": 8
		}
	}, {
		"id": 11,
		"name": "Introducing People to the Game",
		"slug": "introducing-people-to-the-game",
		"created_at": "2017-10-05 14:55:11",
		"updated_at": "2017-11-28 08:57:36",
		"pivot": {
			"club_id": 3125,
			"why_id": 11
		}
	}],
	"we_play_days": [{
		"id": 6,
		"name": "Saturday",
		"slug": "saturday",
		"short_name": "Sat",
		"created_at": "2017-09-26 15:54:55",
		"updated_at": "2017-09-26 15:54:55",
		"pivot": {
			"club_id": 3125,
			"we_play_day_id": 6
		}
	}, {
		"id": 7,
		"name": "Sunday",
		"slug": "sunday",
		"short_name": "Sun",
		"created_at": "2017-09-26 15:54:59",
		"updated_at": "2017-09-26 15:54:59",
		"pivot": {
			"club_id": 3125,
			"we_play_day_id": 7
		}
	}],
	"club_status": {
		"id": 2,
		"name": "Preferred",
		"slug": "preferred",
		"display_order": 2
	},
	"event_types": [{
		"id": 9,
		"name": "Social (Non-Competitive)",
		"slug": "social-non-competitive",
		"created_at": "2017-10-09 14:42:37",
		"updated_at": "2017-10-09 14:45:29",
		"pivot": {
			"club_id": 3125,
			"event_type_id": 9
		}
	}, {
		"id": 15,
		"name": "Events for Beginners",
		"slug": "events-for-beginners",
		"created_at": "2017-10-09 14:43:50",
		"updated_at": "2017-10-09 14:43:50",
		"pivot": {
			"club_id": 3125,
			"event_type_id": 15
		}
	}],
	"golfer_types": [{
		"id": 4,
		"name": "Beginners",
		"description": "Noobies of all ages",
		"slug": "beginners",
		"created_at": "2017-03-04 18:29:06",
		"updated_at": "2017-03-04 18:29:06",
		"created_by": null,
		"pivot": {
			"club_id": 3125,
			"golfer_type_id": 4
		}
	}],
	"languages": [{
		"id": 1,
		"name": "English",
		"slug": "english",
		"created_at": "2017-09-26 15:27:42",
		"updated_at": "2017-09-26 15:27:42",
		"pivot": {
			"club_id": 3125,
			"language_id": 1
		}
	}, {
		"id": 2,
		"name": "Spanish",
		"slug": "spanish",
		"created_at": "2017-09-26 15:28:47",
		"updated_at": "2017-09-26 15:28:47",
		"pivot": {
			"club_id": 3125,
			"language_id": 2
		}
	}]
}, {
	"id": 2929,
	"title": "Tiny Putters Golf",
	"url_title": "tiny-putters-golf-league-scga",
	"entry_date": "2016-03-29 14:38:15",
	"entry_id": 9783,
	"region_id": 10,
	"eligible": 1,
	"club_status_id": 2,
	"latitude": "34.02020000",
	"longitude": "-118.46693500",
	"feature_video_url": null,
	"address2_line1": "2065 1\/2 Ivar Ave",
	"address2_line2": "",
	"address2_city": "Los Angeles",
	"address2_stateorprovince": "CA",
	"address2_postalcode": "90068",
	"address2_line3": "",
	"telephone1": "(508) 415-9969",
	"emailaddress1": "",
	"new_greenfees": "70.00",
	"new_clasificationtype": "3",
	"facility_featured": "no",
	"new_scga_displayname": "Tiny Putters Golf",
	"new_yearestablished": "",
	"new_scgahidecontactemail": 0,
	"new_scgaopenformembership": 1,
	"websiteurl": "",
	"facility_latitude": "34.10897790",
	"facility_longitude": "-118.32713840",
	"facility_formatted_address": "2141 1\/2 Ivar Ave, Los Angeles, CA 90068, USA",
	"course_facility": 0,
	"club_facility": 0,
	"description": "<div class=\"embed-responsive embed-responsive-16by9\">\n  <iframe class=\"embed-responsive-item\" src=\"https:\/\/www.youtube.com\/embed\/qEZraoCOuZ0\" allowfullscreen><\/iframe>\n<\/div>\n<br>\n<br>\n\nTiny Putters Golf League is a social club for anybody who wants to swing the sticks, crack a beer and enjoy one of the greatest games on earth. We want the league to always be comprised of golfers of every skill level. No matter your handicap you\u2019ll always have a great time.",
	"new_scgapromotionaltext": "Tiny Putters Golf League is a social club for anybody who wants to swing the sticks, crack a beer and enjoy one of the greatest games on earth.  We want the league to always be comprised of golfers of every skill level.  No matter your handicap you\u2019ll always have a great time.",
	"address1_line1": "2065 1\/2 Ivar Ave",
	"address1_line2": "",
	"address1_line3": "",
	"address1_city": "Los Angeles",
	"address1_stateorprovince": "CA",
	"address1_postalcode": "90068",
	"online_app": 0,
	"clublink": "",
	"new_scga_allowonlineregistration": 0,
	"new_scga_joinnowurl": "https:\/\/membership.scga.org\/start\/join\/?cid=1070",
	"new_scga_renewnowurl": "",
	"new_scga_allowsonlinesignup": 1,
	"club_image": "",
	"new_remotehandicappingsystemkey": "007329290001",
	"club_facility_relationship": 0,
	"club_site_logo": null,
	"club_header_image": null,
	"last_member_import": "2020-02-11 14:13:49",
	"local_description": null,
	"local_scgapromotionaltext": "<div class=\"embed-responsive embed-responsive-16by9\">\n  <iframe class=\"embed-responsive-item\" src=\"https:\/\/www.youtube.com\/embed\/qEZraoCOuZ0\" allowfullscreen><\/iframe>\n<\/div>\n<br>\n<br>\n\nTiny Putters Golf League is a social club for anybody who wants to swing the sticks, crack a beer and enjoy one of the greatest games on earth. We want the league to always be comprised of golfers of every skill level. No matter your handicap you\u2019ll always have a great time.",
	"address1_county": "Los Angeles",
	"featured_on_home": "no",
	"geolocation_update_address2": "yes",
	"member_count": 174,
	"contact_name": "Austin Keneshea",
	"contact_email": "akeneshea@gmail.com",
	"allow_associate_membership": 0,
	"is_senior_club": 0,
	"age_id": 7,
	"updated_at": "2020-10-16 00:00:18",
	"created_at": "2017-01-13 04:38:14",
	"last_import_action": "updated",
	"last_import_date": "2020-10-16 00:00:18",
	"classification_id": 3,
	"offers_free_trial": 0,
	"video_id": "qEZraoCOuZ0",
	"featured_image_url": "https:\/\/scga.s3.us-west-2.amazonaws.com\/featured_image\/3Nlbjk3uOEUL3BdYLJg7vag0qfRF8qulxYYLd9ZN.png",
	"wePlay": {
		"saturday": "SAT",
		"sunday": "SUN"
	},
	"play_days": ["saturday", "sunday"],
	"nextEvent": null,
	"learnMoreUrl": "\/findByClubName?active=club&club_id=2929",
	"levels": ["average-handicaps", "single-digit-handicaps"],
	"tags": [{
		"name": "Travel",
		"description": "hey"
	}, {
		"name": "Competition",
		"description": ""
	}, {
		"name": "Fun\/Social Golf",
		"description": ""
	}, {
		"name": "Average Handicaps (10-20)",
		"description": ""
	}, {
		"name": "Single Digit Handicaps",
		"description": ""
	}, {
		"name": "Men",
		"description": ""
	}, {
		"name": "Women",
		"description": ""
	}, {
		"name": "English",
		"description": ""
	}, {
		"name": "Spanish",
		"description": ""
	}],
	"age": "18-35",
	"recommended": true,
	"whys": ["competition", "fun-social-golf"],
	"club_types": ["travel"],
	"game_styles": ["competitive-net", "competitive-gross", "team-events", "skins-games", "league", "member-guest"],
	"member_types": [],
	"welcome_genders": ["male", "female"],
	"langs": ["english", "spanish"],
	"skill_levels": [{
		"id": 8,
		"name": "Average Handicaps (10-20)",
		"slug": "average-handicaps",
		"created_at": "2017-10-09 09:17:34",
		"updated_at": "2017-10-09 15:08:46",
		"pivot": {
			"club_id": 2929,
			"skill_level_id": 8
		}
	}, {
		"id": 9,
		"name": "Single Digit Handicaps",
		"slug": "single-digit-handicaps",
		"created_at": "2017-10-09 09:17:46",
		"updated_at": "2017-10-09 09:17:46",
		"pivot": {
			"club_id": 2929,
			"skill_level_id": 9
		}
	}],
	"club_events": [{
		"id": 2744,
		"name": "Moneyball Tournament",
		"description": "",
		"event_date": "2019-03-09 09:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 2929,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 791,
		"created_at": "2019-02-21 11:06:59",
		"updated_at": "2019-02-21 11:06:59"
	}, {
		"id": 2745,
		"name": "The Gambino Open",
		"description": "",
		"event_date": "2019-05-19 09:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 2929,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 787,
		"created_at": "2019-02-21 11:07:28",
		"updated_at": "2019-02-21 11:07:28"
	}],
	"club_benefits": [{
		"id": 196,
		"name": "Discounted tee times",
		"description": null,
		"order": 0,
		"created_at": "2017-12-08 15:57:44",
		"updated_at": "2017-12-08 15:57:44",
		"created_by": 172,
		"club_id": 2929
	}, {
		"id": 198,
		"name": "A boosted sense of satisfaction in your own golf game",
		"description": null,
		"order": 0,
		"created_at": "2017-12-08 15:58:09",
		"updated_at": "2017-12-08 15:58:09",
		"created_by": 172,
		"club_id": 2929
	}, {
		"id": 199,
		"name": "A custom* tailored green jacket for our league's Masters champion (custom only for those able to fit into a 40R)",
		"description": null,
		"order": 0,
		"created_at": "2017-12-08 15:58:22",
		"updated_at": "2017-12-08 15:58:22",
		"created_by": 172,
		"club_id": 2929
	}, {
		"id": 200,
		"name": "A new appreciation for the first tee Bloody Mary",
		"description": null,
		"order": 0,
		"created_at": "2017-12-08 15:58:34",
		"updated_at": "2017-12-08 15:58:34",
		"created_by": 172,
		"club_id": 2929
	}, {
		"id": 201,
		"name": "14\" increase on your vertical jump",
		"description": null,
		"order": 0,
		"created_at": "2017-12-08 15:58:43",
		"updated_at": "2017-12-08 15:58:43",
		"created_by": 172,
		"club_id": 2929
	}, {
		"id": 202,
		"name": "4% discount at Denny's",
		"description": null,
		"order": 0,
		"created_at": "2017-12-08 15:58:54",
		"updated_at": "2017-12-08 15:58:54",
		"created_by": 172,
		"club_id": 2929
	}],
	"average_age": {
		"id": 7,
		"name": "18-35",
		"slug": "18-35",
		"created_at": "2017-10-09 11:32:51",
		"updated_at": "2017-10-18 09:02:43"
	},
	"region": {
		"id": 10,
		"name": "Greater Los Angeles",
		"slug": "greater-los-angeles",
		"display_order": 1,
		"created_at": "2017-03-04 04:20:04",
		"updated_at": "2017-03-06 20:31:20",
		"created_by": 3975
	},
	"wheres": [{
		"id": 3,
		"name": "Travel",
		"slug": "travel",
		"created_at": "2017-09-27 08:18:09",
		"updated_at": "2017-09-27 08:18:09",
		"pivot": {
			"club_id": 2929,
			"where_id": 3
		}
	}],
	"why": [{
		"id": 7,
		"name": "Competition",
		"slug": "competition",
		"created_at": "2017-10-05 14:53:43",
		"updated_at": "2017-10-05 14:53:43",
		"pivot": {
			"club_id": 2929,
			"why_id": 7
		}
	}, {
		"id": 8,
		"name": "Fun\/Social Golf",
		"slug": "fun-social-golf",
		"created_at": "2017-10-05 14:54:09",
		"updated_at": "2017-11-20 11:02:08",
		"pivot": {
			"club_id": 2929,
			"why_id": 8
		}
	}],
	"we_play_days": [{
		"id": 6,
		"name": "Saturday",
		"slug": "saturday",
		"short_name": "Sat",
		"created_at": "2017-09-26 15:54:55",
		"updated_at": "2017-09-26 15:54:55",
		"pivot": {
			"club_id": 2929,
			"we_play_day_id": 6
		}
	}, {
		"id": 7,
		"name": "Sunday",
		"slug": "sunday",
		"short_name": "Sun",
		"created_at": "2017-09-26 15:54:59",
		"updated_at": "2017-09-26 15:54:59",
		"pivot": {
			"club_id": 2929,
			"we_play_day_id": 7
		}
	}],
	"club_status": {
		"id": 2,
		"name": "Preferred",
		"slug": "preferred",
		"display_order": 2
	},
	"event_types": [{
		"id": 7,
		"name": "Competitive (Net)",
		"slug": "competitive-net",
		"created_at": "2017-10-09 14:35:43",
		"updated_at": "2017-10-09 14:45:51",
		"pivot": {
			"club_id": 2929,
			"event_type_id": 7
		}
	}, {
		"id": 8,
		"name": "Competitive (Gross)",
		"slug": "competitive-gross",
		"created_at": "2017-10-09 14:42:21",
		"updated_at": "2017-10-09 14:45:37",
		"pivot": {
			"club_id": 2929,
			"event_type_id": 8
		}
	}, {
		"id": 10,
		"name": "Team Events",
		"slug": "team-events",
		"created_at": "2017-10-09 14:42:46",
		"updated_at": "2017-10-09 14:42:46",
		"pivot": {
			"club_id": 2929,
			"event_type_id": 10
		}
	}, {
		"id": 11,
		"name": "Skins Games",
		"slug": "skins-games",
		"created_at": "2017-10-09 14:42:55",
		"updated_at": "2017-10-09 14:42:55",
		"pivot": {
			"club_id": 2929,
			"event_type_id": 11
		}
	}, {
		"id": 12,
		"name": "League",
		"slug": "league",
		"created_at": "2017-10-09 14:43:26",
		"updated_at": "2017-10-09 14:43:26",
		"pivot": {
			"club_id": 2929,
			"event_type_id": 12
		}
	}, {
		"id": 16,
		"name": "Member\/Guest",
		"slug": "member-guest",
		"created_at": "2017-10-09 14:43:58",
		"updated_at": "2017-10-09 14:43:58",
		"pivot": {
			"club_id": 2929,
			"event_type_id": 16
		}
	}],
	"golfer_types": [],
	"languages": [{
		"id": 1,
		"name": "English",
		"slug": "english",
		"created_at": "2017-09-26 15:27:42",
		"updated_at": "2017-09-26 15:27:42",
		"pivot": {
			"club_id": 2929,
			"language_id": 1
		}
	}, {
		"id": 2,
		"name": "Spanish",
		"slug": "spanish",
		"created_at": "2017-09-26 15:28:47",
		"updated_at": "2017-09-26 15:28:47",
		"pivot": {
			"club_id": 2929,
			"language_id": 2
		}
	}]
}, {
	"id": 3206,
	"title": "Flex Golf Tour",
	"url_title": "flex-golf-tour-scga",
	"entry_date": "2018-11-01 13:04:39",
	"entry_id": 16825,
	"region_id": 7,
	"eligible": 1,
	"club_status_id": 4,
	"latitude": "33.55611300",
	"longitude": "-117.62908600",
	"feature_video_url": null,
	"address2_line1": "999 Corporate Dr",
	"address2_line2": "Suite 100",
	"address2_city": "Ladera Ranch",
	"address2_stateorprovince": "CA",
	"address2_postalcode": "92694",
	"address2_line3": "",
	"telephone1": "(949) 329-3809",
	"emailaddress1": "",
	"new_greenfees": "60.00",
	"new_clasificationtype": "3",
	"facility_featured": "no",
	"new_scga_displayname": "Flex Golf Tour",
	"new_yearestablished": "",
	"new_scgahidecontactemail": 0,
	"new_scgaopenformembership": 1,
	"websiteurl": "http:\/\/www.flexgolftour.com",
	"facility_latitude": "33.55611300",
	"facility_longitude": "-117.62908600",
	"facility_formatted_address": "",
	"course_facility": 0,
	"club_facility": 0,
	"description": "<div class=\"embed-responsive embed-responsive-16by9\">\r\n  <iframe class=\"embed-responsive-item\" src=\"https:\/\/www.youtube.com\/embed\/vQIBOUuEf4Y\" allowfullscreen><\/iframe>\r\n<\/div>\r\n<br>\r\n<br>\r\n\r\nFlex Golf Tour gives EVERY golfer the opportunity to play Tour Golf on their schedule.  Play the designated monthly tournament course on ANY day during the month that is convenient for you.  Play different courses each month. <br>\r\n<br>\r\n- Flexible Scheduling<br>\r\n- Course Variety <br>\r\n- Social <br>\r\n- Competitive <br>\r\n- Fair Competition <br>",
	"new_scgapromotionaltext": "",
	"address1_line1": "999 Corporate Dr",
	"address1_line2": "Suite 100",
	"address1_line3": "",
	"address1_city": "Ladera Ranch",
	"address1_stateorprovince": "CA",
	"address1_postalcode": "92694",
	"online_app": 0,
	"clublink": "",
	"new_scga_allowonlineregistration": 0,
	"new_scga_joinnowurl": "https:\/\/membership.scga.org\/start\/join\/?cid=6762",
	"new_scga_renewnowurl": "",
	"new_scga_allowsonlinesignup": 1,
	"club_image": "",
	"new_remotehandicappingsystemkey": "007332060001",
	"club_facility_relationship": 0,
	"club_site_logo": "https:\/\/scga.s3.us-west-2.amazonaws.com\/logos\/flex-golf-tour-scga\/202KJFMeJAETSJ8LKBxIUxGJ52TtS2zsuzzsYMn0.png",
	"club_header_image": null,
	"last_member_import": "2020-09-10 17:16:38",
	"local_description": null,
	"local_scgapromotionaltext": "<div class=\"embed-responsive embed-responsive-16by9\">\r\n  <iframe class=\"embed-responsive-item\" src=\"https:\/\/www.youtube.com\/embed\/vQIBOUuEf4Y\" allowfullscreen><\/iframe>\r\n<\/div>\r\n<br>\r\n<br>\r\n\r\nFlex Golf Tour gives EVERY golfer the opportunity to play Tour Golf on their schedule.  Play the designated monthly tournament course on ANY day during the month that is convenient for you.  Play different courses each month. <br>\r\n<br>\r\n- Flexible Scheduling<br>\r\n- Course Variety <br>\r\n- Social <br>\r\n- Competitive <br>\r\n- Fair Competition <br>",
	"address1_county": "",
	"featured_on_home": "no",
	"geolocation_update_address2": "yes",
	"member_count": 101,
	"contact_name": null,
	"contact_email": null,
	"allow_associate_membership": 0,
	"is_senior_club": 0,
	"age_id": 6,
	"updated_at": "2020-10-16 00:00:22",
	"created_at": "2018-11-06 11:34:02",
	"last_import_action": "updated",
	"last_import_date": "2020-10-16 00:00:22",
	"classification_id": 3,
	"offers_free_trial": 0,
	"video_id": "vQIBOUuEf4Y",
	"featured_image_url": "https:\/\/scga.s3.us-west-2.amazonaws.com\/featured_image\/q4pHQbmzng62YK8dE6lBUrzu3GeY6ryCPV09LrUK.png",
	"wePlay": ["M-F", "SAT", "SUN"],
	"play_days": ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"],
	"nextEvent": {
		"id": 5230,
		"name": "OC Tour Round 3",
		"description": "",
		"event_date": "2020-10-31 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 3206,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1036,
		"created_at": "2020-08-26 15:53:30",
		"updated_at": "2020-08-26 15:53:30",
		"date": "October 31st 8:00 AM",
		"facility": {
			"id": 1036,
			"title": "Talega Golf Club"
		}
	},
	"learnMoreUrl": "\/findByClubName?active=club&club_id=3206",
	"levels": ["average-handicaps", "single-digit-handicaps"],
	"tags": [{
		"name": "Travel",
		"description": "hey"
	}, {
		"name": "Competition",
		"description": ""
	}, {
		"name": "Business & Networking",
		"description": ""
	}, {
		"name": "Average Handicaps (10-20)",
		"description": ""
	}, {
		"name": "Single Digit Handicaps",
		"description": ""
	}, {
		"name": "Men",
		"description": ""
	}, {
		"name": "Women",
		"description": ""
	}, {
		"name": "English",
		"description": ""
	}],
	"age": "36-55",
	"recommended": false,
	"whys": ["competition", "business-networking"],
	"club_types": ["travel"],
	"game_styles": ["competitive-net"],
	"member_types": [],
	"welcome_genders": ["male", "female"],
	"langs": ["english"],
	"skill_levels": [{
		"id": 8,
		"name": "Average Handicaps (10-20)",
		"slug": "average-handicaps",
		"created_at": "2017-10-09 09:17:34",
		"updated_at": "2017-10-09 15:08:46",
		"pivot": {
			"club_id": 3206,
			"skill_level_id": 8
		}
	}, {
		"id": 9,
		"name": "Single Digit Handicaps",
		"slug": "single-digit-handicaps",
		"created_at": "2017-10-09 09:17:46",
		"updated_at": "2017-10-09 09:17:46",
		"pivot": {
			"club_id": 3206,
			"skill_level_id": 9
		}
	}],
	"club_events": [{
		"id": 2212,
		"name": "Orange County Founders Tour - January",
		"description": "",
		"event_date": "2019-01-01 00:00:00",
		"event_days": 31,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 3206,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1067,
		"created_at": "2018-11-06 14:37:38",
		"updated_at": "2018-11-06 14:37:38"
	}, {
		"id": 2213,
		"name": "Orange County Founders Tour - February",
		"description": "",
		"event_date": "2019-02-01 00:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 3206,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1018,
		"created_at": "2018-11-06 14:38:08",
		"updated_at": "2018-11-06 14:38:08"
	}, {
		"id": 2214,
		"name": "Orange County Founders Tour - March",
		"description": "",
		"event_date": "2019-03-01 00:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 3206,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1042,
		"created_at": "2018-11-06 14:39:13",
		"updated_at": "2018-11-06 14:39:13"
	}, {
		"id": 2215,
		"name": "Orange County Founders Tour - April",
		"description": "",
		"event_date": "2019-04-01 00:00:00",
		"event_days": 30,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 3206,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1045,
		"created_at": "2018-11-06 14:40:07",
		"updated_at": "2018-11-06 14:40:07"
	}, {
		"id": 5228,
		"name": "OC Tour Round 1",
		"description": "",
		"event_date": "2020-08-31 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 3206,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1021,
		"created_at": "2020-08-26 15:52:36",
		"updated_at": "2020-08-26 15:52:36"
	}, {
		"id": 5229,
		"name": "OC Tour Round 2",
		"description": "",
		"event_date": "2020-09-30 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 3206,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1042,
		"created_at": "2020-08-26 15:53:05",
		"updated_at": "2020-08-26 15:53:05"
	}, {
		"id": 5230,
		"name": "OC Tour Round 3",
		"description": "",
		"event_date": "2020-10-31 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 3206,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1036,
		"created_at": "2020-08-26 15:53:30",
		"updated_at": "2020-08-26 15:53:30"
	}, {
		"id": 5231,
		"name": "OC Tour Round 4",
		"description": "",
		"event_date": "2020-11-30 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 3206,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1037,
		"created_at": "2020-08-26 15:54:09",
		"updated_at": "2020-08-26 15:54:09"
	}],
	"club_benefits": [{
		"id": 332,
		"name": "Discounted tee times at select courses",
		"description": null,
		"order": 0,
		"created_at": "2018-11-06 11:36:36",
		"updated_at": "2018-11-06 11:36:36",
		"created_by": 172,
		"club_id": 3206
	}, {
		"id": 333,
		"name": "Tour hat",
		"description": null,
		"order": 0,
		"created_at": "2018-11-06 11:36:56",
		"updated_at": "2018-11-06 11:36:56",
		"created_by": 172,
		"club_id": 3206
	}, {
		"id": 334,
		"name": "Tour bag tag",
		"description": null,
		"order": 0,
		"created_at": "2018-11-06 11:37:06",
		"updated_at": "2018-11-06 11:37:06",
		"created_by": 172,
		"club_id": 3206
	}, {
		"id": 486,
		"name": "Tour Champion Jacket by Travis Mathew",
		"description": null,
		"order": 0,
		"created_at": "2020-04-16 11:30:42",
		"updated_at": "2020-04-16 11:30:42",
		"created_by": 3975,
		"club_id": 3206
	}],
	"average_age": {
		"id": 6,
		"name": "36-55",
		"slug": "36-55",
		"created_at": "2017-10-09 11:32:35",
		"updated_at": "2017-10-18 09:02:36"
	},
	"region": {
		"id": 7,
		"name": "Orange County",
		"slug": "orange-county",
		"display_order": 3,
		"created_at": "2017-02-28 20:14:42",
		"updated_at": "2017-03-04 07:06:56",
		"created_by": 205
	},
	"wheres": [{
		"id": 3,
		"name": "Travel",
		"slug": "travel",
		"created_at": "2017-09-27 08:18:09",
		"updated_at": "2017-09-27 08:18:09",
		"pivot": {
			"club_id": 3206,
			"where_id": 3
		}
	}],
	"why": [{
		"id": 7,
		"name": "Competition",
		"slug": "competition",
		"created_at": "2017-10-05 14:53:43",
		"updated_at": "2017-10-05 14:53:43",
		"pivot": {
			"club_id": 3206,
			"why_id": 7
		}
	}, {
		"id": 10,
		"name": "Business & Networking",
		"slug": "business-networking",
		"created_at": "2017-10-05 14:54:58",
		"updated_at": "2017-10-09 09:02:44",
		"pivot": {
			"club_id": 3206,
			"why_id": 10
		}
	}],
	"we_play_days": [{
		"id": 1,
		"name": "Monday",
		"slug": "monday",
		"short_name": "Mo",
		"created_at": "2017-09-26 15:54:26",
		"updated_at": "2017-09-26 15:54:26",
		"pivot": {
			"club_id": 3206,
			"we_play_day_id": 1
		}
	}, {
		"id": 2,
		"name": "Tuesday",
		"slug": "tuesday",
		"short_name": "Tu",
		"created_at": "2017-09-26 15:54:33",
		"updated_at": "2017-09-26 15:54:33",
		"pivot": {
			"club_id": 3206,
			"we_play_day_id": 2
		}
	}, {
		"id": 3,
		"name": "Wednesday",
		"slug": "wednesday",
		"short_name": "We",
		"created_at": "2017-09-26 15:54:39",
		"updated_at": "2017-09-26 15:54:39",
		"pivot": {
			"club_id": 3206,
			"we_play_day_id": 3
		}
	}, {
		"id": 4,
		"name": "Thursday",
		"slug": "thursday",
		"short_name": "Th",
		"created_at": "2017-09-26 15:54:45",
		"updated_at": "2017-09-26 15:54:45",
		"pivot": {
			"club_id": 3206,
			"we_play_day_id": 4
		}
	}, {
		"id": 5,
		"name": "Friday",
		"slug": "friday",
		"short_name": "Fr",
		"created_at": "2017-09-26 15:54:49",
		"updated_at": "2017-09-26 15:54:49",
		"pivot": {
			"club_id": 3206,
			"we_play_day_id": 5
		}
	}, {
		"id": 6,
		"name": "Saturday",
		"slug": "saturday",
		"short_name": "Sat",
		"created_at": "2017-09-26 15:54:55",
		"updated_at": "2017-09-26 15:54:55",
		"pivot": {
			"club_id": 3206,
			"we_play_day_id": 6
		}
	}, {
		"id": 7,
		"name": "Sunday",
		"slug": "sunday",
		"short_name": "Sun",
		"created_at": "2017-09-26 15:54:59",
		"updated_at": "2017-09-26 15:54:59",
		"pivot": {
			"club_id": 3206,
			"we_play_day_id": 7
		}
	}],
	"club_status": {
		"id": 4,
		"name": "Additional",
		"slug": "additional",
		"display_order": 4
	},
	"event_types": [{
		"id": 7,
		"name": "Competitive (Net)",
		"slug": "competitive-net",
		"created_at": "2017-10-09 14:35:43",
		"updated_at": "2017-10-09 14:45:51",
		"pivot": {
			"club_id": 3206,
			"event_type_id": 7
		}
	}],
	"golfer_types": [],
	"languages": [{
		"id": 1,
		"name": "English",
		"slug": "english",
		"created_at": "2017-09-26 15:27:42",
		"updated_at": "2017-09-26 15:27:42",
		"pivot": {
			"club_id": 3206,
			"language_id": 1
		}
	}]
}, {
	"id": 124,
	"title": "Balboa Park Men's Golf Club",
	"url_title": "balboa-park-mens-golf-club",
	"entry_date": "2010-02-09 02:20:35",
	"entry_id": 3459,
	"region_id": 8,
	"eligible": 1,
	"club_status_id": 1,
	"latitude": "32.72502020",
	"longitude": "-117.13764360",
	"feature_video_url": null,
	"address2_line1": "2600 Golf Course Drive",
	"address2_line2": "",
	"address2_city": "San Diego",
	"address2_stateorprovince": "CA",
	"address2_postalcode": "92102",
	"address2_line3": "",
	"telephone1": "(619) 239-1660",
	"emailaddress1": "bpmgc@msn.com",
	"new_greenfees": "99.00",
	"new_clasificationtype": "5",
	"facility_featured": "no",
	"new_scga_displayname": "Balboa Park Men's Golf Club",
	"new_yearestablished": "",
	"new_scgahidecontactemail": 1,
	"new_scgaopenformembership": 1,
	"websiteurl": "",
	"facility_latitude": "32.72502020",
	"facility_longitude": "-117.13764360",
	"facility_formatted_address": "2600 Golf Course Dr, San Diego, CA 92102, USA",
	"course_facility": 0,
	"club_facility": 0,
	"description": "<div class=\"embed-responsive embed-responsive-16by9\">\r\n  <iframe class=\"embed-responsive-item\" src=\"https:\/\/www.youtube.com\/embed\/dEHL89mZOSk\" allowfullscreen><\/iframe>\r\n<\/div>\r\n<br>\r\n<br>\r\n\r\nWe have tournaments the second and fourth Weds of the month, periodic AWAY events at (mostly private) clubs, Happy Hours at local bars and restaurants, Weekend tournaments every 3 Sundays (or Saturdays), a Mixed event with the Women's Club and Championship events over the course of the year. Our tournaments are competitive with a mixture of formats. We have liberal payouts, usually with 45% of the field, end up in the money.\r\n\r\nGreat group of guys. Come to the \"scoring tent\" after the event for a brew and some chat.... Also, 10% off in the pro shop for club members. Get on the fast track to improving your game and have tons of FUN on one of San Diego's most competitive golf courses!",
	"new_scgapromotionaltext": "We have tournaments the second and fourth Weds of the month, periodic AWAY events at (mostly private) clubs, Happy Hours at local bars and restaurants, Weekend tournaments every 3 Sundays (or Saturdays), a Mixed event with the Women's Club and Championship events over the course of the year. Great group of guys. Come to the \"scoring tent\" after the event for a brew and some chat.... Also, 10% off in the pro shop for club members.",
	"address1_line1": "2600 Golf Course Drive",
	"address1_line2": "",
	"address1_line3": "",
	"address1_city": "San Diego",
	"address1_stateorprovince": "CA",
	"address1_postalcode": "92102",
	"online_app": 0,
	"clublink": "",
	"new_scga_allowonlineregistration": 0,
	"new_scga_joinnowurl": "https:\/\/membership.scga.org\/start\/join\/?cid=22",
	"new_scga_renewnowurl": "",
	"new_scga_allowsonlinesignup": 1,
	"club_image": "",
	"new_remotehandicappingsystemkey": "007301240001",
	"club_facility_relationship": 650,
	"club_site_logo": "https:\/\/scga.s3.us-west-2.amazonaws.com\/logos\/balboa-park-mens-golf-club\/ji2tq4e8bK5kbUESZVYk0UvSrPfTQlzJWghB6mp5.jpeg",
	"club_header_image": null,
	"last_member_import": "2020-03-03 08:47:56",
	"local_description": null,
	"local_scgapromotionaltext": "<div class=\"embed-responsive embed-responsive-16by9\">\r\n  <iframe class=\"embed-responsive-item\" src=\"https:\/\/www.youtube.com\/embed\/dEHL89mZOSk\" allowfullscreen><\/iframe>\r\n<\/div>\r\n<br>\r\n<br>\r\n\r\nWe have tournaments the second and fourth Weds of the month, periodic AWAY events at (mostly private) clubs, Happy Hours at local bars and restaurants, Weekend tournaments every 3 Sundays (or Saturdays), a Mixed event with the Women's Club and Championship events over the course of the year. Our tournaments are competitive with a mixture of formats. We have liberal payouts, usually with 45% of the field, end up in the money.\r\n\r\nGreat group of guys. Come to the \"scoring tent\" after the event for a brew and some chat.... Also, 10% off in the pro shop for club members. Get on the fast track to improving your game and have tons of FUN on one of San Diego's most competitive golf courses!",
	"address1_county": "SAN DIEGO",
	"featured_on_home": "",
	"geolocation_update_address2": "yes",
	"member_count": 330,
	"contact_name": "Edward Horbett",
	"contact_email": "edhorbett@yahoo.com",
	"allow_associate_membership": 0,
	"is_senior_club": 0,
	"age_id": 6,
	"updated_at": "2020-10-16 00:00:08",
	"created_at": "2017-01-13 04:31:12",
	"last_import_action": "updated",
	"last_import_date": "2020-10-16 00:00:08",
	"classification_id": 5,
	"offers_free_trial": 0,
	"video_id": "dEHL89mZOSk",
	"featured_image_url": "https:\/\/scga.s3.us-west-2.amazonaws.com\/featured_image\/au0MQa17rTyiNizsAIfjyJ1mZmq1RW5F9oaU0nDp.png",
	"wePlay": {
		"wednesday": "WE",
		"saturday": "SAT",
		"sunday": "SUN"
	},
	"play_days": ["wednesday", "saturday", "sunday"],
	"nextEvent": {
		"id": 5005,
		"name": "The Memorial",
		"description": "",
		"event_date": "2020-10-28 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 124,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1831,
		"created_at": "2020-02-27 12:20:19",
		"updated_at": "2020-02-27 12:20:19",
		"date": "October 28th 8:00 AM",
		"facility": {
			"id": 1831,
			"title": "Balboa Park Golf Club"
		}
	},
	"learnMoreUrl": "\/findByClubName?active=club&club_id=124",
	"levels": ["high-handicaps", "average-handicaps"],
	"tags": [{
		"name": "Home Course",
		"description": "hey"
	}, {
		"name": "Competition",
		"description": ""
	}, {
		"name": "Fun\/Social Golf",
		"description": ""
	}, {
		"name": "High Handicaps (20+)",
		"description": ""
	}, {
		"name": "Average Handicaps (10-20)",
		"description": ""
	}, {
		"name": "Men",
		"description": ""
	}, {
		"name": "Women",
		"description": ""
	}, {
		"name": "English",
		"description": ""
	}],
	"age": "36-55",
	"recommended": true,
	"whys": ["competition", "fun-social-golf"],
	"club_types": ["home-course"],
	"game_styles": ["competitive-net", "competitive-gross", "team-events", "skins-games", "league"],
	"member_types": ["juniors", "beginners"],
	"welcome_genders": ["male", "female"],
	"langs": ["english"],
	"skill_levels": [{
		"id": 7,
		"name": "High Handicaps (20+)",
		"slug": "high-handicaps",
		"created_at": "2017-10-09 09:17:15",
		"updated_at": "2017-10-09 15:08:29",
		"pivot": {
			"club_id": 124,
			"skill_level_id": 7
		}
	}, {
		"id": 8,
		"name": "Average Handicaps (10-20)",
		"slug": "average-handicaps",
		"created_at": "2017-10-09 09:17:34",
		"updated_at": "2017-10-09 15:08:46",
		"pivot": {
			"club_id": 124,
			"skill_level_id": 8
		}
	}],
	"club_events": [{
		"id": 1575,
		"name": "Club Championship Round 1",
		"description": "",
		"event_date": "2018-07-21 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 124,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1831,
		"created_at": "2018-07-11 10:21:01",
		"updated_at": "2018-07-11 10:21:01"
	}, {
		"id": 1576,
		"name": "Club Championship Round 2",
		"description": "",
		"event_date": "2018-07-22 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 124,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1831,
		"created_at": "2018-07-11 10:21:33",
		"updated_at": "2018-07-11 10:21:33"
	}, {
		"id": 1577,
		"name": "Club Championship Round 3",
		"description": "",
		"event_date": "2018-07-29 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 124,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1831,
		"created_at": "2018-07-11 10:21:53",
		"updated_at": "2018-07-11 10:21:53"
	}, {
		"id": 1578,
		"name": "Individual",
		"description": "",
		"event_date": "2018-07-25 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 124,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1831,
		"created_at": "2018-07-11 10:22:16",
		"updated_at": "2018-07-11 10:22:16"
	}, {
		"id": 1579,
		"name": "Match Play Round 1",
		"description": "",
		"event_date": "2018-08-04 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 124,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1831,
		"created_at": "2018-07-11 10:23:03",
		"updated_at": "2018-07-11 10:23:03"
	}, {
		"id": 1580,
		"name": "Match Play Round 2",
		"description": "",
		"event_date": "2018-08-05 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 124,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1831,
		"created_at": "2018-07-11 10:23:37",
		"updated_at": "2018-07-11 10:23:37"
	}, {
		"id": 1581,
		"name": "Match Play Quarterfinals",
		"description": "",
		"event_date": "2018-08-11 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 124,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1831,
		"created_at": "2018-07-11 10:24:06",
		"updated_at": "2018-07-11 10:24:06"
	}, {
		"id": 1582,
		"name": "Match Play Semifinals",
		"description": "",
		"event_date": "2018-08-12 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 124,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1831,
		"created_at": "2018-07-11 10:24:26",
		"updated_at": "2018-07-11 10:24:26"
	}, {
		"id": 1583,
		"name": "Match Play Finals",
		"description": "",
		"event_date": "2018-08-19 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 124,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1831,
		"created_at": "2018-07-11 10:24:46",
		"updated_at": "2018-07-11 10:24:46"
	}, {
		"id": 1584,
		"name": "Individual",
		"description": "",
		"event_date": "2018-08-08 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 124,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1831,
		"created_at": "2018-07-11 10:25:07",
		"updated_at": "2018-07-11 10:25:07"
	}, {
		"id": 1585,
		"name": "2-Man Team Scramble",
		"description": "",
		"event_date": "2018-08-22 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 124,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1831,
		"created_at": "2018-07-11 10:25:47",
		"updated_at": "2018-07-11 10:25:47"
	}, {
		"id": 1586,
		"name": "Senior Club Championship Round 1",
		"description": "",
		"event_date": "2018-09-04 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 124,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1831,
		"created_at": "2018-07-11 10:26:19",
		"updated_at": "2018-07-11 10:26:19"
	}, {
		"id": 1587,
		"name": "Senior Club Championship Round 2",
		"description": "",
		"event_date": "2018-09-05 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 124,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1831,
		"created_at": "2018-07-11 10:26:39",
		"updated_at": "2018-07-11 10:26:39"
	}, {
		"id": 1588,
		"name": "Away Tournament",
		"description": "",
		"event_date": "2018-09-17 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 124,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1819,
		"created_at": "2018-07-11 10:27:22",
		"updated_at": "2018-07-11 10:27:22"
	}, {
		"id": 1589,
		"name": "Are you the HERO, or are you the BUM?",
		"description": "",
		"event_date": "2018-09-23 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 124,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1831,
		"created_at": "2018-07-11 10:28:10",
		"updated_at": "2018-07-11 10:28:10"
	}, {
		"id": 1590,
		"name": "Individual - Toss Three",
		"description": "",
		"event_date": "2018-09-26 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 124,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1831,
		"created_at": "2018-07-11 10:28:38",
		"updated_at": "2018-07-11 10:28:38"
	}, {
		"id": 1591,
		"name": "Fall Classic Invitational",
		"description": "",
		"event_date": "2018-10-06 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 124,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1831,
		"created_at": "2018-07-11 10:29:04",
		"updated_at": "2018-07-11 10:29:04"
	}, {
		"id": 1592,
		"name": "Individual",
		"description": "",
		"event_date": "2018-10-10 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 124,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1831,
		"created_at": "2018-07-11 10:29:31",
		"updated_at": "2018-07-11 10:29:31"
	}, {
		"id": 1593,
		"name": "4 Man Team - Better 2 of 4",
		"description": "",
		"event_date": "2018-10-24 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 124,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1831,
		"created_at": "2018-07-11 10:30:16",
		"updated_at": "2018-07-11 10:30:16"
	}, {
		"id": 1594,
		"name": "2 Man Eclectic Championship - Day 1",
		"description": "",
		"event_date": "2018-10-27 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 124,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1831,
		"created_at": "2018-07-11 10:30:56",
		"updated_at": "2018-07-11 10:30:56"
	}, {
		"id": 1595,
		"name": "2 Man Eclectic Championship - Day 2",
		"description": "",
		"event_date": "2018-10-28 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 124,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1831,
		"created_at": "2018-07-11 10:31:26",
		"updated_at": "2018-07-11 10:31:26"
	}, {
		"id": 1596,
		"name": "Individual",
		"description": "",
		"event_date": "2018-11-07 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 124,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1831,
		"created_at": "2018-07-11 10:31:47",
		"updated_at": "2018-07-11 10:31:47"
	}, {
		"id": 1597,
		"name": "Dick Hanson Memorial",
		"description": "",
		"event_date": "2018-11-11 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 124,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1831,
		"created_at": "2018-07-11 10:32:14",
		"updated_at": "2018-07-11 10:32:14"
	}, {
		"id": 1598,
		"name": "2 Man Team Scramble",
		"description": "",
		"event_date": "2018-11-14 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 124,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1831,
		"created_at": "2018-07-11 10:32:36",
		"updated_at": "2018-07-11 10:32:36"
	}, {
		"id": 1599,
		"name": "Last Chance Bromance",
		"description": "",
		"event_date": "2018-12-02 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 124,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1831,
		"created_at": "2018-07-11 10:33:20",
		"updated_at": "2018-07-11 10:33:20"
	}, {
		"id": 1600,
		"name": "Event Winners & Wanna B's Individual",
		"description": "",
		"event_date": "2018-12-05 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 124,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1831,
		"created_at": "2018-07-11 10:33:57",
		"updated_at": "2018-07-11 10:33:57"
	}, {
		"id": 1601,
		"name": "Weekend Tournament of Champions",
		"description": "",
		"event_date": "2018-12-09 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 124,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1831,
		"created_at": "2018-07-11 10:34:31",
		"updated_at": "2018-07-11 10:34:31"
	}, {
		"id": 1602,
		"name": "Individual",
		"description": "",
		"event_date": "2018-12-12 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 124,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1831,
		"created_at": "2018-07-11 10:34:51",
		"updated_at": "2018-07-11 10:34:51"
	}, {
		"id": 4964,
		"name": "March Madness",
		"description": "",
		"event_date": "2020-03-11 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 124,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1831,
		"created_at": "2020-02-27 12:01:58",
		"updated_at": "2020-02-27 12:01:58"
	}, {
		"id": 4965,
		"name": "Individual",
		"description": "",
		"event_date": "2020-03-25 08:15:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 124,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1859,
		"created_at": "2020-02-27 12:03:40",
		"updated_at": "2020-02-27 12:03:40"
	}, {
		"id": 4966,
		"name": "Hippity Hoppity",
		"description": "",
		"event_date": "2020-04-08 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 124,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1831,
		"created_at": "2020-02-27 12:04:11",
		"updated_at": "2020-02-27 12:04:11"
	}, {
		"id": 4967,
		"name": "Earth Day Best Ball",
		"description": "",
		"event_date": "2020-04-22 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 124,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1831,
		"created_at": "2020-02-27 12:04:53",
		"updated_at": "2020-02-27 12:04:53"
	}, {
		"id": 4968,
		"name": "Individual",
		"description": "",
		"event_date": "2020-05-13 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 124,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1831,
		"created_at": "2020-02-27 12:05:46",
		"updated_at": "2020-02-27 12:05:46"
	}, {
		"id": 4969,
		"name": "2 Man Scramble",
		"description": "",
		"event_date": "2020-05-27 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 124,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1831,
		"created_at": "2020-02-27 12:06:08",
		"updated_at": "2020-02-27 12:06:08"
	}, {
		"id": 4970,
		"name": "Individual",
		"description": "",
		"event_date": "2020-06-10 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 124,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1831,
		"created_at": "2020-02-27 12:06:30",
		"updated_at": "2020-02-27 12:06:30"
	}, {
		"id": 4971,
		"name": "2 Man Best Ball",
		"description": "",
		"event_date": "2020-06-24 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 124,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1831,
		"created_at": "2020-02-27 12:06:50",
		"updated_at": "2020-02-27 12:06:50"
	}, {
		"id": 4972,
		"name": "Annual Firecracker",
		"description": "",
		"event_date": "2020-07-08 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 124,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1831,
		"created_at": "2020-02-27 12:07:11",
		"updated_at": "2020-02-27 12:07:11"
	}, {
		"id": 4973,
		"name": "Individual",
		"description": "",
		"event_date": "2020-07-22 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 124,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1831,
		"created_at": "2020-02-27 12:07:27",
		"updated_at": "2020-02-27 12:07:27"
	}, {
		"id": 4974,
		"name": "Duel",
		"description": "",
		"event_date": "2020-08-12 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 124,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1831,
		"created_at": "2020-02-27 12:07:45",
		"updated_at": "2020-02-27 12:07:45"
	}, {
		"id": 4975,
		"name": "Pick 9",
		"description": "",
		"event_date": "2020-08-26 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 124,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1831,
		"created_at": "2020-02-27 12:08:04",
		"updated_at": "2020-02-27 12:08:04"
	}, {
		"id": 4976,
		"name": "Individual",
		"description": "",
		"event_date": "2020-09-23 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 124,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1831,
		"created_at": "2020-02-27 12:08:36",
		"updated_at": "2020-02-27 12:08:36"
	}, {
		"id": 4977,
		"name": "Individual",
		"description": "",
		"event_date": "2020-10-14 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 124,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1831,
		"created_at": "2020-02-27 12:08:58",
		"updated_at": "2020-02-27 12:08:58"
	}, {
		"id": 4978,
		"name": "Individual",
		"description": "",
		"event_date": "2020-11-11 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 124,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1831,
		"created_at": "2020-02-27 12:09:17",
		"updated_at": "2020-02-27 12:09:17"
	}, {
		"id": 4979,
		"name": "Team Event",
		"description": "",
		"event_date": "2020-11-18 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 124,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1831,
		"created_at": "2020-02-27 12:09:34",
		"updated_at": "2020-02-27 12:09:34"
	}, {
		"id": 4980,
		"name": "Weekday Tournament of Champions",
		"description": "",
		"event_date": "2020-12-09 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 124,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1831,
		"created_at": "2020-02-27 12:09:58",
		"updated_at": "2020-02-27 12:09:58"
	}, {
		"id": 4981,
		"name": "Waltz",
		"description": "",
		"event_date": "2020-12-16 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 124,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1831,
		"created_at": "2020-02-27 12:10:18",
		"updated_at": "2020-02-27 12:10:18"
	}, {
		"id": 4982,
		"name": "Yesterday is Today",
		"description": "",
		"event_date": "2020-03-01 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 124,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1831,
		"created_at": "2020-02-27 12:11:17",
		"updated_at": "2020-02-27 12:11:17"
	}, {
		"id": 4983,
		"name": "The Geeze and Me",
		"description": "",
		"event_date": "2020-04-05 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 124,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1831,
		"created_at": "2020-02-27 12:11:37",
		"updated_at": "2020-02-27 12:11:37"
	}, {
		"id": 4984,
		"name": "Keep Your Better Half",
		"description": "",
		"event_date": "2020-04-26 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 124,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1831,
		"created_at": "2020-02-27 12:11:57",
		"updated_at": "2020-02-27 12:11:57"
	}, {
		"id": 4985,
		"name": "Let's Play 2!",
		"description": "",
		"event_date": "2020-05-17 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 124,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1831,
		"created_at": "2020-02-27 12:12:17",
		"updated_at": "2020-02-27 12:12:17"
	}, {
		"id": 4986,
		"name": "BOBapalooza",
		"description": "",
		"event_date": "2020-06-07 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 124,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1831,
		"created_at": "2020-02-27 12:12:38",
		"updated_at": "2020-02-27 12:12:38"
	}, {
		"id": 4987,
		"name": "Boys of Summer",
		"description": "",
		"event_date": "2020-06-28 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 124,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1831,
		"created_at": "2020-02-27 12:13:02",
		"updated_at": "2020-02-27 12:13:02"
	}, {
		"id": 4988,
		"name": "All-Star Individual",
		"description": "",
		"event_date": "2020-07-12 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 124,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1831,
		"created_at": "2020-02-27 12:13:26",
		"updated_at": "2020-02-27 12:13:26"
	}, {
		"id": 4989,
		"name": "Sprint to the Finish",
		"description": "",
		"event_date": "2020-08-16 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 124,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1831,
		"created_at": "2020-02-27 12:13:47",
		"updated_at": "2020-02-27 12:13:47"
	}, {
		"id": 4990,
		"name": "Are You the Hero, the Bum, or Something in Between?",
		"description": "",
		"event_date": "2020-08-30 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 124,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1831,
		"created_at": "2020-02-27 12:14:18",
		"updated_at": "2020-02-27 12:14:18"
	}, {
		"id": 4991,
		"name": "End of Summer Classic",
		"description": "",
		"event_date": "2020-09-13 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 124,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1831,
		"created_at": "2020-02-27 12:14:40",
		"updated_at": "2020-02-27 12:14:40"
	}, {
		"id": 4992,
		"name": "Annual Balboa 2-Man Eclectic Championship",
		"description": "",
		"event_date": "2020-10-17 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 124,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1831,
		"created_at": "2020-02-27 12:15:08",
		"updated_at": "2020-02-27 12:15:08"
	}, {
		"id": 4993,
		"name": "The Lucky Ones",
		"description": "",
		"event_date": "2020-11-01 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 124,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1831,
		"created_at": "2020-02-27 12:15:29",
		"updated_at": "2020-02-27 12:15:29"
	}, {
		"id": 4994,
		"name": "Where Were You?",
		"description": "",
		"event_date": "2020-11-22 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 124,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1831,
		"created_at": "2020-02-27 12:15:48",
		"updated_at": "2020-02-27 12:15:48"
	}, {
		"id": 4995,
		"name": "Last Chance Bromance",
		"description": "",
		"event_date": "2020-12-06 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 124,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1831,
		"created_at": "2020-02-27 12:16:08",
		"updated_at": "2020-02-27 12:16:08"
	}, {
		"id": 4996,
		"name": "Weekend Tournament of Champions",
		"description": "",
		"event_date": "2020-12-13 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 124,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1831,
		"created_at": "2020-02-27 12:16:34",
		"updated_at": "2020-02-27 12:16:34"
	}, {
		"id": 4997,
		"name": "President's Cup Round 1",
		"description": "",
		"event_date": "2020-03-14 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 124,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1831,
		"created_at": "2020-02-27 12:17:04",
		"updated_at": "2020-02-27 12:17:04"
	}, {
		"id": 4998,
		"name": "President's Cup Round 2",
		"description": "",
		"event_date": "2020-03-15 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 124,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1831,
		"created_at": "2020-02-27 12:17:20",
		"updated_at": "2020-02-27 12:17:20"
	}, {
		"id": 4999,
		"name": "Duel in the Park - Match Play Championship",
		"description": "",
		"event_date": "2020-05-09 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 124,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1831,
		"created_at": "2020-02-27 12:17:44",
		"updated_at": "2020-02-27 12:17:44"
	}, {
		"id": 5000,
		"name": "86th Annual Club Championship Rd 1",
		"description": "",
		"event_date": "2020-07-31 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 124,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1831,
		"created_at": "2020-02-27 12:18:20",
		"updated_at": "2020-02-27 12:18:20"
	}, {
		"id": 5001,
		"name": "86th Annual Club Championship Rd 2",
		"description": "",
		"event_date": "2020-08-01 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 124,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1831,
		"created_at": "2020-02-27 12:18:40",
		"updated_at": "2020-02-27 12:18:40"
	}, {
		"id": 5002,
		"name": "86th Annual Club Championship Rd 3",
		"description": "",
		"event_date": "2020-08-02 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 124,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1831,
		"created_at": "2020-02-27 12:18:57",
		"updated_at": "2020-02-27 12:18:57"
	}, {
		"id": 5003,
		"name": "Senior Club Championship Round 1",
		"description": "",
		"event_date": "2020-09-08 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 124,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1831,
		"created_at": "2020-02-27 12:19:25",
		"updated_at": "2020-02-27 12:19:25"
	}, {
		"id": 5004,
		"name": "Senior Club Championship Round 2",
		"description": "",
		"event_date": "2020-09-09 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 124,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1831,
		"created_at": "2020-02-27 12:19:46",
		"updated_at": "2020-02-27 12:19:46"
	}, {
		"id": 5005,
		"name": "The Memorial",
		"description": "",
		"event_date": "2020-10-28 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 124,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1831,
		"created_at": "2020-02-27 12:20:19",
		"updated_at": "2020-02-27 12:20:19"
	}, {
		"id": 5006,
		"name": "Away Tournament ",
		"description": "",
		"event_date": "2020-06-01 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 124,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1814,
		"created_at": "2020-02-27 12:20:57",
		"updated_at": "2020-02-27 12:20:57"
	}, {
		"id": 5007,
		"name": "Away Tournament ",
		"description": "",
		"event_date": "2020-08-03 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 124,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1857,
		"created_at": "2020-02-27 12:21:25",
		"updated_at": "2020-02-27 12:21:25"
	}, {
		"id": 5008,
		"name": "Away Tournament ",
		"description": "",
		"event_date": "2020-10-05 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 124,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1855,
		"created_at": "2020-02-27 12:21:58",
		"updated_at": "2020-02-27 12:21:58"
	}],
	"club_benefits": [{
		"id": 291,
		"name": "10% discount in pro shop",
		"description": null,
		"order": 0,
		"created_at": "2018-07-09 15:01:09",
		"updated_at": "2018-07-09 15:01:09",
		"created_by": 172,
		"club_id": 124
	}, {
		"id": 292,
		"name": "Preferred tee times",
		"description": null,
		"order": 0,
		"created_at": "2018-07-09 15:01:36",
		"updated_at": "2018-07-09 15:01:36",
		"created_by": 172,
		"club_id": 124
	}],
	"average_age": {
		"id": 6,
		"name": "36-55",
		"slug": "36-55",
		"created_at": "2017-10-09 11:32:35",
		"updated_at": "2017-10-18 09:02:36"
	},
	"region": {
		"id": 8,
		"name": "San Diego",
		"slug": "san-diego",
		"display_order": 2,
		"created_at": "2017-02-28 20:14:59",
		"updated_at": "2017-03-04 07:25:25",
		"created_by": 205
	},
	"wheres": [{
		"id": 1,
		"name": "Home Course",
		"slug": "home-course",
		"created_at": "2017-09-27 08:14:50",
		"updated_at": "2017-09-27 08:14:50",
		"pivot": {
			"club_id": 124,
			"where_id": 1
		}
	}],
	"why": [{
		"id": 7,
		"name": "Competition",
		"slug": "competition",
		"created_at": "2017-10-05 14:53:43",
		"updated_at": "2017-10-05 14:53:43",
		"pivot": {
			"club_id": 124,
			"why_id": 7
		}
	}, {
		"id": 8,
		"name": "Fun\/Social Golf",
		"slug": "fun-social-golf",
		"created_at": "2017-10-05 14:54:09",
		"updated_at": "2017-11-20 11:02:08",
		"pivot": {
			"club_id": 124,
			"why_id": 8
		}
	}],
	"we_play_days": [{
		"id": 3,
		"name": "Wednesday",
		"slug": "wednesday",
		"short_name": "We",
		"created_at": "2017-09-26 15:54:39",
		"updated_at": "2017-09-26 15:54:39",
		"pivot": {
			"club_id": 124,
			"we_play_day_id": 3
		}
	}, {
		"id": 6,
		"name": "Saturday",
		"slug": "saturday",
		"short_name": "Sat",
		"created_at": "2017-09-26 15:54:55",
		"updated_at": "2017-09-26 15:54:55",
		"pivot": {
			"club_id": 124,
			"we_play_day_id": 6
		}
	}, {
		"id": 7,
		"name": "Sunday",
		"slug": "sunday",
		"short_name": "Sun",
		"created_at": "2017-09-26 15:54:59",
		"updated_at": "2017-09-26 15:54:59",
		"pivot": {
			"club_id": 124,
			"we_play_day_id": 7
		}
	}],
	"club_status": {
		"id": 1,
		"name": "Platinum",
		"slug": "platinum",
		"display_order": 1
	},
	"event_types": [{
		"id": 7,
		"name": "Competitive (Net)",
		"slug": "competitive-net",
		"created_at": "2017-10-09 14:35:43",
		"updated_at": "2017-10-09 14:45:51",
		"pivot": {
			"club_id": 124,
			"event_type_id": 7
		}
	}, {
		"id": 8,
		"name": "Competitive (Gross)",
		"slug": "competitive-gross",
		"created_at": "2017-10-09 14:42:21",
		"updated_at": "2017-10-09 14:45:37",
		"pivot": {
			"club_id": 124,
			"event_type_id": 8
		}
	}, {
		"id": 10,
		"name": "Team Events",
		"slug": "team-events",
		"created_at": "2017-10-09 14:42:46",
		"updated_at": "2017-10-09 14:42:46",
		"pivot": {
			"club_id": 124,
			"event_type_id": 10
		}
	}, {
		"id": 11,
		"name": "Skins Games",
		"slug": "skins-games",
		"created_at": "2017-10-09 14:42:55",
		"updated_at": "2017-10-09 14:42:55",
		"pivot": {
			"club_id": 124,
			"event_type_id": 11
		}
	}, {
		"id": 12,
		"name": "League",
		"slug": "league",
		"created_at": "2017-10-09 14:43:26",
		"updated_at": "2017-10-09 14:43:26",
		"pivot": {
			"club_id": 124,
			"event_type_id": 12
		}
	}],
	"golfer_types": [{
		"id": 3,
		"name": "Juniors",
		"description": "Under 18",
		"slug": "juniors",
		"created_at": "2017-03-04 18:28:47",
		"updated_at": "2017-03-04 18:28:47",
		"created_by": null,
		"pivot": {
			"club_id": 124,
			"golfer_type_id": 3
		}
	}, {
		"id": 4,
		"name": "Beginners",
		"description": "Noobies of all ages",
		"slug": "beginners",
		"created_at": "2017-03-04 18:29:06",
		"updated_at": "2017-03-04 18:29:06",
		"created_by": null,
		"pivot": {
			"club_id": 124,
			"golfer_type_id": 4
		}
	}],
	"languages": [{
		"id": 1,
		"name": "English",
		"slug": "english",
		"created_at": "2017-09-26 15:27:42",
		"updated_at": "2017-09-26 15:27:42",
		"pivot": {
			"club_id": 124,
			"language_id": 1
		}
	}]
}, {
	"id": 2769,
	"title": "Cobra-Puma Golf Club",
	"url_title": "cobra-puma-golf-club",
	"entry_date": "2011-01-07 16:05:53",
	"entry_id": 3345,
	"region_id": 8,
	"eligible": 0,
	"club_status_id": 4,
	"latitude": "33.13374510",
	"longitude": "-117.28982420",
	"feature_video_url": null,
	"address2_line1": "1818 Aston Avenue",
	"address2_line2": "",
	"address2_city": "Carlsbad",
	"address2_stateorprovince": "CA",
	"address2_postalcode": "92008",
	"address2_line3": "",
	"telephone1": "(760) 710-3364",
	"emailaddress1": "",
	"new_greenfees": "36.00",
	"new_clasificationtype": "8",
	"facility_featured": "",
	"new_scga_displayname": "Cobra-Puma Golf Club",
	"new_yearestablished": "",
	"new_scgahidecontactemail": 0,
	"new_scgaopenformembership": 0,
	"websiteurl": "",
	"facility_latitude": "33.13374510",
	"facility_longitude": "-117.28982420",
	"facility_formatted_address": "1818 Aston Ave, Carlsbad, CA 92008, USA",
	"course_facility": 0,
	"club_facility": 0,
	"description": "Private club intended for current employees of Cobra PUMA Golf",
	"new_scgapromotionaltext": "Private club intended for current employees of Cobra PUMA Golf",
	"address1_line1": "1818 Aston Avenue",
	"address1_line2": "",
	"address1_line3": "",
	"address1_city": "Carlsbad",
	"address1_stateorprovince": "CA",
	"address1_postalcode": "92008",
	"online_app": 0,
	"clublink": "",
	"new_scga_allowonlineregistration": 0,
	"new_scga_joinnowurl": "http:\/\/membership.scga.org\/start\/join\/?cid=947",
	"new_scga_renewnowurl": "",
	"new_scga_allowsonlinesignup": 0,
	"club_image": "",
	"new_remotehandicappingsystemkey": "007327690001",
	"club_facility_relationship": 0,
	"club_site_logo": null,
	"club_header_image": null,
	"last_member_import": "2019-12-28 03:16:07",
	"local_description": null,
	"local_scgapromotionaltext": null,
	"address1_county": "San Diego",
	"featured_on_home": "",
	"geolocation_update_address2": "yes",
	"member_count": 45,
	"contact_name": "Ben Neal",
	"contact_email": "bneal4@hotmail.com",
	"allow_associate_membership": 0,
	"is_senior_club": 0,
	"age_id": 6,
	"updated_at": "2020-10-16 00:00:07",
	"created_at": "2017-01-13 04:31:10",
	"last_import_action": "updated",
	"last_import_date": "2020-10-16 00:00:07",
	"classification_id": 8,
	"offers_free_trial": 0,
	"video_id": "kO8oeMxM4R4",
	"featured_image_url": "https:\/\/scga.s3.us-west-2.amazonaws.com\/featured_image\/5wtLEmn8D0MqmEoDaDj4tAfBEKctcm51DyLYSeQU.png",
	"wePlay": ["M-F", "SAT", "SUN"],
	"play_days": [],
	"nextEvent": null,
	"learnMoreUrl": "\/findByClubName?active=club&club_id=2769",
	"levels": [],
	"tags": [],
	"age": "36-55",
	"recommended": false,
	"whys": [],
	"club_types": [],
	"game_styles": [],
	"member_types": ["juniors", "beginners"],
	"welcome_genders": [],
	"langs": [],
	"skill_levels": [],
	"club_events": [],
	"club_benefits": [],
	"average_age": {
		"id": 6,
		"name": "36-55",
		"slug": "36-55",
		"created_at": "2017-10-09 11:32:35",
		"updated_at": "2017-10-18 09:02:36"
	},
	"region": {
		"id": 8,
		"name": "San Diego",
		"slug": "san-diego",
		"display_order": 2,
		"created_at": "2017-02-28 20:14:59",
		"updated_at": "2017-03-04 07:25:25",
		"created_by": 205
	},
	"wheres": [],
	"why": [],
	"we_play_days": [],
	"club_status": {
		"id": 4,
		"name": "Additional",
		"slug": "additional",
		"display_order": 4
	},
	"event_types": [],
	"golfer_types": [{
		"id": 3,
		"name": "Juniors",
		"description": "Under 18",
		"slug": "juniors",
		"created_at": "2017-03-04 18:28:47",
		"updated_at": "2017-03-04 18:28:47",
		"created_by": null,
		"pivot": {
			"club_id": 2769,
			"golfer_type_id": 3
		}
	}, {
		"id": 4,
		"name": "Beginners",
		"description": "Noobies of all ages",
		"slug": "beginners",
		"created_at": "2017-03-04 18:29:06",
		"updated_at": "2017-03-04 18:29:06",
		"created_by": null,
		"pivot": {
			"club_id": 2769,
			"golfer_type_id": 4
		}
	}],
	"languages": []
}, {
	"id": 3061,
	"title": "L.A. County Fire Golf Association",
	"url_title": "l.a.-county-fire-golf-association",
	"entry_date": "2010-12-07 11:32:23",
	"entry_id": 3993,
	"region_id": 9,
	"eligible": 0,
	"club_status_id": 4,
	"latitude": "34.08428200",
	"longitude": "-117.63860600",
	"feature_video_url": null,
	"address2_line1": "814 E La Deney Ct",
	"address2_line2": "",
	"address2_city": "Ontario",
	"address2_stateorprovince": "CA",
	"address2_postalcode": "91764",
	"address2_line3": "",
	"telephone1": "(909) 694-1712",
	"emailaddress1": "",
	"new_greenfees": "60.00",
	"new_clasificationtype": "8",
	"facility_featured": "",
	"new_scga_displayname": "L.A. County Fire Golf Association",
	"new_yearestablished": "",
	"new_scgahidecontactemail": 0,
	"new_scgaopenformembership": 0,
	"websiteurl": "",
	"facility_latitude": "34.08428200",
	"facility_longitude": "-117.63860600",
	"facility_formatted_address": "814 E La Deney Ct, Ontario, CA 91764, USA",
	"course_facility": 0,
	"club_facility": 0,
	"description": "<div class=\"embed-responsive embed-responsive-16by9\">\n  <iframe class=\"embed-responsive-item\" src=\"https:\/\/www.youtube.com\/embed\/17wKoGK78uU\" allowfullscreen><\/iframe>\n<\/div>\n<br>\n<br>\nGolfing opportunities for members of the Los Angeles County Fire Department, retirees, family, and friends.",
	"new_scgapromotionaltext": "Golfing opportunities and results for members of the Los Angeles County Fire Department, retirees, family, and friends.",
	"address1_line1": "814 E La Deney Ct",
	"address1_line2": "",
	"address1_line3": "",
	"address1_city": "Ontario",
	"address1_stateorprovince": "CA",
	"address1_postalcode": "91764",
	"online_app": 0,
	"clublink": "",
	"new_scga_allowonlineregistration": 0,
	"new_scga_joinnowurl": "https:\/\/membership.scga.org\/start\/join\/?cid=1152",
	"new_scga_renewnowurl": "",
	"new_scga_allowsonlinesignup": 0,
	"club_image": "",
	"new_remotehandicappingsystemkey": "007330610001",
	"club_facility_relationship": 0,
	"club_site_logo": null,
	"club_header_image": null,
	"last_member_import": "2019-12-28 04:55:32",
	"local_description": null,
	"local_scgapromotionaltext": "<div class=\"embed-responsive embed-responsive-16by9\">\n  <iframe class=\"embed-responsive-item\" src=\"https:\/\/www.youtube.com\/embed\/17wKoGK78uU\" allowfullscreen><\/iframe>\n<\/div>\n<br>\n<br>\nGolfing opportunities for members of the Los Angeles County Fire Department, retirees, family, and friends.",
	"address1_county": "LOS ANGELES",
	"featured_on_home": "",
	"geolocation_update_address2": "yes",
	"member_count": 377,
	"contact_name": "Jerry Reed",
	"contact_email": "jreed6114@roadrunner.com",
	"allow_associate_membership": 0,
	"is_senior_club": 0,
	"age_id": 6,
	"updated_at": "2020-10-16 00:00:14",
	"created_at": "2017-01-13 04:38:10",
	"last_import_action": "updated",
	"last_import_date": "2020-10-16 00:00:14",
	"classification_id": 8,
	"offers_free_trial": 0,
	"video_id": "17wKoGK78uU",
	"featured_image_url": "https:\/\/scga.s3.us-west-2.amazonaws.com\/featured_image\/BeI60VFZr9PKZcCR8tH3vqjpW20ZsVSTBtvkfN3a.png",
	"wePlay": ["M-F", "SAT", "SUN"],
	"play_days": [],
	"nextEvent": null,
	"learnMoreUrl": "\/findByClubName?active=club&club_id=3061",
	"levels": [],
	"tags": [],
	"age": "36-55",
	"recommended": false,
	"whys": [],
	"club_types": [],
	"game_styles": [],
	"member_types": [],
	"welcome_genders": [],
	"langs": [],
	"skill_levels": [],
	"club_events": [],
	"club_benefits": [],
	"average_age": {
		"id": 6,
		"name": "36-55",
		"slug": "36-55",
		"created_at": "2017-10-09 11:32:35",
		"updated_at": "2017-10-18 09:02:36"
	},
	"region": {
		"id": 9,
		"name": "Inland Empire",
		"slug": "inland-empire",
		"display_order": 6,
		"created_at": "2017-02-28 20:15:08",
		"updated_at": "2017-02-28 20:15:08",
		"created_by": 205
	},
	"wheres": [],
	"why": [],
	"we_play_days": [],
	"club_status": {
		"id": 4,
		"name": "Additional",
		"slug": "additional",
		"display_order": 4
	},
	"event_types": [],
	"golfer_types": [],
	"languages": []
}, {
	"id": 2987,
	"title": "Speedgolf Southern California - Scga",
	"url_title": "speedgolf-southern-california-scga",
	"entry_date": "2017-06-29 12:07:48",
	"entry_id": 13560,
	"region_id": 10,
	"eligible": 0,
	"club_status_id": 4,
	"latitude": "34.04545440",
	"longitude": "-118.26089000",
	"feature_video_url": null,
	"address2_line1": "639 W 9Th St Apt 639",
	"address2_line2": "",
	"address2_city": "Los Angeles",
	"address2_stateorprovince": "CA",
	"address2_postalcode": "90015",
	"address2_line3": "",
	"telephone1": "(469) 569-9521",
	"emailaddress1": "",
	"new_greenfees": "50.00",
	"new_clasificationtype": "3",
	"facility_featured": "",
	"new_scga_displayname": "Speedgolf Southern California - Scga",
	"new_yearestablished": "",
	"new_scgahidecontactemail": 0,
	"new_scgaopenformembership": 0,
	"websiteurl": "",
	"facility_latitude": "34.04545440",
	"facility_longitude": "-118.26089000",
	"facility_formatted_address": "639 W 9th St #639, Los Angeles, CA 90015, USA",
	"course_facility": 0,
	"club_facility": 1,
	"description": "<div class=\"embed-responsive embed-responsive-16by9\">\n  <iframe class=\"embed-responsive-item\" src=\"https:\/\/www.youtube.com\/embed\/EonX--thunE\" allowfullscreen><\/iframe>\n<\/div>\n<br>\n<br>\nSpeedgolf is a fun, fitness-oriented, fast-growing sport, which combines two popular but unrelated activities, golf & running. Golf no longer has to take 5+ hours or replace a fitness workout.We're growing speedgolf in Southern California. We are actively looking for players to join us for upcoming events at local courses. All abilities welcome to play. Only requirements are you play golf (ideally <25 hdcp), are able to run\/jog\/walk briskly, and like to have fun! \nClick <a href=\"http:\/\/www.speedgolfsocal.com\"target=\"_blank\">here<\/a> to learn more.",
	"new_scgapromotionaltext": null,
	"address1_line1": "639 W 9Th St Apt 639",
	"address1_line2": "",
	"address1_line3": "",
	"address1_city": "Los Angeles",
	"address1_stateorprovince": "CA",
	"address1_postalcode": "90015",
	"online_app": 0,
	"clublink": "",
	"new_scga_allowonlineregistration": 1,
	"new_scga_joinnowurl": "",
	"new_scga_renewnowurl": "",
	"new_scga_allowsonlinesignup": 1,
	"club_image": "",
	"new_remotehandicappingsystemkey": "007329870001",
	"club_facility_relationship": 0,
	"club_site_logo": "https:\/\/scga.s3.us-west-2.amazonaws.com\/logos\/speedgolf-southern-california-scga\/RHKVKNbeMckmEjgYvKmnFweRyP8xzh4uP4lUSgld.jpeg",
	"club_header_image": "https:\/\/scga.s3.us-west-2.amazonaws.com\/headers\/speedgolf-southern-california-scga\/iUqh74QP8v945Zo0JQ2v8YPtJ2fDKSNUsjZFRqOD.jpeg",
	"last_member_import": "2019-12-14 03:49:13",
	"local_description": null,
	"local_scgapromotionaltext": "<div class=\"embed-responsive embed-responsive-16by9\">\n  <iframe class=\"embed-responsive-item\" src=\"https:\/\/www.youtube.com\/embed\/EonX--thunE\" allowfullscreen><\/iframe>\n<\/div>\n<br>\n<br>\nSpeedgolf is a fun, fitness-oriented, fast-growing sport, which combines two popular but unrelated activities, golf & running. Golf no longer has to take 5+ hours or replace a fitness workout.We're growing speedgolf in Southern California. We are actively looking for players to join us for upcoming events at local courses. All abilities welcome to play. Only requirements are you play golf (ideally <25 hdcp), are able to run\/jog\/walk briskly, and like to have fun! \nClick <a href=\"http:\/\/www.speedgolfsocal.com\"target=\"_blank\">here<\/a> to learn more.",
	"address1_county": "",
	"featured_on_home": "",
	"geolocation_update_address2": "0",
	"member_count": 0,
	"contact_name": null,
	"contact_email": null,
	"allow_associate_membership": 0,
	"is_senior_club": 0,
	"age_id": 6,
	"updated_at": "2019-04-03 10:50:47",
	"created_at": "2018-01-24 04:16:20",
	"last_import_action": "updated",
	"last_import_date": "2018-01-24 12:16:20",
	"classification_id": 3,
	"offers_free_trial": 0,
	"video_id": "EonX--thunE",
	"featured_image_url": "https:\/\/scga.s3.us-west-2.amazonaws.com\/featured_image\/Hji1ur9Bxmuo88IxzU2GZXNFx5TtvS1ta9greDqk.png",
	"wePlay": {
		"saturday": "SAT",
		"sunday": "SUN"
	},
	"play_days": ["saturday", "sunday"],
	"nextEvent": null,
	"learnMoreUrl": "\/findByClubName?active=club&club_id=2987",
	"levels": ["average-handicaps"],
	"tags": [{
		"name": "Travel",
		"description": "hey"
	}, {
		"name": "Fun\/Social Golf",
		"description": ""
	}, {
		"name": "Staying Active\/Health",
		"description": ""
	}, {
		"name": "Average Handicaps (10-20)",
		"description": ""
	}, {
		"name": "Men",
		"description": ""
	}, {
		"name": "Women",
		"description": ""
	}, {
		"name": "Juniors",
		"description": ""
	}, {
		"name": "English",
		"description": ""
	}, {
		"name": "Spanish",
		"description": ""
	}],
	"age": "36-55",
	"recommended": false,
	"whys": ["fun-social-golf", "staying-active-health"],
	"club_types": ["travel"],
	"game_styles": ["social-non-competitive", "competitive-net", "team-events"],
	"member_types": [],
	"welcome_genders": ["male", "female", "child"],
	"langs": ["english", "spanish"],
	"skill_levels": [{
		"id": 8,
		"name": "Average Handicaps (10-20)",
		"slug": "average-handicaps",
		"created_at": "2017-10-09 09:17:34",
		"updated_at": "2017-10-09 15:08:46",
		"pivot": {
			"club_id": 2987,
			"skill_level_id": 8
		}
	}],
	"club_events": [{
		"id": 409,
		"name": "March Social ",
		"description": "",
		"event_date": "2018-03-05 06:30:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 2987,
		"is_tournament": 1,
		"cost": "50.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1786,
		"created_at": "2018-02-09 08:25:31",
		"updated_at": "2018-02-09 16:33:01"
	}, {
		"id": 410,
		"name": "2018 SoCal Challenge",
		"description": "",
		"event_date": "2018-04-19 06:30:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 2987,
		"is_tournament": 1,
		"cost": "50.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 770,
		"created_at": "2018-02-09 08:25:31",
		"updated_at": "2018-02-09 16:32:26"
	}],
	"club_benefits": [],
	"average_age": {
		"id": 6,
		"name": "36-55",
		"slug": "36-55",
		"created_at": "2017-10-09 11:32:35",
		"updated_at": "2017-10-18 09:02:36"
	},
	"region": {
		"id": 10,
		"name": "Greater Los Angeles",
		"slug": "greater-los-angeles",
		"display_order": 1,
		"created_at": "2017-03-04 04:20:04",
		"updated_at": "2017-03-06 20:31:20",
		"created_by": 3975
	},
	"wheres": [{
		"id": 3,
		"name": "Travel",
		"slug": "travel",
		"created_at": "2017-09-27 08:18:09",
		"updated_at": "2017-09-27 08:18:09",
		"pivot": {
			"club_id": 2987,
			"where_id": 3
		}
	}],
	"why": [{
		"id": 8,
		"name": "Fun\/Social Golf",
		"slug": "fun-social-golf",
		"created_at": "2017-10-05 14:54:09",
		"updated_at": "2017-11-20 11:02:08",
		"pivot": {
			"club_id": 2987,
			"why_id": 8
		}
	}, {
		"id": 14,
		"name": "Staying Active\/Health",
		"slug": "staying-active-health",
		"created_at": "2017-10-16 15:17:19",
		"updated_at": "2017-10-16 15:17:19",
		"pivot": {
			"club_id": 2987,
			"why_id": 14
		}
	}],
	"we_play_days": [{
		"id": 6,
		"name": "Saturday",
		"slug": "saturday",
		"short_name": "Sat",
		"created_at": "2017-09-26 15:54:55",
		"updated_at": "2017-09-26 15:54:55",
		"pivot": {
			"club_id": 2987,
			"we_play_day_id": 6
		}
	}, {
		"id": 7,
		"name": "Sunday",
		"slug": "sunday",
		"short_name": "Sun",
		"created_at": "2017-09-26 15:54:59",
		"updated_at": "2017-09-26 15:54:59",
		"pivot": {
			"club_id": 2987,
			"we_play_day_id": 7
		}
	}],
	"club_status": {
		"id": 4,
		"name": "Additional",
		"slug": "additional",
		"display_order": 4
	},
	"event_types": [{
		"id": 9,
		"name": "Social (Non-Competitive)",
		"slug": "social-non-competitive",
		"created_at": "2017-10-09 14:42:37",
		"updated_at": "2017-10-09 14:45:29",
		"pivot": {
			"club_id": 2987,
			"event_type_id": 9
		}
	}, {
		"id": 7,
		"name": "Competitive (Net)",
		"slug": "competitive-net",
		"created_at": "2017-10-09 14:35:43",
		"updated_at": "2017-10-09 14:45:51",
		"pivot": {
			"club_id": 2987,
			"event_type_id": 7
		}
	}, {
		"id": 10,
		"name": "Team Events",
		"slug": "team-events",
		"created_at": "2017-10-09 14:42:46",
		"updated_at": "2017-10-09 14:42:46",
		"pivot": {
			"club_id": 2987,
			"event_type_id": 10
		}
	}],
	"golfer_types": [],
	"languages": [{
		"id": 1,
		"name": "English",
		"slug": "english",
		"created_at": "2017-09-26 15:27:42",
		"updated_at": "2017-09-26 15:27:42",
		"pivot": {
			"club_id": 2987,
			"language_id": 1
		}
	}, {
		"id": 2,
		"name": "Spanish",
		"slug": "spanish",
		"created_at": "2017-09-26 15:28:47",
		"updated_at": "2017-09-26 15:28:47",
		"pivot": {
			"club_id": 2987,
			"language_id": 2
		}
	}]
}, {
	"id": 629,
	"title": "Sepulveda Golf Club",
	"url_title": "sepulveda-mens-golf-club",
	"entry_date": "2010-12-08 09:27:03",
	"entry_id": 3201,
	"region_id": 10,
	"eligible": 1,
	"club_status_id": 1,
	"latitude": "34.17026100",
	"longitude": "-118.49725000",
	"feature_video_url": null,
	"address2_line1": "14709 Valleyheart Dr",
	"address2_line2": "",
	"address2_city": "Sherman Oaks",
	"address2_stateorprovince": "CA",
	"address2_postalcode": "91403",
	"address2_line3": "",
	"telephone1": "(310) 776-0156",
	"emailaddress1": "",
	"new_greenfees": "125.00",
	"new_clasificationtype": "5",
	"facility_featured": "no",
	"new_scga_displayname": "Sepulveda Golf Club",
	"new_yearestablished": "",
	"new_scgahidecontactemail": 0,
	"new_scgaopenformembership": 1,
	"websiteurl": "http:\/\/www.sepulvedagolf.com",
	"facility_latitude": "34.15999990",
	"facility_longitude": "-118.46000000",
	"facility_formatted_address": "Sherman Oaks, CA 91413, USA",
	"course_facility": 0,
	"club_facility": 0,
	"description": "<div class=\"embed-responsive embed-responsive-16by9\">\r\n  <iframe class=\"embed-responsive-item\" src=\"https:\/\/www.youtube.com\/embed\/mFPnQ27Sk0w\" allowfullscreen><\/iframe>\r\n<\/div>\r\n<br>\r\n<br>\r\nWe are one of the oldest and largest public golf leagues located in the city of Los Angeles.  Our home courses are Encino and Balboa Golf Course which are located in the Sepulveda Basin Recreational Area of the San Fernando Valley. <br>\r\n<br>\r\nWe welcome golfers of all ability and skill levels to join our club and play in any number of our tournaments.<br>\r\n<br>\r\nThe SMGC hosts two tournaments a month.  One weekend tournament and one weekday tournament on the fourth Thursday of the month.  Additionally we host several major tournaments including our Goeckner Classic, Club Championship and President's Cup (match play) events. Members of the SMGC are also invited to play in League Team Play events which are hosted by the MGA and SCGA.<br>\r\n<br>\r\nFor more information, check out our <a href=\"https:\/\/smgc-sepulvedamensgolfclub.golfgenius.com\/pages\/5013442744908013414\" target=\"_blank\">club website.<\/a>",
	"new_scgapromotionaltext": "",
	"address1_line1": "P.O. Box 57373",
	"address1_line2": "",
	"address1_line3": "",
	"address1_city": "Sherman Oaks",
	"address1_stateorprovince": "CA",
	"address1_postalcode": "91413",
	"online_app": 0,
	"clublink": "http:\/\/sepulveda.lagolfclubs.com\/clubs\/NewHome.cfm\/ClubID\/11\/Section\/NewMember",
	"new_scga_allowonlineregistration": 0,
	"new_scga_joinnowurl": "https:\/\/membership.scga.org\/start\/join\/?cid=415",
	"new_scga_renewnowurl": "http:\/\/sepulveda.lagolfclubs.com\/clubs\/NewHome.cfm\/ClubID\/11\/Section\/Player",
	"new_scga_allowsonlinesignup": 1,
	"club_image": "",
	"new_remotehandicappingsystemkey": "007306290001",
	"club_facility_relationship": 713,
	"club_site_logo": "https:\/\/scga.s3.us-west-2.amazonaws.com\/logos\/sepulveda-mens-golf-club\/awXrNG4ntyx4XRcJFEaOgqiAHwXVD4KnvUSfKBVx.jpeg",
	"club_header_image": "https:\/\/scga.s3.us-west-2.amazonaws.com\/headers\/sepulveda-mens-golf-club\/qmV3Ereu6prQyFyRRL3KRlEX5ZLD6GquVYECyTeA.jpeg",
	"last_member_import": "2020-08-07 10:53:58",
	"local_description": null,
	"local_scgapromotionaltext": "<div class=\"embed-responsive embed-responsive-16by9\">\r\n  <iframe class=\"embed-responsive-item\" src=\"https:\/\/www.youtube.com\/embed\/mFPnQ27Sk0w\" allowfullscreen><\/iframe>\r\n<\/div>\r\n<br>\r\n<br>\r\nWe are one of the oldest and largest public golf leagues located in the city of Los Angeles.  Our home courses are Encino and Balboa Golf Course which are located in the Sepulveda Basin Recreational Area of the San Fernando Valley. <br>\r\n<br>\r\nWe welcome golfers of all ability and skill levels to join our club and play in any number of our tournaments.<br>\r\n<br>\r\nThe SMGC hosts two tournaments a month.  One weekend tournament and one weekday tournament on the fourth Thursday of the month.  Additionally we host several major tournaments including our Goeckner Classic, Club Championship and President's Cup (match play) events. Members of the SMGC are also invited to play in League Team Play events which are hosted by the MGA and SCGA.<br>\r\n<br>\r\nFor more information, check out our <a href=\"https:\/\/smgc-sepulvedamensgolfclub.golfgenius.com\/pages\/5013442744908013414\" target=\"_blank\">club website.<\/a>",
	"address1_county": "LOS ANGELES",
	"featured_on_home": "",
	"geolocation_update_address2": "yes",
	"member_count": 532,
	"contact_name": "Virgil Budhu",
	"contact_email": "vrbudhu@gmail.com",
	"allow_associate_membership": 1,
	"is_senior_club": 0,
	"age_id": 6,
	"updated_at": "2020-10-16 00:00:05",
	"created_at": "2017-01-13 04:31:09",
	"last_import_action": "updated",
	"last_import_date": "2020-10-16 00:00:05",
	"classification_id": 5,
	"offers_free_trial": 0,
	"video_id": "mFPnQ27Sk0w",
	"featured_image_url": "https:\/\/scga.s3.us-west-2.amazonaws.com\/featured_image\/pVnoN0kNJ6rjZZqYcDxXhYobeZHI5ZDfje7SgyUX.png",
	"wePlay": {
		"thursday": "TH",
		"sunday": "SUN",
		"friday": "FR",
		"saturday": "SAT"
	},
	"play_days": ["thursday", "sunday", "friday", "saturday"],
	"nextEvent": {
		"id": 4927,
		"name": "Alan Stewart 5 Club",
		"description": "",
		"event_date": "2020-11-27 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 3975,
		"club_id": 629,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 829,
		"created_at": "2020-02-27 09:58:45",
		"updated_at": "2020-02-27 09:58:45",
		"date": "November 27th 8:00 AM",
		"facility": {
			"id": 829,
			"title": "Sepulveda Golf Complex (Balboa and Encino)"
		}
	},
	"learnMoreUrl": "\/findByClubName?active=club&club_id=629",
	"levels": ["average-handicaps", "single-digit-handicaps"],
	"tags": [{
		"name": "Home Course",
		"description": "hey"
	}, {
		"name": "Competition",
		"description": ""
	}, {
		"name": "Fun\/Social Golf",
		"description": ""
	}, {
		"name": "Average Handicaps (10-20)",
		"description": ""
	}, {
		"name": "Single Digit Handicaps",
		"description": ""
	}, {
		"name": "Men",
		"description": ""
	}, {
		"name": "Women",
		"description": ""
	}, {
		"name": "Juniors",
		"description": ""
	}, {
		"name": "English",
		"description": ""
	}, {
		"name": "Spanish",
		"description": ""
	}, {
		"name": "Farsi",
		"description": ""
	}, {
		"name": "Italian",
		"description": ""
	}],
	"age": "36-55",
	"recommended": true,
	"whys": ["competition", "fun-social-golf"],
	"club_types": ["home-course"],
	"game_styles": ["competitive-net", "competitive-gross", "team-events"],
	"member_types": ["beginners"],
	"welcome_genders": ["male", "female", "child"],
	"langs": ["english", "spanish", "farsi", "italian"],
	"skill_levels": [{
		"id": 8,
		"name": "Average Handicaps (10-20)",
		"slug": "average-handicaps",
		"created_at": "2017-10-09 09:17:34",
		"updated_at": "2017-10-09 15:08:46",
		"pivot": {
			"club_id": 629,
			"skill_level_id": 8
		}
	}, {
		"id": 9,
		"name": "Single Digit Handicaps",
		"slug": "single-digit-handicaps",
		"created_at": "2017-10-09 09:17:46",
		"updated_at": "2017-10-09 09:17:46",
		"pivot": {
			"club_id": 629,
			"skill_level_id": 9
		}
	}],
	"club_events": [{
		"id": 417,
		"name": "Away Tournament",
		"description": "",
		"event_date": "2018-02-22 09:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 629,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 813,
		"created_at": "2018-02-12 11:15:58",
		"updated_at": "2018-02-12 11:15:58"
	}, {
		"id": 418,
		"name": "Away Tournament",
		"description": "",
		"event_date": "2018-03-11 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 629,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1078,
		"created_at": "2018-02-12 11:16:24",
		"updated_at": "2018-02-12 11:16:24"
	}, {
		"id": 419,
		"name": "Home Tournament",
		"description": "",
		"event_date": "2018-03-22 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 629,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 829,
		"created_at": "2018-02-12 11:17:07",
		"updated_at": "2018-02-12 11:17:07"
	}, {
		"id": 420,
		"name": "Away Tournament",
		"description": "",
		"event_date": "2018-04-08 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 629,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 810,
		"created_at": "2018-02-12 11:17:58",
		"updated_at": "2018-02-12 11:17:58"
	}, {
		"id": 421,
		"name": "Senior Club Championship",
		"description": "",
		"event_date": "2018-04-28 08:00:00",
		"event_days": 2,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 629,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 829,
		"created_at": "2018-02-12 11:18:45",
		"updated_at": "2018-02-12 11:18:45"
	}, {
		"id": 422,
		"name": "Palumbo",
		"description": "",
		"event_date": "2018-05-12 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 629,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1081,
		"created_at": "2018-02-12 11:19:16",
		"updated_at": "2018-02-12 11:19:16"
	}, {
		"id": 423,
		"name": "Away Tournament",
		"description": "",
		"event_date": "2018-05-24 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 629,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 734,
		"created_at": "2018-02-12 11:20:03",
		"updated_at": "2018-02-12 11:20:03"
	}, {
		"id": 424,
		"name": "Club Championship Rounds 1 & 2",
		"description": "",
		"event_date": "2018-06-23 08:00:00",
		"event_days": 2,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 629,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 829,
		"created_at": "2018-02-12 11:20:44",
		"updated_at": "2018-02-12 11:20:44"
	}, {
		"id": 425,
		"name": "Club Championship Round 3",
		"description": "",
		"event_date": "2018-06-30 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 629,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 829,
		"created_at": "2018-02-12 11:21:05",
		"updated_at": "2018-02-12 11:21:05"
	}, {
		"id": 761,
		"name": "Away Tournament",
		"description": "Course TBD",
		"event_date": "2018-07-08 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 629,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1081,
		"created_at": "2018-03-02 13:14:58",
		"updated_at": "2018-03-02 13:17:14"
	}, {
		"id": 762,
		"name": "Away Tournament",
		"description": "",
		"event_date": "2018-07-22 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 629,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 800,
		"created_at": "2018-03-02 13:15:54",
		"updated_at": "2018-03-02 13:15:54"
	}, {
		"id": 763,
		"name": "Away Tournament",
		"description": "",
		"event_date": "2018-08-12 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 629,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1068,
		"created_at": "2018-03-02 13:16:40",
		"updated_at": "2018-03-02 13:16:40"
	}, {
		"id": 764,
		"name": "Away Tournament",
		"description": "Harding",
		"event_date": "2018-08-23 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 629,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 734,
		"created_at": "2018-03-02 13:18:45",
		"updated_at": "2018-03-02 13:18:45"
	}, {
		"id": 765,
		"name": "Away Tournament",
		"description": "",
		"event_date": "2018-09-16 10:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 629,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1079,
		"created_at": "2018-03-02 13:19:16",
		"updated_at": "2018-03-02 13:19:16"
	}, {
		"id": 766,
		"name": "Away Tournament",
		"description": "",
		"event_date": "2018-09-27 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 629,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 828,
		"created_at": "2018-03-02 13:20:19",
		"updated_at": "2018-03-02 13:20:19"
	}, {
		"id": 767,
		"name": "Dick Geockner Championship",
		"description": "",
		"event_date": "2018-10-07 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 629,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 810,
		"created_at": "2018-03-02 13:21:18",
		"updated_at": "2018-03-02 13:21:18"
	}, {
		"id": 768,
		"name": "Election Day Tournament",
		"description": "",
		"event_date": "2018-10-25 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 629,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 829,
		"created_at": "2018-03-02 13:21:49",
		"updated_at": "2018-03-02 13:21:49"
	}, {
		"id": 769,
		"name": "Away Tournament",
		"description": "",
		"event_date": "2018-11-04 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 629,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1071,
		"created_at": "2018-03-02 13:22:25",
		"updated_at": "2018-03-02 13:22:25"
	}, {
		"id": 770,
		"name": "Five Club",
		"description": "Balboa",
		"event_date": "2018-11-23 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 629,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 829,
		"created_at": "2018-03-02 13:23:13",
		"updated_at": "2018-03-02 13:23:13"
	}, {
		"id": 771,
		"name": "Away Tournament",
		"description": "",
		"event_date": "2018-12-09 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 629,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1069,
		"created_at": "2018-03-02 13:24:15",
		"updated_at": "2018-03-02 13:24:15"
	}, {
		"id": 772,
		"name": "Home Tournament",
		"description": "",
		"event_date": "2018-12-20 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 172,
		"club_id": 629,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 829,
		"created_at": "2018-03-02 13:25:19",
		"updated_at": "2018-03-02 13:25:19"
	}, {
		"id": 4921,
		"name": "Senior Championship ",
		"description": "",
		"event_date": "2020-03-28 08:00:00",
		"event_days": 2,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 3975,
		"club_id": 629,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 829,
		"created_at": "2020-02-27 09:47:58",
		"updated_at": "2020-02-27 09:50:18"
	}, {
		"id": 4922,
		"name": "Palombo Major",
		"description": "",
		"event_date": "2020-05-03 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 3975,
		"club_id": 629,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 1078,
		"created_at": "2020-02-27 09:50:00",
		"updated_at": "2020-02-27 09:50:00"
	}, {
		"id": 4923,
		"name": "Club Championship - Round 1",
		"description": "",
		"event_date": "2020-06-20 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 3975,
		"club_id": 629,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 829,
		"created_at": "2020-02-27 09:54:21",
		"updated_at": "2020-02-27 09:54:21"
	}, {
		"id": 4924,
		"name": "Club Championship - Round 2",
		"description": "",
		"event_date": "2020-06-21 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 3975,
		"club_id": 629,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 829,
		"created_at": "2020-02-27 09:55:07",
		"updated_at": "2020-02-27 09:55:07"
	}, {
		"id": 4925,
		"name": "Club Championship - Round 3",
		"description": "",
		"event_date": "2020-06-28 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 3975,
		"club_id": 629,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 829,
		"created_at": "2020-02-27 09:55:50",
		"updated_at": "2020-02-27 09:55:50"
	}, {
		"id": 4926,
		"name": "Dick Geockner Major",
		"description": "",
		"event_date": "2020-10-11 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 3975,
		"club_id": 629,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 810,
		"created_at": "2020-02-27 09:57:44",
		"updated_at": "2020-02-27 09:57:44"
	}, {
		"id": 4927,
		"name": "Alan Stewart 5 Club",
		"description": "",
		"event_date": "2020-11-27 08:00:00",
		"event_days": 1,
		"location": null,
		"location_url": null,
		"event_url": null,
		"image": null,
		"created_by": 3975,
		"club_id": 629,
		"is_tournament": 1,
		"cost": "0.00",
		"registration_url": "",
		"has_results": 0,
		"results": null,
		"results_custom_page_id": null,
		"results_upload": null,
		"results_url": null,
		"facility_id": 829,
		"created_at": "2020-02-27 09:58:45",
		"updated_at": "2020-02-27 09:58:45"
	}],
	"club_benefits": [{
		"id": 348,
		"name": "Scripts & Hats",
		"description": null,
		"order": 0,
		"created_at": "2019-02-11 08:43:37",
		"updated_at": "2019-02-11 08:43:37",
		"created_by": 172,
		"club_id": 629
	}],
	"average_age": {
		"id": 6,
		"name": "36-55",
		"slug": "36-55",
		"created_at": "2017-10-09 11:32:35",
		"updated_at": "2017-10-18 09:02:36"
	},
	"region": {
		"id": 10,
		"name": "Greater Los Angeles",
		"slug": "greater-los-angeles",
		"display_order": 1,
		"created_at": "2017-03-04 04:20:04",
		"updated_at": "2017-03-06 20:31:20",
		"created_by": 3975
	},
	"wheres": [{
		"id": 1,
		"name": "Home Course",
		"slug": "home-course",
		"created_at": "2017-09-27 08:14:50",
		"updated_at": "2017-09-27 08:14:50",
		"pivot": {
			"club_id": 629,
			"where_id": 1
		}
	}],
	"why": [{
		"id": 7,
		"name": "Competition",
		"slug": "competition",
		"created_at": "2017-10-05 14:53:43",
		"updated_at": "2017-10-05 14:53:43",
		"pivot": {
			"club_id": 629,
			"why_id": 7
		}
	}, {
		"id": 8,
		"name": "Fun\/Social Golf",
		"slug": "fun-social-golf",
		"created_at": "2017-10-05 14:54:09",
		"updated_at": "2017-11-20 11:02:08",
		"pivot": {
			"club_id": 629,
			"why_id": 8
		}
	}],
	"we_play_days": [{
		"id": 4,
		"name": "Thursday",
		"slug": "thursday",
		"short_name": "Th",
		"created_at": "2017-09-26 15:54:45",
		"updated_at": "2017-09-26 15:54:45",
		"pivot": {
			"club_id": 629,
			"we_play_day_id": 4
		}
	}, {
		"id": 7,
		"name": "Sunday",
		"slug": "sunday",
		"short_name": "Sun",
		"created_at": "2017-09-26 15:54:59",
		"updated_at": "2017-09-26 15:54:59",
		"pivot": {
			"club_id": 629,
			"we_play_day_id": 7
		}
	}, {
		"id": 5,
		"name": "Friday",
		"slug": "friday",
		"short_name": "Fr",
		"created_at": "2017-09-26 15:54:49",
		"updated_at": "2017-09-26 15:54:49",
		"pivot": {
			"club_id": 629,
			"we_play_day_id": 5
		}
	}, {
		"id": 6,
		"name": "Saturday",
		"slug": "saturday",
		"short_name": "Sat",
		"created_at": "2017-09-26 15:54:55",
		"updated_at": "2017-09-26 15:54:55",
		"pivot": {
			"club_id": 629,
			"we_play_day_id": 6
		}
	}],
	"club_status": {
		"id": 1,
		"name": "Platinum",
		"slug": "platinum",
		"display_order": 1
	},
	"event_types": [{
		"id": 7,
		"name": "Competitive (Net)",
		"slug": "competitive-net",
		"created_at": "2017-10-09 14:35:43",
		"updated_at": "2017-10-09 14:45:51",
		"pivot": {
			"club_id": 629,
			"event_type_id": 7
		}
	}, {
		"id": 8,
		"name": "Competitive (Gross)",
		"slug": "competitive-gross",
		"created_at": "2017-10-09 14:42:21",
		"updated_at": "2017-10-09 14:45:37",
		"pivot": {
			"club_id": 629,
			"event_type_id": 8
		}
	}, {
		"id": 10,
		"name": "Team Events",
		"slug": "team-events",
		"created_at": "2017-10-09 14:42:46",
		"updated_at": "2017-10-09 14:42:46",
		"pivot": {
			"club_id": 629,
			"event_type_id": 10
		}
	}],
	"golfer_types": [{
		"id": 4,
		"name": "Beginners",
		"description": "Noobies of all ages",
		"slug": "beginners",
		"created_at": "2017-03-04 18:29:06",
		"updated_at": "2017-03-04 18:29:06",
		"created_by": null,
		"pivot": {
			"club_id": 629,
			"golfer_type_id": 4
		}
	}],
	"languages": [{
		"id": 1,
		"name": "English",
		"slug": "english",
		"created_at": "2017-09-26 15:27:42",
		"updated_at": "2017-09-26 15:27:42",
		"pivot": {
			"club_id": 629,
			"language_id": 1
		}
	}, {
		"id": 2,
		"name": "Spanish",
		"slug": "spanish",
		"created_at": "2017-09-26 15:28:47",
		"updated_at": "2017-09-26 15:28:47",
		"pivot": {
			"club_id": 629,
			"language_id": 2
		}
	}, {
		"id": 6,
		"name": "Farsi",
		"slug": "farsi",
		"created_at": "2017-09-26 15:34:04",
		"updated_at": "2017-09-26 15:34:04",
		"pivot": {
			"club_id": 629,
			"language_id": 6
		}
	}, {
		"id": 12,
		"name": "Italian",
		"slug": "italian",
		"created_at": "2017-09-26 15:34:50",
		"updated_at": "2017-09-26 15:34:50",
		"pivot": {
			"club_id": 629,
			"language_id": 12
		}
	}]
}];

export default {
    featuredClubs
};