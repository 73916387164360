import React from 'react';
import EventItem from './EventItem';
import {connect} from 'react-redux'
import axios from 'axios';
const api = 'https://clubs.scga.org/api/';

class ClubEvents extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            hover: false,
            // events: this.props.events,
            locationSet: this.props.locationSet,
            page: 1,
            events_left: 0,
            setFacilityFilter: this.props.setFacilityFilter,
            events: this.props.events,
            thisMonthCount: 0,
        };
        this.nextPageClick = this.nextPageClick.bind(this);
    }

    componentDidMount() {
        // console.log("ClubEvents::componentDidMount: events: ",this.props.events)
        // if(!this.props.events.length === 0){
        //     this.getNextEvents(this.state.page * 10)
        //     console.log("There are ",this.props.events.length," events.")
        // }
        // this.getEventCountThisMonth();
        this.setState({events_left: this.props.eventsFutureCount - this.props.events.length})
        // console.log("ClubEvents::componentDidMount: eventsFutureCount: ",this.props.eventsFutureCount)
        // console.log("ClubEvents::componentDidMount: events length: ",this.props.events.length)
        // console.log("ClubEvents::componentDidMount: events_left: ",this.state.events_left)
    }

    componentWillReceiveProps(nextProps){
        this.setState({
            events: nextProps.events,
            setFacilityFilter: nextProps.setFacilityFilter,
        });
        // console.log("ClubEvents::componentWillReceiveProps: events: ",this.state.events)

    }

    nextPageClick(){
        const offset = this.props.events.length;
        this.getNextEvents(offset);
        this.setState({
            page: this.state.page+1,
            events_left: (this.state.events_left - 10) > 0 ? this.state.events_left - 10 : 0,
        })
    }

    getEventCountThisMonth(){
        axios.get(api + 'events-this-month')
            .then(response => response.data)
            .then(data => {
                // console.log("ClubEvents::getEventCountThisMonth: data: ",data)
                this.setState({thisMonthCount: parseInt(data)})
                // console.log("Events this month: ", data)
            })
            .catch(error => console.error(error));
    }

    getNextEvents(offset){
        let events = this.props.events;
        axios.get(api + 'events/'+ offset)
            .then(response => response.data)
            .then(data => {
                // console.log("ClubEvents::getNextEvents: data: ",data)
                data.events.map(event => {
                    events.push(event);
                })
                this.props.onSetEvents(events, data.futureCount, data.thisMonthCount)
                this.setState({events_left: data.futureCount - events.length})
                // console.log("Events count after api fetch: ", events.length)
            })
            .catch(error => console.error(error));
    }
    render() {
        const today = new Date();
        const events = this.props.events;
        // console.log("ClubEvents::render: events: ", events)
        // console.log("ClubEvents::render: events_left: ", this.state.events_left)
        let idx = 0;
        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        let thisMonth = monthNames[today.getMonth()];
/*
        const events_thisMonth = events.filter((event) => {
            let event_date = new Date(event.event_date);    // create a date object from event date
            return ((event_date.getMonth() === today.getMonth()) && event_date > today);
        });
*/
        const events_count = this.props.eventsThisMonthCount;
        // const events_count = events_thisMonth.length;
        const dims = ["h1w3", "h2w1", "h1w1", "h1w1", "h1w2"];
        // get 10 events from events list
        const eventsList = events.slice(0, this.state.events_left <= 0 ? events.length : this.state.page * 10).map((event) =>
            <EventItem
                hover={this.state.hover}
                gridOrientation={dims[idx++ % 5]}
                event={event}
                locationSet={this.props.locationSet}
                key={event.id}
                // setFacilityFilter={this.props.setFacilityFilter}
                filtersSet={this.props.filtersSet}
            />
        );

        const count_div = <span className="events-count float-right"><span className="count">{events_count}</span> Events in {thisMonth}</span>;
        return (
            <div className={this.state.locationSet ? "upcoming-events results":"upcoming-events"}>
                <div className="row heading-row">
                    <div className="col-12">
                    <div className="preview-list-title">Upcoming Events  </div>
                    <div className="preview-list-sub-title">
                    {!this.state.locationSet && count_div}
                    </div>
                    </div>

                </div>
                <div className="event-grid home">
                    { eventsList }
                </div>
                <div className={this.state.events_left >0 ? "next-ten":"next-ten hide"}>
                    <button onClick={this.nextPageClick}>{this.state.events_left > 10 ?'10 more events...': 'last '+this.state.events_left+' events...'}</button>
                </div>
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        locationSet: state.locationSet,
        filtersSet: state.filtersSet,
        events: state.events,
        eventsFutureCount: state.eventsFutureCount,
        eventsThisMonthCount: state.eventsThisMonthCount,
    };
}
const mapDispatchToProps = dispatch => {
    return {
        onSetEvents: (events,eventsFutureCount, eventsThisMonthCount) => dispatch({type:'SET_EVENTS', payload: {events:events, eventsFutureCount: eventsFutureCount, eventsThisMonthCount: eventsThisMonthCount}}),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClubEvents);